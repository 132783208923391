import VueRouter from "vue-router";
import Home from "./components/Home.vue";
import Login from "./components/Login.vue";
import Register from "./components/Register.vue";
// lazy-loaded
const Profile = () => import("./components/Profile.vue");
// const BoardAdmin = () => import("./components/BoardAdmin.vue");
const BoardModerator = () => import("./components/BoardModerator.vue");
const BoardUser = () => import("./components/BoardUser.vue");
const BoardTeacher = () => import("./components/BoardTeacher.vue");
const BoardStudent = () => import("./components/BoardStudent.vue");
import Main from './views/Main';
import Intro from "./views/pages/Intro";
import Review from "./views/pages/Review";
import Tuition from "./views/pages/Tuition";
import RegisterSuccess from "./components/RegisterSuccess";
import RegisterSuccessDialog from './components/RegisterSuccessDialog'
import TeacherRegister from "./components/teacher/TeacherRegister";
import PaymentForm from "./components/Payment/PaymentForm";
import Payment from "./components/Payment/Payment";
import TeacherVerify from "./components/teacher/TeacherVerify";
import Admin from "./components/admin/Admin";
import MeetingInitiation from "./components/admin/MatchingInitiation"
import Enrollment from "./components/student/Enrollment/Enrollment";
import StudentMatchingInfoForm from "./components/student/Matching/StudentMatchingInfoForm";
import EditProfile from "./components/User/EditProfile";
import PasswordRecovery from "./components/User/PasswordRecovery";
import FinalDegree from "./components/teacher/Register/FinalDegree";
import College from "./components/teacher/Register/College";
import Highschool from "./components/teacher/Register/Highschool";
import SubjectsOfExpertise from "./components/teacher/Register/SubjectsOfExpertise";
import LessonTimes from "./components/teacher/Register/LessonTimes";
import Duration from "./components/teacher/Register/Duration";
import Experience from "./components/teacher/Register/Experience";
import AboutLecture from "./components/teacher/Register/AboutLecture";
import AboutTeacher from "./components/teacher/Register/AboutTeacher";
import TeacherSummary from "./components/teacher/Register/TeacherSummary";
import PaymentConfirmation from "./components/Payment/PaymentConfirmation";
import EditTeacherProfile from "./components/User/EditTeacherProfile";
import ViewTeacherSummary from "./components/teacher/ViewTeacherSummary";
import ScheduledLessons from "./components/admin/ScheduledLessons";
import FaqPage from "./views/pages/FaqPage";

const routes = [
    {
        path: "/",
        component: Main,
        // name: "home",
        // component: Home,
        children: [
            {
                path: "/",
                component: Home,
            },
            {
                path: "/login",
                name: 'login',
                component: Login,
            },
            {
                path: "/register",
                name: "register",
                component: Register,
            },
            {
                path: "/profile",
                name: "profile",
                // lazy-loaded
                component: Profile,
            },
            {
                path: "/payment",
                name: "payment",
                component: Payment
            },
            {
              path: "/paymentForm",
              name: "paymentForm",
              component: PaymentForm
            },
            {
                path: "/payment/confirmation",
                name: "paymentConfirmation",
                component: PaymentConfirmation
            },
            {
                path: "student/matching/info",
                name: "matchingInfoForm",
                component: StudentMatchingInfoForm
            },
            {
                path: "/enroll",
                name: "enrollment",
                component: Enrollment
            },
            // {
            //     path: "/admin",
            //     name: "admin",
            //     // lazy-loaded
            //     component: BoardAdmin,
            // },
            {
                path: "/mod",
                name: "moderator",
                // lazy-loaded
                component: BoardModerator,
            },
            {
                path: "/user",
                name: "user",
                // lazy-loaded
                component: BoardUser,
            },
            {
                path: "/teacher",
                name: "teacher",
                // lazy-loaded
                component: BoardTeacher,
            },
            {
                path: "/student",
                name: "student",
                // lazy-loaded
                component: BoardStudent,
            },

            {
                path: "/teacher/register",
                name: "teacherRegister",
                // lazy-loaded
                component: TeacherRegister,
            },
            {
                path: "/teacher/register/finalDegree",
                name: "finalDegree",
                // lazy-loaded
                component: FinalDegree,
            },
            {
                path: "/teacher/register/college",
                name: "college",
                // lazy-loaded
                component: College,
            },
            {
                path: "/teacher/register/highschool",
                name: "highschool",
                // lazy-loaded
                component: Highschool,
            },
            {
                path: "/teacher/register/subjects",
                name: "subjectsOfExpertise",
                // lazy-loaded
                component: SubjectsOfExpertise,
            },
            {
                path: "/teacher/register/lesson/times",
                name: "lessonTimes",
                // lazy-loaded
                component: LessonTimes,
            },
            {
                path: "/teacher/register/lesson/duration",
                name: "duration",
                // lazy-loaded
                component: Duration,
            },
            {
                path: "/teacher/register/lesson/experience",
                name: "teacherExperience",
                // lazy-loaded
                component: Experience,
            },
            ///teacher/register/teacher/info

            {
                path: "/teacher/register/lecture/info",
                name: "aboutLecture",
                // lazy-loaded
                component: AboutLecture,
            },
            {
                path: "/teacher/register/teacher/info",
                name: "aboutTeacher",
                // lazy-loaded
                component: AboutTeacher,
            },
            {
                path: "/teacher/register/teacher/summary",
                name: "teacherSummary",
                // lazy-loaded
                component: TeacherSummary,
            },
            {
                path: "/teacher/summary",
                name: "viewTeacherSummary",
                // lazy-loaded
                component: ViewTeacherSummary,
            },
            {
                path: "/teacher/verify",
                name: "teacherVerify",
                // lazy-loaded
                component: TeacherVerify,
            },

            {
                path: "/faq",
                name: "faq",
                component: FaqPage,
            },
            {
                path: "/intro",
                name: "intro",
                component: Intro,
            },
            {
                path: "/review",
                name: "review",
                component: Review,
            },
            {
                path: "/tuition",
                name: "tuition",
                component: Tuition,
            },
            {
                path: "/registerSuccess",
                name: "registerSuccess",
                component: RegisterSuccess,
            },
            {
                path: "/registerSuccessDialog",
                name: "registerSuccessDialog",
                component: RegisterSuccessDialog,
            },
            {
                path: "/admin",
                name: "admin",
                component: Admin,
            },
            {
                path: "/admin/matching/initiate",
                name: "initiateMeeting",
                component: MeetingInitiation,
            },
            {
                path: "/admin/lessons/scheduled",
                name: "scheduledLessons",
                component: ScheduledLessons,
            },
            {
                path: "/profile/edit",
                name: "editProfile",
                component: EditProfile
            },
            {
                path: "/profile/edit/teacher",
                name: "editTeacherProfile",
                component: EditTeacherProfile
            },
            // {
            //     path: "/timezone",
            //     name: "timezone",
            //     component: TimezoneImage
            // },
            {
                path: "/password/recover",
                name: "passwordRecovery",
                component: PasswordRecovery
            }
            ],

    },
];

const router = new VueRouter({
    // history: VueRouter.install()createWebHistory(),
    routes,
    mode: 'history',
    // eslint-disable-next-line no-unused-vars
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 };
    }
});

// router.beforeEach((to, from, next) => {
//   const publicPages = ['/login', '/register', '/home'];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem('user');

//   // trying to access a restricted page + not logged in
//   // redirect to login page
//   if (authRequired && !loggedIn) {
//     next('/login');
//   } else {
//     next();
//   }
// });

export default router;
