<template>
    <div>
        <v-btn
                fixed
                elevation="5"
                fab
                right
                bottom
                class="test-container hidden-sm-and-down"
                href="https://pf.kakao.com/_NxlSSb/chat"
                target="_blank"
                color="#ffe812"
        >
            <v-icon color="#ffe812"
                    size="40">$vuetify.icons.kakao
            </v-icon>
        </v-btn>
        <v-btn
                fixed
                elevation="5"
                fab
                right
                bottom
                class="mobile-container hidden-md-and-up"
                href="https://pf.kakao.com/_NxlSSb/chat"
                target="_blank"
                color="#ffe812"
                small

        >
            <v-icon color="#ffe812"
                    size="30">$vuetify.icons.kakao
            </v-icon>
        </v-btn>

    </div>
</template>

<script>
    export default {
        name: "KakaoFloatingButton"
    }
</script>

<style scoped>
    .test-container {
        bottom: 100px;
        right: 26px;
        position: fixed;
        color: #00FF00;
    }

    .mobile-container {
        bottom: 130px;
        right: 15px;
        position: fixed;
        color: #00FF00;

    }
</style>
