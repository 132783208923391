<template>
    <v-container>
        <v-card
                class="mx-auto primary--text pa-2 ma-2"
                max-width="750"
                flat
                color=""
                height="1000"
        >


            <v-card-title class="justify-center mt-15"
            >
                <!--                    <h1>상품 정보</h1>-->
                <span
                        :class="{
                    'text-h4': $vuetify.breakpoint.lgAndUp,
                    'text-h5': $vuetify.breakpoint.mdAndDown,
                    // 'text-strong': $vuetify.breakpoint.mdAndDown
                    }"
                >
<ivyed-logo></ivyed-logo>
<!--                <strong>IVYeD</strong>-->
            </span>
            </v-card-title>
            <!-- PC Browser  -->
            <v-card outlined class="ma-5 pa-5 hidden-sm-and-down" >
                <v-card-text class="text-subtitle-1 pa-3 black--text">

                                                    <span class="text-h6 font-weight-bold primary--text">
                                                        {{this.studentContent.subjectsOfInterest[0]}}&nbsp;

                                                    </span>
                    <span class="text-subtitle-1">{{this.studentContent.subSubjects[0]}} | 주 {{this.studentContent.expectedNumberOfLessonsPerWeek}}회 60분</span>

                </v-card-text>
                <v-card-text class="pa-3">
                    <v-icon class="mx-3" color="secondary">mdi-school</v-icon>
                    <span class="text-subtitle-1 black--text"> 총 {{this.studentContent.expectedLessonDuration * this.studentContent.expectedNumberOfLessonsPerWeek * 4}}회의 1:1 과외 수업</span>
                </v-card-text>
                <v-card-text class="pa-3">
                    <v-icon class="mx-3" color="secondary">mdi-credit-card</v-icon>
                    <span class="text-subtitle-1 black--text"> {{this.studentContent.expectedLessonDuration}}개월 분
                                                        <span class="text-caption">
                                                        (총 {{this.studentContent.expectedNumberOfLessonsPerWeek * this.studentContent.expectedLessonDuration * 4}}회)
                                                        </span>
                                                        <br/><span class="font-weight-bold ml-13">수업 종료 후 1개월씩 자동 결제</span></span>
                </v-card-text>
            </v-card>

            <!-- Mobile  -->
            <v-card outlined class="ma-5 pa-5 hidden-md-and-up" >
                <v-card-text class="text-subtitle-1 pa-3 black--text">

                                                    <span class="text-h6 font-weight-bold primary--text">
                                                        {{this.studentContent.subjectsOfInterest[0]}}&nbsp;

                                                    </span>
                    <span class="text-subtitle-1"><br/>{{this.studentContent.subSubjects[0]}} <br/> 주 {{this.studentContent.expectedNumberOfLessonsPerWeek}}회 60분</span>

                </v-card-text>
                <v-card-text class="pa-2">
                    <v-icon class="mx-1" color="secondary">mdi-school</v-icon>
                    <span class="text-subtitle-1 black--text"> 총 {{this.studentContent.expectedLessonDuration * this.studentContent.expectedNumberOfLessonsPerWeek * 4}}회의 1:1 과외 수업</span>
                </v-card-text>
                <v-card-text class="pa-2">
                    <v-icon class="mx-1" color="secondary">mdi-credit-card</v-icon>
                    <span class="text-subtitle-1 black--text"> {{this.studentContent.expectedLessonDuration}}개월 분
                                                        <span class="text-caption">
                                                        (총 {{this.studentContent.expectedNumberOfLessonsPerWeek * this.studentContent.expectedLessonDuration * 4}}회)
                                                        </span>
                        <br/><span class="font-weight-bold ml-9">수업 종료 후 1개월씩 자동 결제</span></span>
                </v-card-text>
            </v-card>

            <v-card-title class="justify-center black--text"
            >
                <!--                    <h1>상품 정보</h1>-->
                <span
                        :class="{
                    'text-h5': $vuetify.breakpoint.lgAndUp,
                    'text-h6': $vuetify.breakpoint.mdAndDown,
                    // 'text-strong': $vuetify.breakpoint.mdAndDown
                    }"
                >

                <strong>수업료 결제가 완료되었습니다.</strong>
            </span>

            </v-card-title>

            <div
                    class="text-center mb-15 black--text"
                    :class="{
                    'text-subtitle-1': $vuetify.breakpoint.lgAndUp,
                    'text-subtitle-2': $vuetify.breakpoint.mdAndDown,
                    // 'text-strong': $vuetify.breakpoint.mdAndDown
                    }"
            >

                <p><br/> 이제 아래 [확인]을 눌러 수업페이지로 가셔서
                    <br/> [과외 신청서]를 작성 후 제출해주시기 바랍니다.
                    <br/> 감사합니다. </p>
            </div>
            <div class="text-center">
                <v-btn

                        x-large
                        width="85%"
                        color="secondary"
                        @click="redirectToProfile()"
                >
                    확인
                </v-btn>
            </div>
        </v-card>
    </v-container>
</template>

<script>
    import StudentService from "../../services/student.service";
    import IvyedLogo from "../helpers/IvyedLogo";

    export default {

        name: "PaymentConfirmation",
        components: {IvyedLogo},
        methods: {
          redirectToProfile() {
              this.$router.push("/profile")
          }
        },
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
        },
        mounted() {
            if (!this.currentUser) {
                this.$router.push('/login');
            }


            StudentService.getStudentInfo(this.currentUser.id).then(
                (response) => {
                    this.studentContent = response.data;
                    // this.studentContent.subjectsOfInterest = this.removeEmptyStringFromList(this.studentContent.subjectsOfInterest);

                },
                (error) => {
                    this.studentContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        data() {
            return {
                studentContent: "",
            }
        }

    }
</script>

<style scoped>

</style>
