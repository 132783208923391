<template>
    <div class="py-1 all-lessons-background" v-if="lessons.length > 0">
        <v-card outlined
                class="ma-4" v-for="item in lessonsRequests"
                :key="item.id"
                max-width="800"
                >
            <!--            {{item.email}}<br/>-->
            <!--            {{item.lesson_status}}-->
            <div v-if="item.lesson_status == 0">
                <!--                NOT_STARTED-->
                <v-chip class="ma-3 accent--text" color="#A20916"> 요청</v-chip>

                <!--                <div class="text-subtitle-2 ma-2"> 요청 시간: {{new Date(item.updated_at)}}</div>-->
                <!-- show only if lesson status == NOT_STARTED -->
                <!--                                    {{item.lesson_status}}-->
                <!--                                    {{item.student_id}}-->
                <!--                                    {{item.lesson_id}}-->
                <!--                                    {{item}}-->

                <!--                                    <h3>{{item.lesson_status}}</h3>-->
                <v-card-title class="font-weight-bold text-decoration-underline">
                    학생 정보
                </v-card-title>
                <div class="mx-5">
<!--                    <span class="font-weight-bold">{{item.school}} ({{item.school_type}}), {{item.current_location}} </span>에-->
<!--                    재학중인-->
<!--                    <span class="font-weight-bold"> {{item.desired_major}}</span> 계열 전공 진학을-->
<!--                    희망하는-->
<!--                    <span class="font-weight-bold">{{item.current_grade}}</span> 학생이에요.-->
<!--                    <br/>-->
                    <strong>{{item.current_location}}</strong> 거주 중이며 <strong>{{item.school}}</strong> (<strong>{{item.school_type}}</strong>) <strong>{{item.current_grade}}</strong>
                    학생입니다.
                    <br/>
                    진학 희망 전공 계열은 <strong>{{item.desired_major}}</strong> 입니다. 📚
                    <br/>
                </div>
                <v-card-title class="font-weight-bold text-decoration-underline">
                    희망 수업 정보

                </v-card-title>
                <div class="mx-5">
                    <!--                                        <span v-for="value in item.preferred_lesson_style" :key="value">-->
                    <!--                                        {{value}}-->
                    <!--                                        </span>-->
                    <span>
                                                                <span class="">도움이 필요한 과목은
                                                                    <span class="font-weight-bold text-decoration-underline">
                                                                    {{item.subjects_of_interest}} - {{item.sub_subjects}}
                                                                </span>
                                                                    입니다.
                                                                </span>
                                                                <br/>
                                                                <br/>
                                                            <span class="font-weight-bold">
                                                                선호 수업 방식:
                                                            </span> {{item.preferred_lesson_style}}
                                                            <br/>
                                                            </span>
                    <span>
                                                            <span class="font-weight-bold">
                                                                선호 교재:
                                                            </span> {{item.preferred_lesson_material}}
                                                                <br/>
                                                                </span>
                    <span>
                                                            <span class="font-weight-bold">
                                                                수업 목적:
                                                            </span> {{item.goal_of_lesson}}
                                                                <br/>
                                                                </span>
                </div>
                <v-card-title class="font-weight-bold text-decoration-underline">
                    선호 일정
                </v-card-title>
                <div class="mx-5">
                    <div v-show="item.detailed_lesson_times.monday.length > 0">
                        <!--                                            <span class="font-weight-bold"> 월요일 </span>: {{item.detailed_lesson_times.monday}}-->
                        <span class="font-weight-bold"> 월요일 </span>:
                        <li v-for="value in item.detailed_lesson_times.monday" :key="value">
                            {{value}}
                        </li>
                        <v-divider class="ma-2"></v-divider>

                    </div>
                    <div v-show="item.detailed_lesson_times.tuesday.length > 0">
                        <span class="font-weight-bold"> 화요일 </span>:
                        <li v-for="value in item.detailed_lesson_times.tuesday"
                            :key="value">
                            {{value}}
                        </li>
                        <v-divider class="ma-2"></v-divider>

                    </div>

                    <div v-show="item.detailed_lesson_times.wednesday.length > 0">
                        <span class="font-weight-bold"> 수요일 </span>:
                        <li v-for="value in item.detailed_lesson_times.wednesday"
                            :key="value">
                            {{value}}
                        </li>
                        <v-divider class="ma-2"></v-divider>

                    </div>

                    <div v-show="item.detailed_lesson_times.thursday.length > 0">
                        <span class="font-weight-bold"> 목요일 </span>:
                        <li v-for="value in item.detailed_lesson_times.thursday"
                            :key="value">
                            {{value}}
                        </li>
                        <v-divider class="ma-2"></v-divider>

                    </div>

                    <div v-show="item.detailed_lesson_times.friday.length > 0">
                        <span class="font-weight-bold"> 금요일 </span>:
                        <li v-for="value in item.detailed_lesson_times.friday" :key="value">
                            {{value}}
                        </li>
                        <v-divider class="ma-2"></v-divider>

                    </div>

                    <div v-show="item.detailed_lesson_times.saturday.length > 0">
                        <span class="font-weight-bold"> 토요일 </span>:
                        <li v-for="value in item.detailed_lesson_times.saturday"
                            :key="value">
                            {{value}}
                        </li>
                        <v-divider class="ma-2"></v-divider>

                    </div>

                    <div v-show="item.detailed_lesson_times.sunday.length > 0">
                        <span class="font-weight-bold"> 일요일 </span>:
                        <li v-for="value in item.detailed_lesson_times.sunday" :key="value">
                            {{value}}
                        </li>
                        <v-divider class="ma-2"></v-divider>

                    </div>

                </div>
                <v-card-title class="font-weight-bold text-decoration-underline">
                    과외 기간
                </v-card-title>
                <div class="mx-5">
                    <span>
                        총
                    <span class="font-weight-bold">
                         {{item.expected_lesson_duration}}개월
                    </span>
                        동안
                    <br/>
                    </span>
                    <span>

                        일주일에
                    <span class="font-weight-bold">  {{item.expected_number_of_lessons_per_week}} 번씩 </span> 진행을 원해요!
                    <br/>
                    </span>
                </div>
                <div class="text-center">

                <v-btn color="secondary" class="mx-3 mt-7 mb-5"
                       @click.native="proceedLesson(item.lesson_id)"
                >
                    요청 수락
                </v-btn>
                </div>
            </div>
        </v-card>

        <!-- 선생님으로 가입했지만 아직 우리에게 등록하지 않았을시   -->
    </div>
</template>

<script>
    import TeacherService from "../../../services/teacher.service"
    import StudentService from "../../../services/student.service"
    import LessonService from "../../../services/lesson.service"
    // import AllLessons from "./Lessons/AllLessons";

    export default {
        name: "LessonRequests",
        components: {},
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
            lessonsRequests: function () {
                return this.lessons.filter(function (lesson) {
                    return lesson.lesson_status == 0;
                })
            }
        },
        mounted() {

            if (!this.currentUser) {
                this.$router.push('/login');
            }
            TeacherService.getTeacherInfo(this.currentUser.id).then(
                (response) => {
                    this.teacherContent = response.data;
                    console.log("===============")
                    console.log(this.teacherContent)
                    console.log("===============")
                    this.setLessonStatuses(this.teacherContent)
                },
                (error) => {
                    this.teacherContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
            TeacherService.getStudentsWhoHaveLessonsWithTeacherId(this.currentUser.id).then(
                (response) => {

                    this.lessons = response.data;
                    // this.teacherContent.detailedLessonTimes = JSON.parse(this.teacherContent.detailedLessonTimes)

                    this.parseLessonTimes()
                    // console.log("lessons:")
                    // console.log(this.lessons)
                    // console.log("lessons:")
                    // this.parseLessonTimeJson(this.lessons)


                },
                (error) => {
                    this.lessons =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },

        data: () => ({
            matchingOn: false,
            showLesson: false,
            lesson_asked: false,
            testValue: 1,
            lesson_message_sent_by_student: false,
            lesson_no_lessons: false,
            lesson_accepted: false,
            teacherLessons: {
                lesson_asked: [],
                lesson_message_sent_by_student: [],
                lesson_no_lessons: [],
                lesson_accepted: [],
            },
            lessonRequestBody: [],
            lessonResponseBody: [],
            lessonStudents: [],
            lessons: [],
            teacherContent: "",
            studentLessonContent: "",
            teacherLessonContent: "",
            studentContent: "",
            value: '',

        }),
        methods: {

            getStudentInfoFromLessons(studentId) {
                for (let i = 0; i < this.lessons.length; i++) {
                    // console.log("what")
                    // console.log(this.lessons[i])
                    // console.log("what")
                    if (this.lessons[i].student_id == studentId) {
                        return this.lessons[i];
                    }
                }
            },

            submitTeacherInfo(userId, requestBody) {


                requestBody.registerStatus = 'PENDING'
                TeacherService.updateTeacherAboutTeacher(userId, requestBody).then(
                    (response) => {
                        this.teacherContent = response.data;
                        this.$router.push("/profile")
                        this.next = true
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },

            parseLessonTimes() {
                for (let i = 0; i < this.lessonsRequests.length; i++) {
                    this.lessonsRequests[i].detailed_lesson_times = this.lessonsRequests[i].detailed_lesson_times.replace(/\\/g, '');
                    let temp = this.lessonsRequests[i].detailed_lesson_times
                    if (temp.charAt(0) == '"') {
                        temp = temp.substr(1);
                    }
                    if (temp.charAt(temp.length -1) == '"') {
                        temp = temp.substr(0, temp.length-1);
                    }
                    if (typeof temp == 'string' || temp instanceof String) {
                        temp = JSON.parse(temp);
                        this.lessonsRequests[i].detailed_lesson_times = temp;
                    }
                }
            },


            parseLessonTimeJson(lessons) {

                for (let i = 0; i < lessons.length; i++) {
                    // console.log("here")
                    // console.log(lessons[i].detailed_lesson_times)
                    // console.log("here")

                }
            },

            setLessonStatuses(responseBody) {


                for (let i = 0; i < responseBody.lessons.length; i++) {
                    console.log(responseBody.lessons[i].teacherMatchingStatus)
                    if (responseBody.lessons[i].teacherMatchingStatus == 'LESSON_ASKED') {
                        this.teacherLessons.lesson_asked.push(responseBody.lessons[i])
                        this.lesson_asked = true
                    } else if (responseBody.lessons[i].teacherMatchingStatus == 'MESSAGE_SENT_BY_STUDENT') {
                        this.lesson_message_sent_by_student = true;
                        this.teacherLessons.lesson_message_sent_by_student.push(responseBody.lessons[i])
                    } else if (responseBody.lessons[i].teacherMatchingStatus == 'NO_LESSONS') {
                        this.teacherLessons.lesson_no_lessons.push(responseBody.lessons[i]);
                        this.lesson_no_lessons = true;
                    } else if (responseBody.lessons[i].teacherMatchingStatus == 'LESSON_ACCEPTED') {
                        this.teacherLessons.lesson_accepted.push(responseBody.lessons[i]);
                        this.lesson_accepted = true;
                    }

                }
                console.log(this.lesson_message_sent_by_student)
            },

            getEvents({start, end}) {
                const events = []

                const min = new Date(`${start.date}T00:00:00`)
                const max = new Date(`${end.date}T23:59:59`)
                const days = (max.getTime() - min.getTime()) / 86400000
                const eventCount = this.rnd(days, days + 20)

                for (let i = 0; i < eventCount; i++) {
                    const allDay = this.rnd(0, 3) === 0
                    const firstTimestamp = this.rnd(min.getTime(), max.getTime())
                    const first = new Date(firstTimestamp - (firstTimestamp % 900000))
                    const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
                    const second = new Date(first.getTime() + secondTimestamp)

                    events.push({
                        name: this.names[this.rnd(0, this.names.length - 1)],
                        start: first,
                        end: second,
                        color: this.colors[this.rnd(0, this.colors.length - 1)],
                        timed: !allDay,
                    })
                }

                this.events = events
            },
            getEventColor(event) {
                return event.color
            },
            rnd(a, b) {
                return Math.floor((b - a + 1) * Math.random()) + a
            },
            getStudentInfo(studentId) {
                StudentService.getStudentInfo(studentId).then(
                    (response) => {
                        return response.data;
                    },
                    (error) => {
                        this.studentContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },

            getStudentInfoByLessonId(lessonId) {
                this.showLesson = true
                StudentService.getUserInfoByLessonId(lessonId).then(
                    (response) => {
                        // console.log("sexsesxsexsesxsexsesxsexsesx")
                        // console.log(response)
                        this.lessonStudents = response.data;
                        console.log(this.lessonStudents)
                        // console.log("sexsesxsexsesxsexsesxsexsesx")
                        // return response;
                    },
                    (error) => {
                        this.studentContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },

            proceedLesson(lessonId) {
                this.lessonRequestBody = {
                    status: 'TEACHER_ACCEPTED',
                    studentMatchingStatus: 'FOUND',
                    teacherMatchingStatus: 'TEACHER_PROCEEDING',
                }
                LessonService.updateLesson(lessonId, this.lessonRequestBody).then(
                    (response) => {

                        this.lessonResponseBody = response.data;
                        window.location.reload();
                        return response.data;
                    },
                    (error) => {
                        this.lessonResponseBody =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
                // this.$router.push('/profile')
                // this.$router.push({
                //     path: "/profile",
                //     query: {
                //         q: this.searchQuery,
                //         t: new Date().getTime(),
                //     }
                // });
                // window.location.reload();

                // location.reload();
            },

            confirmLesson(lessonId) {
                this.lessonRequestBody = {
                    status: 'SCHEDULED',
                    studentMatchingStatus: 'MATCHED',
                    teacherMatchingStatus: 'LESSON_ACCEPTED',
                },

                    LessonService.updateLesson(lessonId, this.lessonRequestBody).then(
                        (response) => {
                            this.lessonResponseBody = response.data;
                            window.location.reload();

                            return response.data;
                        },
                        (error) => {
                            this.lessonResponseBody =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                        }
                    );
            },


            rejectLesson(lessonId, requestBody) {
                LessonService.updateLesson(lessonId, requestBody).then(
                    (response) => {
                        this.lessonResponseBody = response.data;
                        return response.data;
                    },
                    (error) => {
                        this.lessonResponseBody =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },


            getLessonInfo(lessonId) {
                LessonService.getLessonInfo(lessonId).then(
                    (response) => {
                        console.log("get lesson info")
                        console.log(response)
                        console.log("get lesson info")
                        return response.data;
                    },
                    (error) => {
                        this.lessonResponseBody =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },


        }
    }
</script>


<style scoped>

    .all-lessons-background {
        background-color: #F0F0F0;
        max-width: 800px;
    }

</style>
