import axios from "axios";
import authHeader from "./auth-header";
import info from "../../info";

// const API_URL = 'http://localhost:8080/api/email';
// const API_URL = 'https://backend-ie.herokuapp.com/api/email';
// https://backend-ie.herokuapp.com/api/test/all

//local
// const API_URL = info.urls.local + 'email/';

//dev
const API_URL = info.urls.staging + 'email/';

//prod
// const API_URL = info.urls.production + 'email/';



class EmailService {

    sendEmailNotification(requestBody) {
        return axios.post(API_URL, requestBody, {headers: authHeader()});
    }

    sendEmailNotificationByUserId(requestBody, userId) {
        return axios.post(API_URL + "/" +userId, requestBody, {headers: authHeader()});
    }
}

export default new EmailService();
