<template>

    <v-container>
        <!--        <h1>{{currentUser}}</h1>-->
        <v-container v-if="currentUser.roles[0] == 'ROLE_TEACHER'">


            <v-card
                    class="mx-auto pa-5 primary--text"
                    max-width="800"
                    elevation="0"
            >
                <span
                        class="font-weight-bold"
                        :class="{
            'text-h6': $vuetify.breakpoint.mdAndDown,
            'text-h5': $vuetify.breakpoint.lgAndUp
            }">
                    희망 수업 요일 및 시간대 <v-icon color="primary">mdi-calendar-clock</v-icon>
                    <v-divider class="mt-3"></v-divider>

                </span>
                <p class="black--text text-subtitle-2">
                    희망 수업 시간대는 Korea Standard Time (KST) / 한국 표준시간으로 입력해주세요.
                </p>
            </v-card>
            <!--        <h1>{{teacherContent}}</h1>-->
            <!--            {{items}}-->
            <!--            {{removeEmptyStringFromList(teacherContent.subjectsOfExpertise)}}-->
            <v-card
                    class="mx-auto pa-5 mt-2 black--text justify-center"
                    max-width="800"
                    elevation="0"
            >

                <v-form v-model="formValid" class="ma-3">
                    <v-card-subtitle
                            :class="{}">

                    </v-card-subtitle>
                    <v-row class="">
                        <v-select
                                v-model="teacherContent.availableDays"
                                :items="preferredLessonDays"
                                label="선호 수업 요일 (다중 선택 가능)"
                                multiple
                                chips
                                :rules="[rules.daysRequired]"
                                auto-select-first
                                class="select"
                        >
                            <v-switch
                                    slot="prepend-item"
                                    @click="selectAllDays()"
                                    label="전체 선택"
                                    class="ml-1 pl-1"
                            ></v-switch>
                        </v-select>

                    </v-row>
<v-row>
                    <v-card elevation="0" class="ma-1 pa-1 text-subtitle-2"
                            v-show="teacherContent.availableDays != null">
                        <p>선호 일정을 많이 선택해 주실수록 빠르고 원할한 매칭이 가능합니다.</p>
                        <v-divider class="pa-1"></v-divider>
                        <a target="_blank"
                           class="link"
                           href="https://savvytime.com/converter/kst-to-pst-mst-ct-et-akst-hst-sgt">
                            Timezone 별 한국시간 검색하러가기 >
                        </a>
                    </v-card>
</v-row>
                    <v-row class="mt-15">
                    <v-select v-if="teacherContent.availableDays.includes('월')"
                                :items="preferredLessonTimeSlots"
                                v-model="teacherContent.detailedLessonTimes.monday"
                                dense
                                chips
                                label="월요일 세부 시간"
                                multiple
                                class="select"
                                :rules="[rules.specificTimeRequired]">
                        <v-switch
                                slot="prepend-item"
                                @click="selectAllMondayTimes()"
                                label="전체 선택"
                                class="ml-1 pl-1"
                        ></v-switch>
                    </v-select>
                    <v-select v-if="teacherContent.availableDays.includes('화')"
                                :items="preferredLessonTimeSlots"
                                v-model="teacherContent.detailedLessonTimes.tuesday"
                                dense
                                chips
                                label="화요일 세부 시간"
                                multiple
                                :rules="[rules.specificTimeRequired]"
                              class="select"

                    >
                        <v-switch
                                slot="prepend-item"
                                @click="selectAllTuesdayTimes()"
                                label="전체 선택"
                                class="ml-1 pl-1"
                        ></v-switch>
                    </v-select>
                    <v-select v-if="teacherContent.availableDays.includes('수')"
                                :items="preferredLessonTimeSlots"
                                v-model="teacherContent.detailedLessonTimes.wednesday"
                                dense
                                chips
                                label="수요일 세부 시간"
                                multiple
                                class="select"
                                :rules="[rules.specificTimeRequired]"
                    >
                        <v-switch
                                slot="prepend-item"
                                @click="selectAllWednesdayTimes()"
                                label="전체 선택"
                                class="ml-1 pl-1"
                        ></v-switch>
                    </v-select>
                    <v-select v-if="teacherContent.availableDays.includes('목')"
                                :items="preferredLessonTimeSlots"
                                v-model="teacherContent.detailedLessonTimes.thursday"
                                dense
                                chips
                                label="목요일 세부 시간"
                                multiple
                                class="select"
                                :rules="[rules.specificTimeRequired]">
                        <v-switch
                                slot="prepend-item"
                                @click="selectAllThursdayTimes()"
                                label="전체 선택"
                                class="ml-1 pl-1"
                        ></v-switch>
                    </v-select>
                    <v-select v-if="teacherContent.availableDays.includes('금')"
                                :items="preferredLessonTimeSlots"
                                v-model="teacherContent.detailedLessonTimes.friday"
                                dense
                                chips
                                label="금요일 세부 시간"
                                multiple
                                class="select"
                                :rules="[rules.specificTimeRequired]"
                    >
                        <v-switch
                                slot="prepend-item"
                                @click="selectAllFridayTimes()"
                                label="전체 선택"
                                class="ml-1 pl-1"
                        ></v-switch>
                    </v-select>
                    <v-select v-if="teacherContent.availableDays.includes('토')"
                                :items="preferredLessonTimeSlots"
                                v-model="teacherContent.detailedLessonTimes.saturday"
                                dense
                                chips
                                label="토요일 세부 시간"
                                multiple
                                class="select"
                                :rules="[rules.specificTimeRequired]"
                    >
                        <v-switch
                                slot="prepend-item"
                                @click="selectAllSaturdayTimes()"
                                label="전체 선택"
                                class="ml-1 pl-1"
                        ></v-switch>
                    </v-select>
                    <v-select v-if="teacherContent.availableDays.includes('일')"
                                :items="preferredLessonTimeSlots"
                                v-model="teacherContent.detailedLessonTimes.sunday"
                                dense
                                chips
                                label="일요일 세부 시간"
                                multiple
                                class="select"
                                :rules="[rules.specificTimeRequired]"
                    >
                        <v-switch
                                slot="prepend-item"
                                @click="selectAllSundayTimes()"
                                label="전체 선택"
                                class="ml-1 pl-1"
                        ></v-switch>
                    </v-select>
                    </v-row>
                    <!--                    <v-row>-->
                    <!--                        <v-col>-->
                    <!--                            <v-textarea-->
                    <!--                                    v-model="teacherContent.availableTimeRemarks"-->
                    <!--                                    label="가능한 시간에 대해 자세히 설명 해주시면 도움이 됩니다 :) "-->
                    <!--                                    flat-->
                    <!--                                    placeholder="ex) 주말에는 오전에만 가능해요."-->
                    <!--                            ></v-textarea>-->
                    <!--                        </v-col>-->
                    <!--                    </v-row>-->

                    <div class="mt-5">
                    <v-btn
                            v-show="teacherContent.registerStatus == 'NOT_STARTED'"
                            :disabled="!formValid"
                            color="secondary"
                            class="mr-3"
                            @click="updateTeacherInfo(currentUser.id, teacherContent)">
                        NEXT
                    </v-btn>
                    <v-btn
                            v-show="teacherContent.registerStatus != 'NOT_STARTED'"
                            :disabled="!formValid"
                            color="secondary"
                            class="mr-3"
                            @click="updateTeacherInfo(currentUser.id, teacherContent)">
                        수정하기
                    </v-btn>
                    <v-btn v-show="teacherContent.registerStatus == 'NOT_STARTED'"
                           :to="'/teacher/register/subjects'"
                           text>
                        Back
                    </v-btn>
                    <v-btn v-show="teacherContent.registerStatus != 'NOT_STARTED'"
                           :to="'/teacher/register/teacher/summary'" text>
                        Back
                    </v-btn>
                    </div>
                </v-form>
            </v-card>
        </v-container>
    </v-container>
</template>

<script>
    import TeacherService from '../../../services/teacher.service'
    import info from "../../../../info";

    export default {
        name: "FinalDegree",
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },

        },
        mounted() {
            TeacherService.getTeacherInfo(this.currentUser.id).then(
                (response) => {
                    this.teacherContent = response.data;
                    this.teacherContent.subjectsOfExpertise = this.removeEmptyStringFromList(this.teacherContent.subjectsOfExpertise);
                    this.teacherContent.availableTimes = this.removeEmptyStringFromList(this.teacherContent.availableTimes);
                    this.teacherContent.availableDays = this.removeEmptyStringFromList(this.teacherContent.availableDays);
                    this.teacherContent.preferredLessonStyle = this.removeEmptyStringFromList(this.teacherContent.preferredLessonStyle);
                    this.teacherContent.preferredLessonMaterial = this.removeEmptyStringFromList(this.teacherContent.preferredLessonMaterial);
                    this.teacherContent.preferredCoverMaterial = this.removeEmptyStringFromList(this.teacherContent.preferredCoverMaterial);
                    this.teacherContent.selectedMathSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedMathSubSubjects);
                    this.teacherContent.selectedScienceSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedScienceSubSubjects);
                    this.teacherContent.selectedEnglishSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedEnglishSubSubjects)
                    this.teacherContent.detailedLessonTimes = JSON.parse(this.teacherContent.detailedLessonTimes)
                    if (this.teacherContent.highestLevelOfEducation == '대학원 재학중' ||
                        this.teacherContent.highestLevelOfEducation == '석사 졸업' ||
                        this.teacherContent.highestLevelOfEducation == '박사 졸업') {
                        this.isBeyondUndergrad = true;
                    }
                },
                (error) => {
                    this.teacherContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
            console.log(this.teacherContent)

        },
        data() {
            return {
                teacherContent: "",
                formValid: false,
                undergrad: "",
                hasSelectedAllDays: false,
                hasSelectedAllMondayTimes: false,
                hasSelectedAllTuesdayTimes: false,
                hasSelectedAllWednesdayTimes: false,
                hasSelectedAllThursdayTimes: false,
                hasSelectedAllFridayTimes: false,
                hasSelectedAllSaturdayTimes: false,
                hasSelectedAllSundayTimes: false,
                gradSchoolType: info.gradSchoolType,
                preferredLessonTimes: info.preferredLessonTimes,
                preferredLessonDays: info.preferredLessonDays,
                preferredLessonTimeSlots: info.preferredLessonTimeSlots,
                rules: {
                    listNotEmpty: (value) => value.length > 0 || '필수 선택',
                    required: (value) => !!value || '필수 입력 값입니다.',
                    daysRequired: (value) => (value.includes('월') ||
                        value.includes('화') ||
                        value.includes('수') ||
                        value.includes('목') ||
                        value.includes('금') ||
                        value.includes('토') ||
                        value.includes('일')) || '필수 입력 값입니다.',
                    specificTimeRequired: (value) =>
                        (value.includes("00~04시") ||
                            value.includes("04~08시") ||
                            value.includes("08~12시") ||
                            value.includes("12~16시") ||
                            value.includes("16~20시") ||
                            value.includes("20~24시")) || '필수 입력 값입니다.',


                },

                possibleHighSchoolTypes: info.possibleHighSchoolTypes
            }
        },
        methods: {
            selectAllDays() {
                this.hasSelectedAllDays = !this.hasSelectedAllDays;
                if (this.hasSelectedAllDays) {
                    this.teacherContent.availableDays = [...this.preferredLessonDays]
                } else if (!this.hasSelectedAllDays) {
                    this.teacherContent.availableDays = []
                }
            },

            selectAllMondayTimes() {
                this.hasSelectedAllMondayTimes = !this.hasSelectedAllMondayTimes;
                if (this.hasSelectedAllMondayTimes) {
                    this.teacherContent.detailedLessonTimes.monday = [...this.preferredLessonTimeSlots]
                } else if (!this.hasSelectedAllMondayTimes) {
                    this.teacherContent.detailedLessonTimes.monday = []
                }
            },

            selectAllTuesdayTimes() {
                this.hasSelectedAllTuesdayTimes = !this.hasSelectedAllTuesdayTimes;
                if (this.hasSelectedAllTuesdayTimes) {
                    this.teacherContent.detailedLessonTimes.tuesday = [...this.preferredLessonTimeSlots]
                } else if (!this.hasSelectedAllTuesdayTimes) {
                    this.teacherContent.detailedLessonTimes.tuesday = []
                }
            },

            selectAllWednesdayTimes() {
                this.hasSelectedAllWednesdayTimes = !this.hasSelectedAllWednesdayTimes;
                if (this.hasSelectedAllWednesdayTimes) {
                    this.teacherContent.detailedLessonTimes.wednesday = [...this.preferredLessonTimeSlots]
                } else if (!this.hasSelectedAllWednesdayTimes) {
                    this.teacherContent.detailedLessonTimes.wednesday = []
                }
            },

            selectAllThursdayTimes() {
                this.hasSelectedAllThursdayTimes = !this.hasSelectedAllThursdayTimes;
                if (this.hasSelectedAllThursdayTimes) {
                    this.teacherContent.detailedLessonTimes.thursday = [...this.preferredLessonTimeSlots]
                } else if (!this.hasSelectedAllThursdayTimes) {
                    this.teacherContent.detailedLessonTimes.thursday = []
                }
            },

            selectAllFridayTimes() {
                this.hasSelectedAllFridayTimes = !this.hasSelectedAllFridayTimes;
                if (this.hasSelectedAllFridayTimes) {
                    this.teacherContent.detailedLessonTimes.friday = [...this.preferredLessonTimeSlots]
                } else if (!this.hasSelectedAllFridayTimes) {
                    this.teacherContent.detailedLessonTimes.friday = []
                }
            },

            selectAllSaturdayTimes() {
                this.hasSelectedAllSaturdayTimes = !this.hasSelectedAllSaturdayTimes;
                if (this.hasSelectedAllSaturdayTimes) {
                    this.teacherContent.detailedLessonTimes.saturday = [...this.preferredLessonTimeSlots]
                } else if (!this.hasSelectedAllSaturdayTimes) {
                    this.teacherContent.detailedLessonTimes.saturday = []
                }
            },

            selectAllSundayTimes() {
                this.hasSelectedAllSundayTimes = !this.hasSelectedAllSundayTimes;
                if (this.hasSelectedAllSundayTimes) {
                    this.teacherContent.detailedLessonTimes.sunday = [...this.preferredLessonTimeSlots]
                } else if (!this.hasSelectedAllSundayTimes) {
                    this.teacherContent.detailedLessonTimes.sunday = []
                }
            },


            updateTeacherInfo(userId, requestBody) {
                requestBody.detailedLessonTimes = JSON.stringify(this.teacherContent.detailedLessonTimes);
                TeacherService.updateTeacherLessonTimes(userId, requestBody).then(
                    (response) => {
                        this.teacherContent = response.data;
                        // this.$router.push("/teacher/register/lesson/duration")
                        if (this.teacherContent.registerStatus == 'NOT_STARTED') {
                            this.$router.push("/teacher/register/lesson/duration")
                        } else {
                            this.$router.push("/teacher/register/teacher/summary")
                        }
                        this.next = true
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },
            removeEmptyStringFromList(input) {
                return input.filter(element => {
                    return element !== '';
                });
            },

            stringToJson(input) {
                const temp = JSON.parse(input);
                console.log(temp)
                return temp;
            }
        }
    }
</script>

<style scoped>
    .link {
        color: #2200cc;
    }
    .select {
        max-width: 500px;
    }

    /*a:link {*/
    /*    color: blue;*/
    /*}*/

    /*a:visited {*/
    /*    color: purple;*/
    /*}*/
</style>
