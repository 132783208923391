<template>
    <div class="container">

<!--        <h1>Text Soap</h1>-->
<!--        <h2>An iMessage chat becomes fodder for daytime drama</h2>-->

<!--        <p class="comment">This begins one morning with my friend in San Diego telling me about an earthquake they just had.</p>-->
        <p class="comment text-h5 font-weight-bold text-center pb-5">“일정 조율이 힘드셨나요?”  </p>

        <div class="imessage text-size mb-15">
<!--            <p class="from-them">저녁 늦게 수업 <br/> 가능하신 선생님 <br/> 찾기가 어려워요.</p>-->
            <p class="from-them"> 저녁 늦게 가능하신<br/>  선생님 찾기가 <br/> 너무 어려워요..😥</p>
<!--            <p class="from-me">No Problem!<br/> IVYED에서 원하는 시간에 가능한 <br/> 선생님을 바로 찾아드립니다!</p>-->
            <p class="from-me">
                No Problem!<br/>
                IVYED에서는<br/>
                선호 시간대에 <br/>
                가능하신 선생님을 <br/>
                바로 찾아드립니다!</p>
<!--            <p class="from-me no-tail emoji">👍🏻</p>-->
<!--            <p class="from-me">Glad you&rsquo;re safe</p>-->
        </div>

        <p class="comment text-h5 font-weight-bold text-center pt-7 pb-5">“나에게 딱 맞는 선생님!”</p>
<!--        <p class="comment text-h5">학생분이</p>-->

        <div class="imessage text-size mb-15">

            <p class="from-them">개념정리 보다는 <br/><strong> 문제/과제풀이 <br/> 위주로 </strong>수업하면<br/> 좋을 것 같은데...</p>
            <!--            <p class="from-me">No Problem!<br/> IVYED에서 원하는 시간에 가능한 <br/> 선생님을 바로 찾아드립니다!</p>-->
            <p class="from-me">
                일정 뿐 아니라<br/>
                수강신청서에<br/>
                기입하신 <strong>모든 정보 <br/>
                기반으로 딱 맞는</strong> <br/>
                선생님이 배정됩니다.<br/></p>
            <!--            <p class="from-me no-tail emoji">👍🏻</p>-->
            <!--            <p class="from-me">Glad you&rsquo;re safe</p>-->
        </div>


        <p class="comment text-h5 font-weight-bold pt-7 pb-5 text-center">“수업에만 집중하세요!”</p>

        <div class="imessage text-size">
            <p class="from-them">선생님 개인사정으로 <br/>수업이 중단되면 <br/> 어떻게 하죠? <br/></p>
            <p class="from-them">            수업 공백이 생기면 <br/>너무 힘들어서..😢😢</p>


            <!--            <p class="from-me">No Problem!<br/> IVYED에서 원하는 시간에 가능한 <br/> 선생님을 바로 찾아드립니다!</p>-->
            <p class="from-me">
                Don't Worry!<br/>
                IVYED 스마트 매칭은<br/>
                선생님을 자동으로<br/>
                재배정 해드립니다.<br/></p>
            <p class="from-me">
                매끄러운 전환을 위해<br/>
                학생분의 수업정보가 <br/>
                새로운 선생님께 <br/>사전 전달됩니다.<br/>
            </p>
            <p class="from-them large-emoji">😍</p>


        </div>



<!--        <p class="comment">So now it's time for me to get back to work:</p>-->

<!--        <div class="imessage">-->
<!--            <p class="from-me">Time to write some code where I left off</p>-->
<!--        </div>-->

<!--        <p class="comment">She says:</p>-->

<!--        <div class="imessage">-->
<!--            <p class="from-them">Previously on As the Code Turns</p>-->
<!--        </div>-->

<!--        <p class="comment">So I decide to have a little fun with that hand-off:-->
<!--        </p>-->

<!--        <div class="imessage">-->
<!--            <p class="from-me no-tail margin-b_none">Previously...</p>-->
<!--            <p class="from-me no-tail">Brock went to the hospital to check on Cyntheeah...</p>-->
<!--            <p class="from-me no-tail">Notice the pretentious spelling of &ldquo;Cyntheeah&rdquo;...</p>-->
<!--            <p class="from-me no-tail">While in her hospital room, Diego is standing outside looking through the door window and can hear the entire conversation because he has super hearing.</p>-->
<!--            <p class="from-me margin-b_none">Elsewhere, Biff has plans to take down the entire Caspian family with his secret about how Roger Caspian used to be...REGINA Caspian!</p>-->
<!--            <p class="from-them margin-b_one margin-t_one">Haha</p>-->
<!--            <p class="from-me no-tail">And the budding romance between teens Erika and Johnny bloom</p>-->
<!--            <p class="from-me">Back at the hospital, cuz who doesn&rsquo;t hang out at hospitals in their free time, amirite...</p>-->
<!--            <p class="from-them margin-b_one margin-t_one">Riiiiight</p>-->
<!--            <p class="from-me no-tail margin-b_none">Brock leaves Cyntheeah&rsquo;s room, and Diego sneaks in while she sleeps...</p>-->
<!--            <p class="from-me no-tail">It&rsquo;s the worst hospital in the world cuz they leave her chart out for anyone to see...</p>-->
<!--            <p class="from-me no-tail">While the nurse isn&rsquo;t watching Diego looks at her chart...</p>-->
<!--            <p class="from-me no-tail">And discovers...</p>-->
<!--            <p class="from-me no-tail">[bad music]...[Commercial]</p>-->
<!--            <p class="from-me no-tail">...TO BE CONTINUED...</p>-->
<!--            <p class="from-me">Gotta run, have a safe day in the sun ☀️</p>-->
<!--            <p class="from-them margin-t_one">Haha, talk to you later.</p>-->
<!--        </div>-->

<!--        <p class="comment">So that was a fun exercise. I wasn't planning on doing anything more with it. But by mid-morning I got inspired...</p>-->

<!--        <div class="imessage">-->
<!--            <p class="from-me no-tail margin-b_none">Heiress Lexus von Doberman - cuz all towns have an heiress - takes an interest in her new pool man, Rodrigo...</p>-->
<!--            <p class="from-me no-tail">Lexus&rsquo;s time sunbathing by the pool is interrupted by Biff who has some juicy information on the Caspian family...</p>-->
<!--            <p class="from-me no-tail">The Caspians are the largest juice bar magnate in the tri-state area and Lexus plans to take them down, for no reason in particular, just because it&rsquo;s good daytime drama...</p>-->
<!--            <p class="from-me">Incidentally, Lexus&rsquo;s daughter is Erika. And Erika&rsquo;s love interest Johnny? Johnny Caspian. Cuz no one saw that little plot twist coming...</p>-->
<!--        </div>-->

<!--        <p class="comment">I started sending these back throughout the day like scenes from a 5-act play:</p>-->

<!--        <div class="imessage">-->
<!--            <p class="from-me no-tail margin-b_none">Back at the hospital, Diego sneaks out of Cyntheeah&rsquo;s room with a copy of her chart under his arm, right in front of the nurse&rsquo;s station, but no one notices cuz all the nurses are gossiping. Again, worst. Hospital. Ever.</p>-->
<!--            <p class="from-me no-tail">-->
<!--                Diego sneaks around the corner to the vending machine, looks in the chart again, with one eyebrow raised and a smirk, and scheming music playing in the background...</p>-->
<!--            <p class="from-me">[bad music]...[Commercial]</p>-->
<!--            <p class="from-them margin-b_one margin-t_one">This is awesome</p>-->
<!--            <p class="from-me no-tail">Brock returns to his home which is amazingly well-lit with track lighting in every room. His maid Juanita tells him that &ldquo;Ze strange man come by ask question about se&ntilde;or Brock&rdquo;. Brock makes a call and plans a meeting...</p>-->
<!--            <p class="from-me no-tail">Meanwhile at the beach cabana, Erika, Johnny and friends makes plans for a weekend getaway cruise as all 17-year-olds do...</p>-->
<!--            <p class="from-me">Incidentally, last week Erika and Johnny were 10 years-old, but kids don&rsquo;t age fast enough for daytime drama, so now they are 17! Overnight! Like magic!</p>-->
<!--        </div>-->

<!--        <p class="comment">-->
<!--            By early afternoon I could tell the story had taken on a life of its own.</p>-->

<!--        <div class="imessage">-->
<!--            <p class="from-me no-tail margin-b_none">Brock arrives at a restaurant for a lunch with his old marine buddy, Joze (not a misspelling) cuz nothing says &ldquo;secret meeting&rdquo; like a public restaurant in the middle of the day with plenty of on-lookers.</p>-->
<!--            <p class="from-me no-tail">Back at the hospital Dr. Stewart Collins is examining Cyntheeah. Both went to high school together and Stewart had an unrequited crush on her. He looks for her chart but it&rsquo;s missing...</p>-->
<!--            <p class="from-me no-tail">Dr. Stew asks the nurse if anyone has been in Cyntheeah&rsquo;s room and the nurse says &ldquo;just her brother...&rdquo;, to which Dr. Stew replies &ldquo;she has no brother...&rdquo;-->
<!--            <p class="from-me">[bad music]…[Commercial]</p>-->
<!--            <p class="from-them margin-t_one">Haha</p>-->
<!--        </div>-->

<!--        <p class="comment">Of course, I needed to introduce more characters, cuz that would really expand the iMessage soap universe...</p>-->

<!--        <div class="imessage">-->
<!--            <p class="from-me no-tail margin-b_none">Brock walks outside the restaurant and gets a phone call from his mother, Amelia, who has more money than Jesus Christ and casually wears padded blazers at home for the fun of it...</p>-->
<!--            <p class="from-me no-tail">&ldquo;Darling...just calling cuz I&rsquo;m dreading having drinks at the club with that Doberman bitch.&rdquo;</p>-->
<!--            <p class="from-me no-tail">&ldquo;Can&rsquo;t talk now mom...&rdquo;</p>-->
<!--            <p class="from-me no-tail">&ldquo;Yes, yes, always something to do with the downtrodden folk&rdquo;</p>-->
<!--            <p class="from-me no-tail">As Brock hangs up he notices seated at a table across the street someone who suspiciously looks like Diego. But as a bus passes by the seat is now empty.</p>-->
<!--            <p class="from-me no-tail">Joze heads to the beach club for his &ldquo;day job&rdquo; as the former Iraq vet is now in charge of the swanky up-scale beach resort...</p>-->
<!--            <p class="from-me no-tail">He spots Johnny and whistles to his favorite cabana boy. &ldquo;Towels need to be changed in Cabana #5, lady and her poodle need more sunscreen.&ldquo;</p>-->
<!--            <p class="from-me no-tail">Johnny, having forsaken his family&rsquo;s juice bar fortune, is working a &ldquo;real job&rdquo; for his own cash. Plus, the beach club is a good spot to make lots of coke deals.</p>-->
<!--            <p class="from-me no-tail">Joze goes into his office, shuts the door, and opens his wardrobe filled with cheap Love Boat white jackets, &agrave; la Capt. Stubing. Behind the blazers is a safe...</p>-->
<!--            <p class="from-me no-tail">Joze unlocks the safe and pulls out a stack of bills and two hand guns. He raises an eyebrow, loads a clip into one of the guns...</p>-->
<!--            <p class="from-me">[bad music]...[Commericial]</p>-->
<!--        </div>-->

<!--        <p class="comment">I dunno who Joze is, but I don&rsquo;t wanna be on his bad side.</p>-->

<!--        <div class="imessage">-->
<!--            <p class="from-them">This is GOLD!</p>-->
<!--        </div>-->

<!--        <p class="comment">Naturally.</p>-->

<!--        <div class="imessage">-->
<!--            <p class="from-me no-tail margin-b_none">Lexus and Amelia have drinks at the country club but each hates the other. Lexus casually mentions she has a new pool man. She also knows that Amelia has been sleeping with her right hand man Biff. No one know what any of this has to do with anything, but the scheming is afoot...</p>-->
<!--            <p class="from-me no-tail">Back at the hospital, Brock returns to see Cyntheeah but Dr. Stew stops him in the hall. He asks about the missing chart, cuz doctors don&rsquo;t bother with security, they just do it all themselves. Dr. Stew won&rsquo;t let Brock see her...</p>-->
<!--            <p class="from-me no-tail">Brock goes behind the nurses station and raids the drawers for some Vicodin, cuz the nurses are too busy doing something other than their job. Just as Brock turns the corner he&rsquo;s met face-to-face with...</p>-->
<!--            <p class="from-me no-tail">DAMIAN CASPIAN!</p>-->
<!--            <p class="from-me">Damian is the older brother to Johnny and the rebel of the Caspian juice bar clan. He&rsquo;s obviously not at the hospital to sell smoothies...</p>-->
<!--        </div>-->

<!--        <p class="comment">This is classic rebel guy with rebel name showing up at the most inconvenient time.</p>-->

<!--        <div class="imessage">-->
<!--            <p class="from-me no-tail margin-b_none">At the beach club, cabana boy Johnny is changing towels when he spots Biff. He knows Biff from when he&rsquo;d sneak out of Erika&rsquo;s bedroom at the same time Biff was sneaking out Lexus&rsquo;s window. Also Biff drives a 1984 Pontiac Fierro</p>-->
<!--            <p class="from-me no-tail">Biff is there to pick up Erika and to score some blow from the high school dealers. Johnny hides behind a towel rack and sees Joze, sporting a Magnum PI Hawaiian shirt with two Glock 9&rsquo;s leaving his office.</p>-->
<!--            <p class="from-me no-tail">As Joze leaves he spots Biff, and Biff spots him. The camera closes in on their faces and they squint...</p>-->
<!--            <p class="from-me">[bad music]...[Commercial]</p>-->
<!--        </div>-->

<!--        <p class="comment">So by the end of the day I knew I needed to tie up things. The final act:</p>-->

<!--        <div class="imessage">-->

<!--            <p class="from-me no-tail margin-b_none">[Cut to Diego on a boat in the marina]</p>-->
<!--            <p class="from-me no-tail">Diego is talking on the phone. Remarkably his phone service is crystal clear.</p>-->
<!--            <p class="from-me">Back at the hospital, Damian and Brock stare each other down. No one says anything, no one flinches or blinks. Damian is holding a smoothie...</p>-->
<!--        </div>-->

<!--        <p class="comment">That kind of thing probably <em>does</em> happen a lot in real life...</p>-->

<!--        <div class="imessage">-->
<!--            <p class="from-me no-tail margin-b_none">Brock turns around and goes back to Cyntheeah&rsquo;s room. The nurse tries to stop him, which is uncharacteristic of the nurse since she hasn&rsquo;t been doing her job at all today. As Brock opens the hospital room door he and the nurse see the bed is empty...</p>-->
<!--            <p class="from-me no-tail">Cyntheeah is gone...</p>-->
<!--            <p class="from-me no-tail">Damian strolls in behind: &ldquo;Well well well, isn&rsquo;t that lovely.&rdquo;</p>-->
<!--            <p class="from-me no-tail">Lexus returns homes to find her pool man Rodrigo in her bedroom and looking through her drawers...</p>-->
<!--            <p class="from-me no-tail">Lexus: &ldquo;What the hell?!&rdquo; Rodrigo: &ldquo;No hablo Englais, se&ntilde;ora.&rdquo; ¯\_(ツ)_/¯</p>-->
<!--            <p class="from-me no-tail">She sees what he&rsquo;s holding. &ldquo;I think I have the perfect job for you, Rodrigo.&rdquo;-->
<!--            <p class="from-me">Joze is in his car applying a fake mustache to his face, cuz he&rsquo;s always been a Tom Selleck fan. He checks his Glock, puts on his sunglasses, then lets the tires of his Ford Ranchero squeal down the road.</p>-->
<!--        </div>-->

<!--        <p class="comment">And so...</p>-->

<!--        <div class="imessage">-->
<!--            <p class="from-me no-tail margin-b_none">The Caspians plan to introduce a new chocolate with red pepper smoothie at their juice bar that could cause controversy…and a make a few enemies...</p>-->
<!--            <p class="from-me no-tail">Erika is at Johnny&rsquo;s house and both are packing for the weekend cruise. Their dialog is only for the younger viewers: Beyoncé this, Kanye that...</p>-->
<!--            <p class="from-me no-tail">Diego&rsquo;s boat blows up.</p>-->
<!--            <p class="from-me">...CLIFFHANGER...</p>-->
<!--            <p class="from-me emoji">😎</p>-->
<!--            <p class="from-them margin-t_one emoji">😱</p>-->
<!--        </div>-->

<!--        <p class="comment">And just like that, a new genre of daytime drama was born: Text Soap.</p>-->

    </div>
</template>

<script>
    export default {
        name: "SmartMatching"
    }
</script>

<style scoped>
    .imessage {
        background-color: #F7F8FA;
        /*border: 1px solid #e5e5ea;*/
        /*border-radius: 0.25rem;*/
        display: flex;
        flex-direction: column;
        font-family: "SanFrancisco";
        font-size: 1.25rem;
        margin: 0 auto 1rem;
        max-width: 600px;
        padding: 0.5rem 1.5rem;
    }

    .imessage p {
        border-radius: 1.15rem;
        line-height: 1.25;
        max-width: 75%;
        padding: 0.5rem .875rem;
        position: relative;
        word-wrap: break-word;
    }

    .imessage p::before,
    .imessage p::after {
        bottom: -0.1rem;
        content: "";
        height: 1rem;
        position: absolute;
    }

    p.from-me {
        align-self: flex-end;
        background-color: #248bf5;
        color: #fff;
    }

    p.from-me::before {
        border-bottom-left-radius: 0.8rem 0.7rem;
        border-right: 1rem solid #248bf5;
        right: -0.35rem;
        transform: translate(0, -0.1rem);
    }

    p.from-me::after {
        background-color: #F7F8FA;
        border-bottom-left-radius: 0.5rem;
        right: -40px;
        transform:translate(-30px, -2px);
        width: 10px;
    }

    p[class^="from-"] {
        margin: 0.5rem 0;
        width: fit-content;
    }

    p.from-me ~ p.from-me {
        margin: 0.25rem 0 0;
    }

    p.from-me ~ p.from-me:not(:last-child) {
        margin: 0.25rem 0 0;
    }

    p.from-me ~ p.from-me:last-child {
        margin-bottom: 0.5rem;
    }

    p.from-them {
        align-items: flex-start;
        background-color: #e5e5ea;
        color: #000;
    }

    p.from-them:before {
        border-bottom-right-radius: 0.8rem 0.7rem;
        border-left: 1rem solid #e5e5ea;
        left: -0.35rem;
        transform: translate(0, -0.1rem);
    }

    p.from-them::after {
        background-color: #F7F8FA;
        border-bottom-right-radius: 0.5rem;
        left: 20px;
        transform: translate(-30px, -2px);
        width: 10px;
    }

    p[class^="from-"].emoji {
        background: none;
        font-size: 2.5rem;
    }

    p[class^="from-"].emoji::before {
        content: none;
    }

    .no-tail::before {
        display: none;
    }

    .margin-b_none {
        margin-bottom: 0 !important;
    }

    .margin-b_one {
        margin-bottom: 1rem !important;
    }

    .margin-t_one {
        margin-top: 1rem !important;
    }














    /* general styling */
    @font-face {
        font-family: "SanFrancisco";
        src:
                url("https://cdn.rawgit.com/AllThingsSmitty/fonts/25983b71/SanFrancisco/sanfranciscodisplay-regular-webfont.woff2") format("woff2"),
                url("https://cdn.rawgit.com/AllThingsSmitty/fonts/25983b71/SanFrancisco/sanfranciscodisplay-regular-webfont.woff") format("woff");
    }

    body {
        font-family: -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        Oxygen-Sans,
        Ubuntu,
        Cantarell,
        "Helvetica Neue",
        sans-serif;
        font-weight: normal;
        margin: 0;
    }

    .container {
        margin: 0 auto;
        max-width: 600px;
        padding: 1rem;
    }

    h1 {
        font-weight: normal;
        margin-bottom: 0.5rem;
    }

    h2 {
        border-bottom: 1px solid #e5e5ea;
        color: #666;
        font-weight: normal;
        margin-top: 0;
        padding-bottom: 1.5rem;
    }

    .comment {
        color: #222;
        font-size: 1.25rem;
        line-height: 1.5;
        margin-bottom: 1.25rem;
        max-width: 100%;
        padding: 0;
    }

    @media screen and (max-width: 800px) {
        body {
            margin: 0 0.5rem;
        }

        .container {
            padding: 0.5rem;
        }

        .imessage {
            font-size: 1.05rem;
            margin: 0 auto 1rem;
            max-width: 600px;
            padding: 0.25rem 0.875rem;
        }

        .imessage p {
            margin: 0.5rem 0;
        }
    }
    .text-size {
        font-size: 18px;
        letter-spacing: 1px;
    }
    .large-emoji {
        font-size: 80px;
    }
</style>
