<template>
    <!--    <v-card class="py-1" v-if="lessons.length <= 0">-->
    <!--        현재 매칭이 진행중입니다.-->
    <!--    </v-card>-->
    <div class="py-1 all-lessons-background" v-if="this.allLessons.length > 0">
        <!--{{lessons}}-->
        <v-card outlined
                class="ma-4" v-for="item in allLessons"
                max-width="800"
                :key="item.id">
            <!--            {{item.updated_at}}-->
            <!--            v-if="item.lesson_status == 0"-->
            <div>
                <!--                NOT_STARTED-->
                <!--                {{typeof item.detailed_lesson_times}}-->
                <v-chip v-if="item.lesson_status == 0" class="ma-3 accent--text" color="#A20916"> 요청</v-chip>
                <v-chip v-if="item.lesson_status == 1 || item.lesson_status == 5" class="ma-3 accent--text"
                        color="#579004"> 준비 중
                </v-chip>
                <p class="primary--text ma-5" v-if="item.lesson_status == 1 || item.lesson_status == 5">
                    수업 진행하실 과외방 생성 후 연락드리겠습니다. 🙂
                </p>
                <v-chip v-if="item.lesson_status == 6" class="ma-3 accent--text" color="#0058DC"> 매칭 완료</v-chip>
                <!--                <div class="text-subtitle-2 ma-2"> 요청 시간: {{new Date(item.updated_at)}}</div>-->
                <!-- show only if lesson status == NOT_STARTED -->
                <!--                                    {{item.lesson_status}}-->
                <!--                                    {{item.student_id}}-->
                <!--                                    {{item.lesson_id}}-->
                <!--                                    {{item}}-->

                <!--                                    <h3>{{item.lesson_status}}</h3>-->
                <v-card-title class="font-weight-bold text-decoration-underline">
                    학생 정보
                </v-card-title>
                <div class="mx-5">
                    <!--                    <span class="font-weight-bold">{{item.school}} ({{item.school_type}}), {{item.current_location}}</span>-->
                    <strong>{{item.current_location}}</strong> 거주 중이며 <strong>{{item.school}}</strong> (<strong>{{item.school_type}}</strong>)
                    <strong>{{item.current_grade}}</strong>
                    학생입니다.
                    <br/>
                    진학 희망 전공 계열은 <strong>{{item.desired_major}}</strong> 입니다. 📚
                    <br/>
                    <!--                    재학중인-->
                    <!--                    <span class="font-weight-bold"> {{item.desired_major}}</span> 계열 전공 진학을-->
                    <!--                    희망하는-->
                    <!--                    <span class="font-weight-bold">{{item.current_grade}}</span> 학생이에요.-->
                    <br/>

                </div>
                <div class="mx-5" v-if="item.lesson_status == 6">

                    <span class="font-weight-bold ">
                         학생명:
                        </span> {{item.student_last_name}} {{item.student_first_name}}
                    <br/>

                    <span class="font-weight-bold">
                        카톡 ID:
                        </span> {{item.kakao_id}}
                    <br/>
                    <span class="font-weight-bold">
                        이메일:
                        </span> {{item.student_email}}
                    <br/>
                    <span class="font-weight-bold">
                        전화번호:
                    </span>
                    <span v-if="item.country_code == '직접 입력'">{{item.phone_number}}</span>
                    <span v-if="item.country_code != '직접 입력' && item.country_code != null"> (+{{item.country_code.replace(/\D/g,'')}}) {{item.phone_number}}</span>
                    <br/>

                </div>

                <v-card-title class="font-weight-bold text-decoration-underline">
                    <span v-if="item.lesson_status != 6"> 희망</span> 수업 정보

                </v-card-title>
                <div class="mx-5">
                    <!--                                        <span v-for="value in item.preferred_lesson_style" :key="value">-->
                    <!--                                        {{value}}-->
                    <!--                                        </span>-->
                    <span>

                        <span class="" v-if="item.lesson_status != 6">도움이 필요한 과목은
                            <span class="font-weight-bold text-decoration-underline">
                                {{item.subjects_of_interest}} - {{item.sub_subjects}}
                            </span>
                            입니다.
                        </span>
                        <div v-if="item.lesson_status == 6">
                        <span class="font-weight-bold">
                        과목:
                        </span> {{item.subjects_of_interest}} - {{item.sub_subjects}}
                        <br/>
                        <span class="font-weight-bold">
                        수업 횟수:
                        </span> 주 {{item.expected_number_of_lessons_per_week}}회
                        <br/>
                            </div>
                                                            <span class="font-weight-bold">
                                                                선호 수업 방식:
                                                            </span> {{item.preferred_lesson_style}}
                                                            <br/>
                                                            </span>
                    <span>
                                                            <span class="font-weight-bold">
                                                                선호 교재:
                                                            </span> {{item.preferred_lesson_material}}
                                                                <br/>
                                                                </span>
                    <span>
                                                            <span class="font-weight-bold">
                                                                수업 목적:
                                                            </span> {{item.goal_of_lesson}}
                                                                <br/>
                                                                </span>
                    <span v-if="item.lesson_remarks != null">
                        <span class="font-weight-bold">
                            기타 요청 사항:
                        </span> {{item.lesson_remarks}}
                        <br/>
                    </span>
                </div>
                <div v-if="item.lesson_status != 6">
                <v-card-title  class="font-weight-bold text-decoration-underline">
                    선호 일정
                </v-card-title>
                <div class="mx-5">
                    <span>
                        일주일에
                        <span class="font-weight-bold">  {{item.expected_number_of_lessons_per_week}} 번씩 </span> 진행을 원해요!
                    </span>
                    <br/>
                    <br/>
                    <div v-show="item.detailed_lesson_times.monday.length > 0">
                        <!--                                            <span class="font-weight-bold"> 월요일 </span>: {{item.detailed_lesson_times.monday}}-->
                        <span class="font-weight-bold"> 월요일 </span>:
                        <li v-for="value in item.detailed_lesson_times.monday" :key="value">
                            {{value}}
                        </li>
                        <v-divider class="ma-2"></v-divider>

                    </div>
                    <div v-show="item.detailed_lesson_times.tuesday.length > 0">
                        <span class="font-weight-bold"> 화요일 </span>:
                        <li v-for="value in item.detailed_lesson_times.tuesday"
                            :key="value">
                            {{value}}
                        </li>
                        <v-divider class="ma-2"></v-divider>

                    </div>

                    <div v-show="item.detailed_lesson_times.wednesday.length > 0">
                        <span class="font-weight-bold"> 수요일 </span>:
                        <li v-for="value in item.detailed_lesson_times.wednesday"
                            :key="value">
                            {{value}}
                        </li>
                        <v-divider class="ma-2"></v-divider>

                    </div>

                    <div v-show="item.detailed_lesson_times.thursday.length > 0">
                        <span class="font-weight-bold"> 목요일 </span>:
                        <li v-for="value in item.detailed_lesson_times.thursday"
                            :key="value">
                            {{value}}
                        </li>
                        <v-divider class="ma-2"></v-divider>

                    </div>

                    <div v-show="item.detailed_lesson_times.friday.length > 0">
                        <span class="font-weight-bold"> 금요일 </span>:
                        <li v-for="value in item.detailed_lesson_times.friday" :key="value">
                            {{value}}
                        </li>
                        <v-divider class="ma-2"></v-divider>

                    </div>

                    <div v-show="item.detailed_lesson_times.saturday.length > 0">
                        <span class="font-weight-bold"> 토요일 </span>:
                        <li v-for="value in item.detailed_lesson_times.saturday"
                            :key="value">
                            {{value}}
                        </li>
                        <v-divider class="ma-2"></v-divider>

                    </div>

                    <div v-show="item.detailed_lesson_times.sunday.length > 0">
                        <span class="font-weight-bold"> 일요일 </span>:
                        <li v-for="value in item.detailed_lesson_times.sunday" :key="value">
                            {{value}}
                        </li>
                        <v-divider class="ma-2"></v-divider>

                    </div>

                </div>
                <v-card-title class="font-weight-bold text-decoration-underline">
                    과외 기간
                </v-card-title>
                <div class="mx-5 mb-2">
                    <span>
                        총
                    <span class="font-weight-bold">
                         {{item.expected_lesson_duration}}개월
                    </span>
                        동안
                    <br/>
                    </span>
                    <span>

                        일주일에
                    <span class="font-weight-bold">  {{item.expected_number_of_lessons_per_week}} 번씩 </span> 진행을 원해요!
                    <br/>
                    </span>
                </div>
                </div>
                <div v-if="item.lesson_status == 0" class="text-center">
                    <v-btn color="secondary" class="mx-3 mt-7 mb-5"
                           @click.native="proceedLesson(item.lesson_id)">
                        요청 수락
                    </v-btn>
                </div>
                <v-divider v-if="item.lesson_status == 6" class="ma-3"></v-divider>
<!--                <div v-if="item.lesson_status == 6" class="ml-6 mt-2">-->
<!--                    <row>-->
<!--                        <v-col>-->
<!--                            <span class="font-weight-bold text-h6">화이트보드 링크</span>-->
<!--                            <div>-->
<!--                                <p>{{item.page_call_link}}</p>-->
<!--                                <v-btn class="my-3" outlined :href="item.page_call_link"-->
<!--                                       target="_blank" color="primary">입장하기-->
<!--                                </v-btn>-->
<!--                            </div>-->
<!--                        </v-col>-->
<!--                        <v-col>-->
<!--                            <span class="font-weight-bold text-h6">수업일지 링크</span>-->
<!--                            <div>-->
<!--                                <p>{{item.report_link}}</p>-->

<!--                                <v-btn class="my-3" outlined :href="item.report_link"-->
<!--                                       target="_blank" color="primary">수업일지-->
<!--                                </v-btn>-->
<!--                            </div>-->
<!--                        </v-col>-->
<!--                    </row>-->
<!--                </div>-->
                <div v-if="item.lesson_status == 6" class=" text-center mb-4 mt-2">
                    <row>
                        <v-btn class="my-3 mr-7" elevation="1" :href="item.page_call_link"
                               target="_blank" color="secondary">과외방 입장
                        </v-btn>
                        <v-btn class="my-3" elevation="1" outlined :href="item.report_link"
                               target="_blank" color="secondary">수업일지 보기
                        </v-btn>
                    </row>
                </div>

            </div>

            <!--                TEACHER_ACCEPTED-->
            <!--            <div class="pb-3" v-if="item.lesson_status == 1 || item.lesson_status == 5">-->
            <!--                <v-chip class="ma-3 accent&#45;&#45;text" color="#579004"> 준비 중</v-chip>-->
            <!--                <v-card-title class="font-weight-bold text-decoration-underline">-->
            <!--                    학생 정보-->
            <!--                </v-card-title>-->
            <!--                <div class="ml-6">-->
            <!--                    <span class="font-weight-bold">{{item.school}} ({{item.school_type}}), {{item.current_location}} </span>에-->
            <!--                    재학중인-->
            <!--                    <span class="font-weight-bold"> {{item.desired_major}}</span> 계열 전공 진학을-->
            <!--                    희망하는-->
            <!--                    <span class="font-weight-bold">{{item.current_grade}}</span> 학생이에요.-->
            <!--                    <br/>-->

            <!--                </div>-->
            <!--                <v-card-title class="font-weight-bold text-decoration-underline">-->
            <!--                    희망 수업 정보-->

            <!--                </v-card-title>-->
            <!--                <div class="ml-6">-->
            <!--                    &lt;!&ndash;                                        <span v-for="value in item.preferred_lesson_style" :key="value">&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                        {{value}}&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                        </span>&ndash;&gt;-->
            <!--                    <span>-->
            <!--                                                                <span class="">도움이 필요한 과목은-->
            <!--                                                                    <span class="font-weight-bold text-decoration-underline">-->
            <!--                                                                    {{item.subjects_of_interest}} - {{item.sub_subjects}}-->
            <!--                                                                </span>-->
            <!--                                                                    입니다.-->
            <!--                                                                </span>-->
            <!--                                                                <br/>-->
            <!--                                                                <br/>-->
            <!--                                                            <span class="font-weight-bold">-->
            <!--                                                                선호 수업 방식:-->
            <!--                                                            </span> {{item.preferred_lesson_style}}-->
            <!--                                                            <br/>-->
            <!--                                                            </span>-->
            <!--                    <span>-->
            <!--                                                            <span class="font-weight-bold">-->
            <!--                                                                선호 교재:-->
            <!--                                                            </span> {{item.preferred_lesson_material}}-->
            <!--                                                                <br/>-->
            <!--                                                                </span>-->
            <!--                    <span>-->
            <!--                                                            <span class="font-weight-bold">-->
            <!--                                                                수업 목적:-->
            <!--                                                            </span> {{item.goal_of_lesson}}-->
            <!--                                                                <br/>-->
            <!--                                                                </span>-->
            <!--                </div>-->
            <!--                <v-card-title class="font-weight-bold text-decoration-underline">-->
            <!--                    선호 일정-->
            <!--                </v-card-title>-->
            <!--                <div class="ml-6">-->
            <!--                    <div v-show="item.detailed_lesson_times.monday != null">-->
            <!--                        &lt;!&ndash;                                            <span class="font-weight-bold"> 월요일 </span>: {{item.detailed_lesson_times.monday}}&ndash;&gt;-->
            <!--                        <span class="font-weight-bold"> 월요일 </span>:-->
            <!--                        <li v-for="value in item.detailed_lesson_times.monday" :key="value">-->
            <!--                            {{value}}-->
            <!--                        </li>-->
            <!--                        <v-divider class="ma-2"></v-divider>-->

            <!--                    </div>-->
            <!--                    <div v-show="item.detailed_lesson_times.tuesday != null">-->
            <!--                        <span class="font-weight-bold"> 화요일 </span>:-->
            <!--                        <li v-for="value in item.detailed_lesson_times.tuesday"-->
            <!--                            :key="value">-->
            <!--                            {{value}}-->
            <!--                        </li>-->
            <!--                        <v-divider class="ma-2"></v-divider>-->

            <!--                    </div>-->

            <!--                    <div v-show="item.detailed_lesson_times.wednesday != null">-->
            <!--                        <span class="font-weight-bold"> 수요일 </span>:-->
            <!--                        <li v-for="value in item.detailed_lesson_times.wednesday"-->
            <!--                            :key="value">-->
            <!--                            {{value}}-->
            <!--                        </li>-->
            <!--                        <v-divider class="ma-2"></v-divider>-->

            <!--                    </div>-->

            <!--                    <div v-show="item.detailed_lesson_times.thursday != null">-->
            <!--                        <span class="font-weight-bold"> 목요일 </span>:-->
            <!--                        <li v-for="value in item.detailed_lesson_times.thursday"-->
            <!--                            :key="value">-->
            <!--                            {{value}}-->
            <!--                        </li>-->
            <!--                        <v-divider class="ma-2"></v-divider>-->

            <!--                    </div>-->

            <!--                    <div v-show="item.detailed_lesson_times.friday != null">-->
            <!--                        <span class="font-weight-bold"> 금요일 </span>:-->
            <!--                        <li v-for="value in item.detailed_lesson_times.friday" :key="value">-->
            <!--                            {{value}}-->
            <!--                        </li>-->
            <!--                        <v-divider class="ma-2"></v-divider>-->

            <!--                    </div>-->

            <!--                    <div v-show="item.detailed_lesson_times.saturday != null">-->
            <!--                        <span class="font-weight-bold"> 토요일 </span>:-->
            <!--                        <li v-for="value in item.detailed_lesson_times.saturday"-->
            <!--                            :key="value">-->
            <!--                            {{value}}-->
            <!--                        </li>-->
            <!--                        <v-divider class="ma-2"></v-divider>-->

            <!--                    </div>-->

            <!--                    <div v-show="item.detailed_lesson_times.sunday != null">-->
            <!--                        <span class="font-weight-bold"> 일요일 </span>:-->
            <!--                        <li v-for="value in item.detailed_lesson_times.sunday" :key="value">-->
            <!--                            {{value}}-->
            <!--                        </li>-->
            <!--                        <v-divider class="ma-2"></v-divider>-->

            <!--                    </div>-->

            <!--                </div>-->
            <!--                <v-card-title class="font-weight-bold text-decoration-underline">-->
            <!--                    과외 기간-->
            <!--                </v-card-title>-->
            <!--                <div class="ml-6">-->
            <!--                                                            <span>-->
            <!--                                                                총-->
            <!--                                                            <span class="font-weight-bold">-->
            <!--                                                                 {{item.expected_lesson_duration}}개월-->
            <!--                                                            </span>-->
            <!--                                                                동안-->
            <!--                                                            <br/>-->
            <!--                                                            </span>-->
            <!--                    <span>-->

            <!--                                                                일주일에-->
            <!--                                                            <span class="font-weight-bold">  {{item.expected_number_of_lessons_per_week}} 번씩 </span> 진행을 원해요!-->
            <!--                                                            <br/>-->
            <!--                                                            </span>-->
            <!--                </div>-->

            <!--            </div>-->
            <!--                BOTH ACCEPTED-->
            <!--            <div v-if="item.lesson_status == 6">-->
            <!--                <v-chip class="ma-3 accent&#45;&#45;text" color="#0058DC"> 매칭 완료</v-chip>-->

            <!--                <v-card-title class="font-weight-bold text-decoration-underline">-->
            <!--                    학생 정보-->
            <!--                </v-card-title>-->
            <!--                <div class="ml-6">-->
            <!--                    <span class="font-weight-bold">{{item.school}} ({{item.school_type}}), {{item.current_location}} </span>에-->
            <!--                    재학중인-->
            <!--                    <span class="font-weight-bold"> {{item.desired_major}}</span> 계열 전공 진학을-->
            <!--                    희망하는-->
            <!--                    <span class="font-weight-bold">{{item.current_grade}}</span> 학생이에요.-->
            <!--                    <br/>-->
            <!--                </div>-->
            <!--                <v-card-title class="font-weight-bold text-decoration-underline">-->
            <!--                    희망 수업 정보-->

            <!--                </v-card-title>-->
            <!--                <div class="ml-6">-->
            <!--                    &lt;!&ndash;                                        <span v-for="value in item.preferred_lesson_style" :key="value">&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                        {{value}}&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                        </span>&ndash;&gt;-->
            <!--                    <span>-->
            <!--                        <span class="">도움이 필요한 과목은-->
            <!--                            <span class="font-weight-bold text-decoration-underline">-->
            <!--                                {{item.subjects_of_interest}} - {{item.sub_subjects}}-->
            <!--                            </span>-->
            <!--                            입니다.-->
            <!--                        </span>-->
            <!--                        <br/>-->
            <!--                        <br/>-->
            <!--                        <span class="font-weight-bold">-->
            <!--                        선호 수업 방식:-->
            <!--                        </span> {{item.preferred_lesson_style}}-->
            <!--                        <br/>-->
            <!--                        </span>-->
            <!--                    <span>-->
            <!--                                                            <span class="font-weight-bold">-->
            <!--                                                                선호 교재:-->
            <!--                                                            </span> {{item.preferred_lesson_material}}-->
            <!--                                                                <br/>-->
            <!--                                                                </span>-->
            <!--                    <span>-->
            <!--                                                            <span class="font-weight-bold">-->
            <!--                                                                수업 목적:-->
            <!--                                                            </span> {{item.goal_of_lesson}}-->
            <!--                                                                <br/>-->
            <!--                                                                </span>-->
            <!--                </div>-->
            <!--                <v-card-title class="font-weight-bold text-decoration-underline">-->
            <!--                    선호 일정-->
            <!--                </v-card-title>-->
            <!--                <div class="ml-6">-->
            <!--                    <div v-show="item.detailed_lesson_times.monday.length > 0">-->
            <!--                        &lt;!&ndash;                                            <span class="font-weight-bold"> 월요일 </span>: {{item.detailed_lesson_times.monday}}&ndash;&gt;-->
            <!--                        <span class="font-weight-bold"> 월요일 </span>:-->
            <!--                        <li v-for="value in item.detailed_lesson_times.monday" :key="value">-->
            <!--                            {{value}}-->
            <!--                        </li>-->
            <!--                        <v-divider class="ma-2"></v-divider>-->

            <!--                    </div>-->
            <!--                    <div v-show="item.detailed_lesson_times.tuesday.length > 0">-->
            <!--                        <span class="font-weight-bold"> 화요일 </span>:-->
            <!--                        <li v-for="value in item.detailed_lesson_times.tuesday"-->
            <!--                            :key="value">-->
            <!--                            {{value}}-->
            <!--                        </li>-->
            <!--                        <v-divider class="ma-2"></v-divider>-->

            <!--                    </div>-->

            <!--                    <div v-show="item.detailed_lesson_times.wednesday.length > 0">-->
            <!--                        <span class="font-weight-bold"> 수요일 </span>:-->
            <!--                        <li v-for="value in item.detailed_lesson_times.wednesday"-->
            <!--                            :key="value">-->
            <!--                            {{value}}-->
            <!--                        </li>-->
            <!--                        <v-divider class="ma-2"></v-divider>-->

            <!--                    </div>-->

            <!--                    <div v-show="item.detailed_lesson_times.thursday.length > 0">-->
            <!--                        <span class="font-weight-bold"> 목요일 </span>:-->
            <!--                        <li v-for="value in item.detailed_lesson_times.thursday"-->
            <!--                            :key="value">-->
            <!--                            {{value}}-->
            <!--                        </li>-->
            <!--                        <v-divider class="ma-2"></v-divider>-->

            <!--                    </div>-->

            <!--                    <div v-show="item.detailed_lesson_times.friday.length > 0">-->
            <!--                        <span class="font-weight-bold"> 금요일 </span>:-->
            <!--                        <li v-for="value in item.detailed_lesson_times.friday" :key="value">-->
            <!--                            {{value}}-->
            <!--                        </li>-->
            <!--                        <v-divider class="ma-2"></v-divider>-->

            <!--                    </div>-->

            <!--                    <div v-show="item.detailed_lesson_times.saturday.length > 0">-->
            <!--                        <span class="font-weight-bold"> 토요일 </span>:-->
            <!--                        <li v-for="value in item.detailed_lesson_times.saturday"-->
            <!--                            :key="value">-->
            <!--                            {{value}}-->
            <!--                        </li>-->
            <!--                        <v-divider class="ma-2"></v-divider>-->

            <!--                    </div>-->

            <!--                    <div v-show="item.detailed_lesson_times.sunday.length > 0">-->
            <!--                        <span class="font-weight-bold"> 일요일 </span>:-->
            <!--                        <li v-for="value in item.detailed_lesson_times.sunday" :key="value">-->
            <!--                            {{value}}-->
            <!--                        </li>-->
            <!--                        <v-divider class="ma-2"></v-divider>-->

            <!--                    </div>-->

            <!--                </div>-->
            <!--                <v-card-title class="font-weight-bold text-decoration-underline">-->
            <!--                    과외 기간-->
            <!--                </v-card-title>-->
            <!--                <div class="ml-6">-->
            <!--                                                            <span>-->
            <!--                                                                총-->
            <!--                                                            <span class="font-weight-bold">-->
            <!--                                                                 {{item.expected_lesson_duration}}개월-->
            <!--                                                            </span>-->
            <!--                                                                동안-->
            <!--                                                            <br/>-->
            <!--                                                            </span>-->
            <!--                    <span>-->

            <!--                                                                일주일에-->
            <!--                                                            <span class="font-weight-bold">  {{item.expected_number_of_lessons_per_week}} 번씩 </span> 진행을 원해요!-->
            <!--                                                            <br/>-->
            <!--                                                            </span>-->
            <!--                </div>-->


            <!--            </div>-->


        </v-card>

        <v-card height="100px" elevation="0">
            <!--DELETE WHEN FOOTER COMES IN-->
        </v-card>
        <!-- 선생님으로 가입했지만 아직 우리에게 등록하지 않았을시   -->
    </div>
    <!--    </div>-->
</template>

<script>
    import TeacherService from "../../../services/teacher.service"
    import StudentService from "../../../services/student.service"
    import LessonService from "../../../services/lesson.service"
    // import AllLessons from "./Lessons/AllLessons";

    export default {
        name: "TeacherProfile",
        components: {},
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
            allLessons: function () {
                return this.lessons.filter(function (lesson) {
                    return lesson.lesson_status == 0 || lesson.lesson_status == 1 || lesson.lesson_status == 5 || lesson.lesson_status == 6;
                })
            }
        },
        mounted() {

            if (!this.currentUser) {
                this.$router.push('/login');
            }
            TeacherService.getStudentsWhoHaveLessonsWithTeacherId(this.currentUser.id).then(
                (response) => {

                    this.lessons = response.data;
                    // this.teacherContent.detailedLessonTimes = JSON.parse(this.teacherContent.detailedLessonTimes)

                    this.parseLessonTimes()
                    // for (let i = 0; i < this.lessons.length; i++) {
                    //
                    // }
                    // console.log("lessons:")
                    // console.log(this.lessons)
                    // console.log("lessons:")
                    // this.parseLessonTimeJson(this.lessons)


                },
                (error) => {
                    this.lessons =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    console.log(this.lessons)
                }
            );
            TeacherService.getTeacherInfo(this.currentUser.id).then(
                (response) => {
                    this.teacherContent = response.data;
                    this.setLessonStatuses(this.teacherContent)
                },
                (error) => {
                    this.teacherContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

        },

        data: () => ({
            matchingOn: false,
            showLesson: false,
            lesson_asked: false,
            testValue: 1,
            lesson_message_sent_by_student: false,
            lesson_no_lessons: false,
            lesson_accepted: false,
            teacherLessons: {
                lesson_asked: [],
                lesson_message_sent_by_student: [],
                lesson_no_lessons: [],
                lesson_accepted: [],
            },
            lessonRequestBody: [],
            lessonResponseBody: [],
            lessonStudents: [],
            lessons: [],
            teacherContent: "",
            studentLessonContent: "",
            teacherLessonContent: "",
            studentContent: "",
            value: '',

        }),
        methods: {

            getStudentInfoFromLessons(studentId) {
                for (let i = 0; i < this.lessons.length; i++) {
                    // console.log("what")
                    // console.log(this.lessons[i])
                    // console.log("what")
                    if (this.lessons[i].student_id == studentId) {
                        return this.lessons[i];
                    }
                }
            },

            submitTeacherInfo(userId, requestBody) {


                requestBody.registerStatus = 'PENDING'
                TeacherService.updateTeacherAboutTeacher(userId, requestBody).then(
                    (response) => {
                        this.teacherContent = response.data;
                        this.$router.push("/profile")
                        this.next = true
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },

            parseLessonTimes() {
                for (let i = 0; i < this.allLessons.length; i++) {
                    this.allLessons[i].detailed_lesson_times = this.allLessons[i].detailed_lesson_times.replace(/\\/g, '');
                    let temp = this.allLessons[i].detailed_lesson_times
                    if (temp.charAt(0) == '"') {
                        temp = temp.substr(1);
                    }
                    if (temp.charAt(temp.length -1) == '"') {
                        temp = temp.substr(0, temp.length-1);
                    }
                    if (typeof temp == 'string' || temp instanceof String) {
                        temp = JSON.parse(temp);
                        this.allLessons[i].detailed_lesson_times = temp;
                    }
                }
            },

            parseLessonTimeJson(lessons) {

                for (let i = 0; i < lessons.length; i++) {
                    // console.log("here")
                    // console.log(lessons[i].detailed_lesson_times)
                    // console.log("here")

                }
            },

            setLessonStatuses(responseBody) {


                for (let i = 0; i < responseBody.lessons.length; i++) {
                    console.log(responseBody.lessons[i].teacherMatchingStatus)
                    if (responseBody.lessons[i].teacherMatchingStatus == 'LESSON_ASKED') {
                        this.teacherLessons.lesson_asked.push(responseBody.lessons[i])
                        this.lesson_asked = true
                    } else if (responseBody.lessons[i].teacherMatchingStatus == 'MESSAGE_SENT_BY_STUDENT') {
                        this.lesson_message_sent_by_student = true;
                        this.teacherLessons.lesson_message_sent_by_student.push(responseBody.lessons[i])
                    } else if (responseBody.lessons[i].teacherMatchingStatus == 'NO_LESSONS') {
                        this.teacherLessons.lesson_no_lessons.push(responseBody.lessons[i]);
                        this.lesson_no_lessons = true;
                    } else if (responseBody.lessons[i].teacherMatchingStatus == 'LESSON_ACCEPTED') {
                        this.teacherLessons.lesson_accepted.push(responseBody.lessons[i]);
                        this.lesson_accepted = true;
                    }

                }
                console.log(this.lesson_message_sent_by_student)
            },

            getStudentInfo(studentId) {
                StudentService.getStudentInfo(studentId).then(
                    (response) => {
                        return response.data;
                    },
                    (error) => {
                        this.studentContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },

            getStudentInfoByLessonId(lessonId) {
                this.showLesson = true
                StudentService.getUserInfoByLessonId(lessonId).then(
                    (response) => {
                        this.lessonStudents = response.data;
                        // return response;
                    },
                    (error) => {
                        this.studentContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },

            proceedLesson(lessonId) {
                this.lessonRequestBody = {
                    status: 'TEACHER_ACCEPTED',
                    studentMatchingStatus: 'FOUND',
                    teacherMatchingStatus: 'TEACHER_PROCEEDING',
                }
                LessonService.updateLesson(lessonId, this.lessonRequestBody).then(
                    (response) => {

                        this.lessonResponseBody = response.data;
                        window.location.reload();
                        return response.data;
                    },
                    (error) => {
                        this.lessonResponseBody =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },

            confirmLesson(lessonId) {
                this.lessonRequestBody = {
                    status: 'SCHEDULED',
                    studentMatchingStatus: 'MATCHED',
                    teacherMatchingStatus: 'LESSON_ACCEPTED',
                },

                    LessonService.updateLesson(lessonId, this.lessonRequestBody).then(
                        (response) => {
                            this.lessonResponseBody = response.data;
                            window.location.reload();

                            return response.data;
                        },
                        (error) => {
                            this.lessonResponseBody =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                        }
                    );
            },


            rejectLesson(lessonId, requestBody) {
                LessonService.updateLesson(lessonId, requestBody).then(
                    (response) => {
                        this.lessonResponseBody = response.data;
                        return response.data;
                    },
                    (error) => {
                        this.lessonResponseBody =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },


            getLessonInfo(lessonId) {
                LessonService.getLessonInfo(lessonId).then(
                    (response) => {
                        return response.data;
                    },
                    (error) => {
                        this.lessonResponseBody =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },


        }
    }
</script>


<style scoped>

    .all-lessons-background {
        background-color: #F0F0F0;
        max-width: 800px;
        /*width: 100%;*/
    }

</style>
