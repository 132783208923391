<template>
    <div class="main-container">
        <!--        {{studentContent}}-->
        <v-card
                class="mx-auto  pa-5 primary--text"
                max-width="800"
                elevation="0"
                outlined
        >
            <span :class="{
            'text-h6': $vuetify.breakpoint.mdAndDown,
            'text-h5': $vuetify.breakpoint.lgAndUp
            }">
                <strong>IVYED</strong>에 오신 것을 환영합니다!
            </span>
        </v-card>

        <!-- 학생으로 가입했지만 아직 우리에게 등록하지 않았을시   -->
        <div>

            <!--  SHOWING VARIOUS MESSAGES SECTION -->
            <v-card
                    class="mx-auto mt-5 primary--text"
                    max-width="800"
                    elevation="0"

            >
                <!-- 결제 전 -->

                <!-- 결제 후 -->
                <div class="" v-show="studentContent.studentRegisterStatus == 'REGISTERED'">

                    <!-- 과외 신청서 작성 전 -->
                    <v-card color="" height="250" v-if="studentContent.matchingStatus=='NOT_STARTED'"
                            class="mt-5 primary--text status-card" flat elevation="0">
                        <span

                                class="mt-15 black--text"
                                :class="{
                                        'text-subtitle-2': $vuetify.breakpoint.mdAndDown,
                                        '': $vuetify.breakpoint.lgAndUp}">
                            <span class="ml-1">아직 과외 신청서가 등록되지 않았습니다!</span>
                            <br/>
                            <span class="ml-1 hidden-sm-and-down"> 과외 신청서를 등록해주셔야 선생님 매칭이 진행됩니다!</span>
                            <span class="ml-1 hidden-md-and-up"> 신청서를 등록해주셔야 <br/></span>
                            <span class="ml-1 hidden-md-and-up">선생님 매칭이 진행됩니다! </span>

                        </span>
                        <div class="container mt-15">
                            <ul class="progressbar">
                                <li class="active">수강 신청</li>
                                <li>과외 신청서</li>
                                <li>매칭</li>
                                <li>수업</li>
                            </ul>
                        </div>
                    </v-card>
                </div>

            </v-card>

            <v-card
                    class="mx-auto mt-5 primary--text"
                    max-width="800"
                    elevation="0"
            >

                <div v-show="studentContent.studentRegisterStatus == 'NOT_STARTED'" class="pt-4">
                    <span class="ml-5 mt-15 hidden-sm-and-down black--text text-h6"> <strong>수강 신청</strong>하시면 <strong>{{currentUser.firstName}}</strong>님에게 딱 맞는 선생님을 찾아드립니다.</span>
                    <span class="mt-15 hidden-md-and-up black--text ml-5"> <strong>수강 신청</strong>하시면 <br/></span>
                    <span class="hidden-md-and-up black--text ml-5"><strong>{{currentUser.firstName}}</strong>님에게 딱 맞는 선생님을 찾아드립니다.</span>
                    <!--                    <v-divider class="mb-3 mt-3"></v-divider>-->
                    <div class="mt-15 mb-15"
                         :class="{
                                        'text-subtitle-2': $vuetify.breakpoint.mdAndDown,
                                        '': $vuetify.breakpoint.lgAndUp}">
                        <div class="container py-10">
                            <ul class="progressbar">
                                <li class="active">수강 신청</li>
                                <li>과외 신청서</li>
                                <li>매칭</li>
                                <li>수업</li>
                            </ul>
                        </div>


                    </div>
                </div>
                <div v-show="studentContent.studentRegisterStatus == 'NOT_STARTED'" class="pt-4">
                    <div align="center" v-if="!isEnrollmentDataEmpty()" class="hidden-sm-and-down">
                        <v-btn
                                color="primary"
                                @click="proceedToPayment(currentUser.id)"
                        > 지금 바로 등록하러 가기!
                        </v-btn>
                    </div>

                    <!--  browser      -->
                    <div class="text-center hidden-sm-and-down pb-15" v-if="isEnrollmentDataEmpty()">
                        <v-btn
                                class="ma-3 "
                                color="secondary"
                                to="/enroll"
                                x-large>
                            수강 신청하기
                        </v-btn>
                    </div>


                    <!--  mobile      -->
                    <div class=" hidden-md-and-up pb-15" v-if="isEnrollmentDataEmpty()" align="center">
                        <v-btn
                                class="ma-3"
                                color="secondary"
                                to="/enroll"
                                width="40%"

                        > 수강 신청
                        </v-btn>
                    </div>

                </div>
                <!-- 결제  -->
                <div class="mb-4" v-show="studentContent.studentRegisterStatus == 'REGISTERED'">

                    <!-- MATCHING NOT REQUESTED / before filling out matching form-->
                    <div v-if="studentContent.matchingStatus=='NOT_STARTED'">
                        <div class="card mb-5">
                            <div class="card__header">
                            </div>
                            <div class="card__body">
                                <span class="tag tag-blue">현재 이용중인 수강권</span>
                                <h2>{{this.studentContent.subjectsOfInterest[0]}}
                                </h2>
                                <span class="text-h6">{{this.studentContent.subSubjects[0]}}</span>
                                <span class="text-h6">주 {{this.studentContent.expectedNumberOfLessonsPerWeek}}회 60분</span>
                                <v-card-text class="pa-2 mt-5">
                                    <v-icon class="mx-1" color="secondary">mdi-school</v-icon>
                                    <span class="text-subtitle-1 black--text"> 총 {{this.studentContent.expectedLessonDuration * this.studentContent.expectedNumberOfLessonsPerWeek * 4}}회의 1:1 과외 수업</span>
                                </v-card-text>
                                <v-card-text class="pa-2">
                                    <v-icon class="mx-1" color="secondary">mdi-credit-card</v-icon>
                                    <span class="text-subtitle-1 black--text"> {{this.studentContent.expectedLessonDuration}}개월 분
                                        <span class="text-caption">
                                            (총 {{this.studentContent.expectedNumberOfLessonsPerWeek * this.studentContent.expectedLessonDuration * 4}}회)
                                        </span>
                                        <br/>
                                        <span class="ml-9 font-weight-bold">수업 종료 후 1개월씩 자동 결제</span></span>
                                </v-card-text>
                            </div>
                            <div class="card__footer text-center">
                                <v-btn v-if="studentContent.matchingStatus == 'NOT_STARTED'"
                                       to="/student/matching/info"
                                       class=" mx-auto my-4" x-large width="250" color="secondary">
                                    <v-icon>mdi-pencil</v-icon>
                                    과외 신청서 등록하기
                                </v-btn>
                            </div>
                        </div>

                        <div class="payment-card">
                            <div class="card__header">
                            </div>
                            <div class="card__body">
                                <span class="tag tag-red">현재 등록된 카드</span>
                                <h2>XX카드 ({{String(this.studentContent.payments[0].cardNumber).slice(-4)}})</h2>
                            </div>
                            <div class="card__footer text-center">

                                <v-btn v-if="studentContent.matchingStatus == 'NOT_STARTED'"
                                       class="mx-auto my-4 primary--text" x-large width="250"
                                       elevation="0"
                                       outlined

                                >
                                    카드 변경하기
                                </v-btn>

                            </div>
                        </div>

                        <div class="my-15">
                            <span> 과외를 변경하거나 중단하고 싶은 경우엔, <strong>카카오톡 IVYED 매니저</strong>에게 연락주세요.</span>
                        </div>
                        <v-divider></v-divider>
                    </div>

                    <v-container v-if="studentContent.matchingStatus=='MATCHING_INFO_FILLED'">
                        <span
                                class="black--text"
                                :class="{
                                'text-h7': $vuetify.breakpoint.lgAndUp,
                                'text-h7': $vuetify.breakpoint.mdAndDown,

                                }">
                            <p>과외 신청서 작성 완료!</p>
                            <p>
                                <br/>
                                <strong>[바로 선생님 매칭하기]</strong>를 누르시면 <br/>
                                {{this.currentUser.firstName}}님과 잘 맞는 최적의 IVYED 선생님을 찾아드립니다!
                            </p>
                        </span>

                        <div class="container mt-7 pb-15">
                            <ul class="progressbar">
                                <li>수강 신청</li>
                                <li class="active">과외 신청서</li>
                                <li>매칭</li>
                                <li>수업</li>
                            </ul>
                        </div>
                        <div
                                class="mx-auto mt-15  mb-5 primary--text text-center"
                                max-width="750"
                                outlined
                        >
                            <v-btn color="secondary" class="ma-2" large
                                   v-if="studentContent.matchingStatus == 'MATCHING_INFO_FILLED'"
                                   @click="updateMatchingStatus(currentUser.id, studentContent)">
                                바로 선생님 매칭하기 &nbsp;<v-icon>mdi-human-male-board</v-icon>
                            </v-btn>
                            <v-btn color="secondary" outlined class="ma-2" large
                                   v-if="studentContent.matchingStatus == 'MATCHING_INFO_FILLED'"
                                   to="/student/matching/info">
                                과외 신청서 수정하기 &nbsp;<v-icon>mdi-pencil-outline</v-icon>
                            </v-btn>
                        </div>
                        <v-divider></v-divider>
                    </v-container>


                    <!-- MATCHING REQUESTED  -->
                    <v-container v-if="studentContent.matchingStatus=='REQUESTED'">
                        <h2 class="mb-5 mt-5">
                        </h2>
                        <span class="black--text"
                              :class="{

                                }">
                            {{currentUser.firstName}}님과 잘 맞는 IVYED 선생님을 찾는 중입니다.
                            <br/>
                            배정되는 즉시 연락드리겠습니다. 😊

                                </span>

                        <div
                                class="mx-auto mt-15 mb-5 primary--text"
                                max-width="750"
                                outlined
                        >
                            <div class="container my-15 pb-15">
                                <ul class="progressbar">
                                    <li>수강 신청</li>
                                    <li>과외 신청서</li>
                                    <li class="active">매칭</li>
                                    <li>수업</li>
                                </ul>
                            </div>
                        </div>
<!--                        <div class="card mb-5">-->
<!--                            <div class="card__header">-->
<!--                                &lt;!&ndash;                                        <img src="https://source.unsplash.com/600x400/?computer" alt="card__image" class="card__image" width="600">&ndash;&gt;-->
<!--                            </div>-->
<!--                            <div class="card__body">-->
<!--                                <span class="tag tag-blue">현재 이용중인 수강권</span>-->
<!--                                &lt;!&ndash;                                <h2>{{this.studentContent.subjectsOfInterest[0]}}<span class="text-h6">&nbsp; 주 {{this.studentContent.expectedNumberOfLessonsPerWeek}}회 60분</span>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                </h2>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                <span>{{this.studentContent.subSubjects[0]}}</span>&ndash;&gt;-->
<!--                                <h2>{{this.studentContent.subjectsOfInterest[0]}}-->
<!--                                </h2>-->
<!--                                <span class="text-h6">{{this.studentContent.subSubjects[0]}}</span>-->
<!--                                <span class="text-h6">주 {{this.studentContent.expectedNumberOfLessonsPerWeek}}회 60분</span>-->
<!--                                &lt;!&ndash;                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi perferendis molestiae&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    non nemo doloribus. Doloremque, nihil! At ea atque quidem!</p>&ndash;&gt;-->
<!--                                <v-card-text class="pa-2 mt-5">-->
<!--                                    <v-icon class="mx-1" color="secondary">mdi-school</v-icon>-->
<!--                                    <span class="text-subtitle-1 black&#45;&#45;text"> 총 {{this.studentContent.expectedLessonDuration * this.studentContent.expectedNumberOfLessonsPerWeek * 4}}회의 1:1 과외 수업</span>-->
<!--                                </v-card-text>-->
<!--                                <v-card-text class="pa-2">-->
<!--                                    <v-icon class="mx-1" color="secondary">mdi-credit-card</v-icon>-->
<!--                                    <span class="text-subtitle-1 black&#45;&#45;text"> {{this.studentContent.expectedLessonDuration}}개월 분-->
<!--                                                        <span class="text-caption">-->
<!--                                                        (총 {{this.studentContent.expectedNumberOfLessonsPerWeek * this.studentContent.expectedLessonDuration * 4}}회)-->
<!--                                                        </span>-->
<!--                        <br/><span class="ml-9 font-weight-bold">수업 종료 후 1개월씩 자동 결제</span></span>-->
<!--                                </v-card-text>-->
<!--                            </div>-->
<!--                            <div class="card__footer">-->
<!--                                &lt;!&ndash;                                        <div class="user">&ndash;&gt;-->
<!--                                &lt;!&ndash;                                            <img src="https://i.pravatar.cc/40?img=1" alt="user__image" class="user__image">&ndash;&gt;-->
<!--                                &lt;!&ndash;                                            <div class="user__info">&ndash;&gt;-->
<!--                                &lt;!&ndash;                                                <h5>Jane Doe</h5>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                                <small>2h ago</small>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                                </div>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        </div>&ndash;&gt;-->
<!--                                <v-btn v-if="studentContent.matchingStatus == 'NOT_STARTED'"-->
<!--                                       to="/student/matching/info"-->
<!--                                       class="mx-auto ma-4 ml-7" x-large width="250" color="secondary">-->
<!--                                    <v-icon>mdi-pencil</v-icon>-->
<!--                                    과외 신청서 등록하기-->
<!--                                </v-btn>-->
<!--                                <v-btn v-if="studentContent.matchingStatus == 'NOT_STARTED'"-->
<!--                                       to="/student/matching/info"-->
<!--                                       class="mx-auto ma-4 ml-7 primary&#45;&#45;text" x-large width="250"-->
<!--                                       elevation="0"-->
<!--                                       outlined-->

<!--                                >-->
<!--                                    &lt;!&ndash;                                    <v-icon>mdi-pencil</v-icon>&ndash;&gt;-->
<!--                                    카드 변경하기-->
<!--                                </v-btn>-->

<!--                            </div>-->


<!--                        </div>-->
<!--                        &lt;!&ndash;                        <v-divider></v-divider>&ndash;&gt;-->
<!--                        <div class="payment-card">-->
<!--                            <div class="card__header">-->
<!--                                &lt;!&ndash;                                        <img src="https://source.unsplash.com/600x400/?computer" alt="card__image" class="card__image" width="600">&ndash;&gt;-->
<!--                            </div>-->
<!--                            <div class="card__body">-->
<!--                                <span class="tag tag-red">현재 등록된 카드</span>-->
<!--                                <h2>XX카드 ({{String(this.studentContent.payments[0].cardNumber).slice(-4)}})</h2>-->
<!--                                &lt;!&ndash;                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi perferendis molestiae&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    non nemo doloribus. Doloremque, nihil! At ea atque quidem!</p>&ndash;&gt;-->

<!--                            </div>-->
<!--                            <div class="card__footer text-center">-->

<!--                                <v-btn-->
<!--                                        class="mx-auto my-4 primary&#45;&#45;text" x-large width="250"-->
<!--                                        elevation="0"-->
<!--                                        outlined-->

<!--                                >-->
<!--                                    &lt;!&ndash;                                    <v-icon>mdi-pencil</v-icon>&ndash;&gt;-->
<!--                                    카드 변경하기-->
<!--                                </v-btn>-->

<!--                            </div>-->
<!--                        </div>-->
                    </v-container>

                    <!-- MATCHING FOUND  -->
                    <!--                            {{studentContent.matchingStatus}}-->
                    <v-card v-if="studentContent.matchingStatus=='FOUND'" class="black--text" elevation="0">
                        <div class="ma-3">
                            <p class="text-subtitle-1">학생분과 잘 맞을 것 같은 선생님을 찾았습니다!</p>
                            <p class="mb-5 mt-5 text-subtitle-1">
                                <strong>[수업 진행]</strong>을 눌러 선생님과 상세 수업 일정 조율을 시작하세요!
                            </p>
                            <v-card height="100px" elevation="0">
                                <div class="container my-15">
                                    <ul class="progressbar">
                                        <li>수강 신청</li>
                                        <li>과외 신청서</li>
                                        <li class="active">매칭</li>
                                        <li>수업</li>
                                    </ul>
                                </div>
                            </v-card>
                        </div>
                        <!--                        {{lessonsExceptCancelled.length}}-->
                        <div class="semi-background pa-2  mt-15 mb-10">
                        <v-card outlined class="black--text " v-for="item in lessonsExceptCancelled"
                                :key="item.id">


                            <!--                            <div class="text-subtitle-2 ma-2"> 요청 시간: {{new Date(item.updated_at)}}</div>-->
                            <!--                            <v-divider></v-divider>-->
                            <div v-if="item.lesson_status != 9" class="">
                                <v-card-title class="font-weight-bold mt-2">
                                    선생님 정보
                                </v-card-title>
                                <div class="mx-5 mt-2">

                                    <span class="text-subtitle-1 font-weight-bold text-decoration-underline">대학교<br/></span>
                                    <strong>{{item.teacher_undergrad}} </strong> <strong>{{item.teacher_undergrad_major}} </strong>전공으로


                                    <strong>{{item.highest_level_of_education}} </strong>
                                    <span v-if="item.highest_level_of_education.includes('졸업')">
<!--                                    하셨어요.-->
                                </span>
                                    <span v-if="item.highest_level_of_education.includes('중')">
<!--                                    이세요.-->
                                </span>
                                    <span v-if="item.teacher_grad_school != null">

                                </span>


                                </div>
                                <br/>
                                <div class="mx-5">
                                    <span class="text-subtitle-1 font-weight-bold text-decoration-underline">고등학교<br/></span>
                                    <strong>{{item.teacher_high_school}} ({{item.teacher_high_school_type}})
                                        졸업 </strong>
                                </div>
                                <br/>
                                <div class="mx-5 mb-2" v-if="item.teacher_personal_intro != null">
                                    <span class="text-subtitle-1 font-weight-bold text-decoration-underline">선생님 소개<br/></span>
                                    "{{item.teacher_personal_intro}}"
                                </div>

                                <v-divider></v-divider>
                                <v-container>
                                    <v-card elevation="0" class="black--text">
                                        <v-card-title class="font-weight-bold  mt-2">
                                            특별 요청 사항
                                        </v-card-title>
                                        <v-form v-model="valid">
                                            <v-container fluid>
                                                <v-textarea
                                                        name="input-7-1"
                                                        filled
                                                        class="black--text text-subtitle-2"
                                                        placeholder="예) AP Computer Science 문제 풀이와 학교 프로젝트 번갈아 가면서 진행 부탁드립니다."
                                                        auto-grow
                                                        v-model="appointment.message"
                                                        :rules="[v => !!v || '필수입력']"
                                                ></v-textarea>
                                            </v-container>
                                            <div
                                                    class="mx-auto mt-5 ml-5 mb-5 text-center primary--text"
                                                    max-width="750"
                                                    outlined
                                            >
                                                <!--                                        {{item}}-->
                                                <!--                                                {{item}}-->
                                                <v-btn color="secondary" class="mr-5"
                                                       :disabled="!valid"
                                                       @click="makeLessonAppointment(item.lesson_id, appointment, item.student_id, item.teacher_id)"
                                                >
                                                    선생님께 보내기
                                                </v-btn>
                                            </div>
                                        </v-form>
                                    </v-card>
                                </v-container>
                            </div>
                        </v-card>
                        </div>

                    </v-card>

                    <!-- 2022/05/13 선생님 기다리는동안 보낼 메시지 필요                    -->
                    <!--                    <v-container v-if="studentContent.matchingStatus=='WAITING_FOR_TEACHER_CONFIRMATION'" class="both-accepted-container">-->
                    <div v-if="studentContent.lessons.length > 0">
                        <v-container v-if="studentContent.lessons[0].status == 'BOTH_ACCEPTED'"
                                     class="both-accepted-container black--text">
                            <p>선생님과 비대면으로 수업할 과외방 생성 중입니다.<br/>
                                1영업일 내로 선생님과 함께 연락드리겠습니다!🙂</p>
                            <!--                        <h4>선생님께서 최종 수락하시면 과외를 시작합니다! </h4>-->
                            <v-divider class="mb-3 mt-3"></v-divider>
                            <v-card height="100px" elevation="0" class="mb-7">
                                <div class="container my-15">
                                    <ul class="progressbar">
                                        <li>수강 신청</li>
                                        <li>과외 신청서</li>
                                        <li class="active">매칭</li>
                                        <li>수업</li>
                                    </ul>
                                </div>
                            </v-card>

                        </v-container>
                        <v-container class="black--text" v-if="studentContent.lessons[0].status == 'SCHEDULED'">
                            <!--                    {{studentContent.lessons[0].status == 'SCHEDULED'}}-->
                            <!--                    <v-container v-if="studentContent.matchingStatus=='MATCHED'">-->
                            <p>하단 <strong>[과외방 입장]</strong>을 통해 수업을 시작하시거나 <br/>

                                <strong>[수업 일지]</strong> 를 통해 이전 수업 피드백 및 영상을 보면서 복습하실 수 있습니다.</p>
                            <!--                        <h4>아래 수업 정보를 꼭 확인 해주세요!</h4>-->
                            <v-divider class="mb-3 mt-3"></v-divider>
                            <!--                        <v-card height="100px" elevation="0">-->
                            <!--                            <div class="container my-15">-->
                            <!--                                <ul class="progressbar">-->
                            <!--                                    <li>수강 신청</li>-->
                            <!--                                    <li>과외 신청서</li>-->
                            <!--                                    <li >매칭 중</li>-->
                            <!--                                    <li class="active">매칭 완료</li>-->
                            <!--                                </ul>-->
                            <!--                            </div>-->
                            <!--                        </v-card>-->
                            <v-card class="mt-15 black--text" elevation="0" outlined>
                                <!--                            <p class="text-h6 ml-4 mt-3 mb-3 topbar&#45;&#45;text font-weight-bold">예정된 수업 <v-icon color="topbar">mdi-human-male-board</v-icon></p>-->
                                <!--                            <v-divider></v-divider>-->
                                <v-card-title class="font-weight-bold  mt-5">
                                    수업 정보
                                </v-card-title>
                                <div class="mx-5">
                                <span class="font-weight-bold">
                        과목:
                        </span> {{studentContent.subjectsOfInterest[0]}} - {{studentContent.subSubjects[0]}}
                                    <br/>
                                    <span class="font-weight-bold">
                        수업 횟수:
                        </span> 주 {{studentContent.expectedNumberOfLessonsPerWeek}}회
                                    <br/>
                                    <!--                                    <div class=" text-center mb-4 mt-2">-->
                                    <!--                                        <row>-->
                                    <!--                                            <v-btn class="my-3 mr-7" elevation="1" :href="''"-->
                                    <!--                                                   target="_blank" color="secondary">과외방 입장-->
                                    <!--                                            </v-btn>-->
                                    <!--                                            <v-btn class="my-3" elevation="1" outlined :href="''"-->
                                    <!--                                                   target="_blank" color="secondary">수업일지 보기-->
                                    <!--                                            </v-btn>-->
                                    <!--                                        </row>-->
                                    <!--                                    </div>-->

                                </div>
                                <!--                            <div class="ml-6">-->
                                <!--                                <span class="text-subtitle-1 font-weight-bold text-decoration-underline">선생님 <br/></span>-->
                                <!--                                <span><strong>{{studentContent.subjectsOfInterest[0]}} </strong>과외 with <strong>{{getTeacherInfoById(scheduledLessons[0].tid).teacher_lastname}} {{getTeacherInfoById(scheduledLessons[0].tid).teacher_firstname}}</strong> 선생님</span>-->
                                <!--                                <br/>-->
                                <!--                                <br/>-->
                                <!--                                <span class="text-subtitle-1 font-weight-bold text-decoration-underline">과외 일정 <br/></span>-->
                                <!--                                <span><strong>{{scheduledLessons[0].schedule}} </strong></span>-->
                                <!--                                <br/>-->
                                <!--                                <br/>-->
                                <!--                                <span class="text-subtitle-1 font-weight-bold text-decoration-underline">수업용 화이트보드 <br/></span>-->
                                <!--                                <p><v-btn class="my-3" outlined :href="scheduledLessons[0].pageCallLink" target="_blank" color="primary">입장하기</v-btn></p>-->

                                <!--&lt;!&ndash;                                <span><strong>{{scheduledLessons[0].pageCallLink}} </strong></span>&ndash;&gt;-->


                                <!--                            </div>-->
                                <v-card-title class="font-weight-bold  mt-5">
                                    선생님 정보
                                </v-card-title>
                                <div class="mx-5">
                                <span class="font-weight-bold">
                        이름:
                        </span>{{getTeacherInfoById(scheduledLessons[0].tid).teacher_lastname}}
                                    {{getTeacherInfoById(scheduledLessons[0].tid).teacher_firstname}}
                                    <br/>
                                    <span class="font-weight-bold">
                        카톡 ID:
                        </span> {{getTeacherInfoById(scheduledLessons[0].tid).teacher_kakao_id}}
                                    <br/>
                                    <span class="font-weight-bold">
                        이메일:
                        </span> {{getTeacherInfoById(scheduledLessons[0].tid).teacher_email}}
                                    <br/>
                                    <span class="font-weight-bold">
                        전화번호:
                        </span>
                                    <span v-if="getTeacherInfoById(scheduledLessons[0].tid).teacher_country_code == '직접 입력'">{{getTeacherInfoById(scheduledLessons[0].tid).teacher_phone_number}}</span>
                                    <span v-if="getTeacherInfoById(scheduledLessons[0].tid).teacher_country_code != '직접 입력' && getTeacherInfoById(scheduledLessons[0].tid).teacher_country_code != null"> (+{{getTeacherInfoById(scheduledLessons[0].tid).teacher_country_code.replace(/\D/g,'')}}) {{getTeacherInfoById(scheduledLessons[0].tid).teacher_phone_number}}</span>

                                    <br/>
                                    <br/>
                                    <div>
                                        <div class="">
                                        <span class="text-subtitle-1 "><strong>대학교:<br/> </strong> <strong>{{getTeacherInfoById(scheduledLessons[0].tid).teacher_undergrad_major}} </strong>전공으로
                                        <strong>{{getTeacherInfoById(scheduledLessons[0].tid).teacher_undergrad}} </strong></span>
                                            <span v-if="getTeacherInfoById(scheduledLessons[0].tid).teacher_grad_school != null"
                                                  class="font-weight-bold"> 졸업 </span>
                                            <span v-if="getTeacherInfoById(scheduledLessons[0].tid).teacher_grad_school == null"><strong>{{getTeacherInfoById(scheduledLessons[0].tid).highest_level_of_education}} </strong></span>
                                        </div>
                                        <div class=""
                                             v-if="getTeacherInfoById(scheduledLessons[0].tid).teacher_grad_school != null">
                                        <span class="text-subtitle-1 "><strong>대학원:<br/> </strong> <strong>{{getTeacherInfoById(scheduledLessons[0].tid).teacher_grad_major}} </strong>전공으로
                                        <strong>{{getTeacherInfoById(scheduledLessons[0].tid).teacher_grad_school}} </strong></span>
                                            <strong>{{getTeacherInfoById(scheduledLessons[0].tid).highest_level_of_education}} </strong>
                                        </div>
                                    </div>
                                    <br/>
                                    <div>
                                        <span class="text-subtitle-1 font-weight-bold">고등학교: <br/></span>
                                        <strong>{{getTeacherInfoById(scheduledLessons[0].tid).teacher_high_school}} </strong>
                                        ({{getTeacherInfoById(scheduledLessons[0].tid).teacher_high_school_type}}) 졸업
                                    </div>
                                    <br/>
                                    <div v-if="getTeacherInfoById(scheduledLessons[0].tid).teacher_personal_intro != null">
                                        <span class="text-subtitle-1 font-weight-bold">선생님 소개: <br/></span>
                                        {{getTeacherInfoById(scheduledLessons[0].tid).teacher_personal_intro}}
                                    </div>

                                    <div class=" text-center mb-4 mt-5">
                                        <row>
                                            <v-btn class="my-3 mr-7" elevation="1" :href="scheduledLessons[0].pageCallLink"
                                                   target="_blank" color="secondary">과외방 입장
                                            </v-btn>
                                            <v-btn class="my-3" elevation="1" outlined :href="scheduledLessons[0].reportLink"
                                                   target="_blank" color="secondary">수업일지 보기
                                            </v-btn>
                                        </row>
                                    </div>

                                </div>


                            </v-card>


                        </v-container>
                    </div>
                    <div v-if="studentContent.studentRegisterStatus == 'REGISTERED' && studentContent.matchingStatus!='NOT_STARTED'">
                        <div class="card mb-5">
                            <div class="card__header">
                            </div>
                            <div class="card__body">
                                <span class="tag tag-blue">현재 이용중인 수강권</span>
                                <h2>{{this.studentContent.subjectsOfInterest[0]}}
                                </h2>
                                <span class="text-h6">{{this.studentContent.subSubjects[0]}}</span>
                                <span class="text-h6">주 {{this.studentContent.expectedNumberOfLessonsPerWeek}}회 60분</span>
                                <v-card-text class="pa-2 mt-5">
                                    <v-icon class="mx-1" color="secondary">mdi-school</v-icon>
                                    <span class="text-subtitle-1 black--text"> 총 {{this.studentContent.expectedLessonDuration * this.studentContent.expectedNumberOfLessonsPerWeek * 4}}회의 1:1 과외 수업</span>
                                </v-card-text>
                                <v-card-text class="pa-2">
                                    <v-icon class="mx-1" color="secondary">mdi-credit-card</v-icon>
                                    <span class="text-subtitle-1 black--text"> {{this.studentContent.expectedLessonDuration}}개월 분
                                        <span class="text-caption">
                                            (총 {{this.studentContent.expectedNumberOfLessonsPerWeek * this.studentContent.expectedLessonDuration * 4}}회)
                                        </span>
                                        <br/>
                                        <span class="ml-9 font-weight-bold">수업 종료 후 1개월씩 자동 결제</span></span>
                                </v-card-text>
                            </div>
                            <div class="card__footer text-center">
                                <v-btn v-if="studentContent.matchingStatus == 'NOT_STARTED'"
                                       to="/student/matching/info"
                                       class=" mx-auto my-4" x-large width="250" color="secondary">
                                    <v-icon>mdi-pencil</v-icon>
                                    과외 신청서 등록하기
                                </v-btn>
                            </div>
                        </div>

                        <div class="payment-card">
                            <div class="card__header">
                            </div>
                            <div class="card__body">
                                <span class="tag tag-red">현재 등록된 카드</span>
                                <h2>XX카드 ({{String(this.studentContent.payments[0].cardNumber).slice(-4)}})</h2>
                            </div>
                            <div class="card__footer text-center">

                                <v-btn v-if="studentContent.matchingStatus == 'NOT_STARTED'"
                                       class="mx-auto my-4 primary--text" x-large width="250"
                                       elevation="0"
                                       outlined

                                >
                                    카드 변경하기
                                </v-btn>

                            </div>
                        </div>

                        <div class="my-15">
                            <span> 과외를 변경하거나 중단하고 싶은 경우엔, <strong>카카오톡 IVYED 매니저</strong>에게 연락주세요.</span>
                        </div>
<!--                        <v-divider></v-divider>-->
                    </div>

                </div>
            </v-card>
        </div>


    </div>
</template>

<script>

    import StudentService from "../../services/student.service"
    import LessonService from "../../services/lesson.service"
    import EmailService from '../../services/email.service'
    // import TeacherService from '../../services/teacher.service'
    // import CardViewMore from "../helpers/Card-View-More";
    // import CardViewMore from "../helpers/Card-View-More";
    // import StudentInfo from "./StudentInfo";
    // import StudentMatchingNotStarted from "./StudentMatchingNotStarted";
    // import StudentMatchingRequested from "./StudentMatchingFound";
    // import StudentMatchingFound from "./StudentMatchingFound";

    export default {
        name: "StudentProfile",
        // components: {CardViewMore},
        // components: {CardViewMore},
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
            enrollmentData() {
                return this.$store.state.enrollmentData;
            },
            lessonsExceptCancelled: function () {
                return this.lessons.filter(function (lesson) {
                    return lesson.lesson_status != 9;
                })
            }
        },
        mounted() {
            if (!this.currentUser) {
                this.$router.push('/login');
            }

            // add student info fetcher
            StudentService.getStudentInfo(this.currentUser.id).then(
                (response) => {
                    this.studentContent = response.data;
                    for (let i = 0; i < this.studentContent.lessons.length; i++) {
                        if (this.studentContent.lessons[i].status == 'SCHEDULED') {
                            this.scheduledLessons.push(this.studentContent.lessons[i])
                        }
                    }
                },
                (error) => {
                    this.studentContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

            StudentService.getLessonInfoByStudentId(this.currentUser.id).then(
                (response) => {
                    console.log(response.data)
                    // this.lessons.subjects_of_expertise = this.removeEmptyStringFromList(this.lessons.subjects_of_expertise)
                    this.lessons = response.data;
                },
                (error) => {
                    this.lessons =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        methods: {
            removeEmptyStringFromList(input) {
                return input.filter(element => {
                    return element !== '';
                });
            },
            proceedToPayment(userId) {

                let requestBody = {
                    subjectsOfInterest: [],
                    subSubjects: [],
                    expectedNumberOfLessonsPerWeek: "",
                    expectedLessonDuration: "",
                    tablet: "",
                };
                requestBody.subjectsOfInterest.push(this.enrollmentData.lessonCategory);
                requestBody.subSubjects.push(this.enrollmentData.lessonSubCateogry);
                requestBody.expectedNumberOfLessonsPerWeek = this.removeStringFromString(this.enrollmentData.expectedNumberOfLessonsPerWeek);
                requestBody.expectedLessonDuration = this.removeStringFromString(this.enrollmentData.expectedLessonDuration);
                requestBody.tablet = this.enrollmentData.tablet;
                console.log(requestBody);
                StudentService.updateStudent(userId, requestBody).then(
                    (response) => {
                        console.log(response.data);
                    },
                    (error) => {
                        this.studentContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        console.log(this.studentContent)
                    }
                )
                this.$router.push("/enroll");
                return userId;
            },

            updateStudent(userId, requestBody) {
                StudentService.updateStudent(userId, requestBody).then(
                    (response) => {
                        console.log(response.data);
                        this.sendNotificationEmail({
                            "email": this.currentUser.email,
                            "emailBody": "[WIP] 선생님 매칭 신청이 완료 되었습니다. 곧 매칭 해줄게 기둘"
                        })
                    },
                    (error) => {
                        this.studentContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },

            sendNotificationEmail(requestBody) {
                EmailService.sendEmailNotification(requestBody).then(
                    (response) => {
                        console.log(response.data)
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },
            sendNotificationEmailByUserId(requestBody, userId) {
                EmailService.sendEmailNotificationByUserId(requestBody, userId).then(
                    (response) => {
                        console.log(response.data)
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },

            updateMatchingStatus(userId, requestBody) {
                requestBody.matchingStatus = 'REQUESTED';
                console.log("Here")
                this.updateStudent(userId, requestBody);
                this.$router.push("/profile")
            },

            stringToList(input) {
                return input.split(";").filter(element => {
                    return element !== '';
                });
            },
            removeStringFromString(input) {
                return input.replace(/\D/g, '');
            },

            isEnrollmentDataEmpty() {
                // if (Object.keys(this.enrollmentData).length > 0) {
                if (this.enrollmentData.length > 0) {
                    return false;
                }
                return true;
            },

            getSchedule() {
                this.appointment.schedule = this.days + " " + this.times;
                return this.days + " " + this.times;
            },

            makeLessonAppointment(lessonId, requestBody, studentId, teacherId) {
                requestBody.studentId = studentId.toString();
                requestBody.teacherId = teacherId.toString();
                requestBody.schedule = this.days + " " + this.times;
                LessonService.makeLessonAppointment(lessonId, requestBody).then(
                    (response) => {
                        console.log(response.data);
                        this.sendNotificationEmailByUserId({
                            "emailBody": "[TEST] 학생에게 과외 확정 요청이 왔어요. 들어가서 얼렁 보쇼",
                            "userId": teacherId,
                        }, teacherId);
                        // return response.body;


                        this.$router.go(0)
                        // this.$router.go(this.$router.currentRoute)

                    },
                    (error) => {
                        this.appointmentResponseBody =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },

            getTeacherInfoById(userId) {
                for (let i = 0; i < this.lessons.length; i++) {
                    if (this.lessons[i].teacher_id == userId) {
                        return this.lessons[i];
                    }
                }
            }
        },
        data: () => ({
            scheduledLessons: [],
            valid: false,
            studentContent: "",
            requestBody: "",
            appointmentResponseBody: "",
            lessons: [],
            continueLesson: false,
            scheduleTime: [],
            days: "",
            times: [],
            appointment: {
                schedule: "",
                message: "",
                studentId: "",
                teacherId: "",
            },
            hasEnrollmentData: false,
            bannerItems: [
                {
                    color: '#1F7087',
                    // src: 'https://cdn.vuetifyjs.com/images/cards/foster.jpg',
                    title: '비대면 수업은 어떻게 진행 되는지 알아보세요.',
                    artist: 'IVYeD 의 비대면 시스템을 알아보세요!',
                },
                {
                    color: '#952175',
                    // src: 'https://cdn.vuetifyjs.com/images/cards/halcyon.png',
                    title: '왜 IVYeD여야 하나요?',
                    artist: '동안..',
                },
            ],
        }),
    }
</script>

<style scoped>
    .button-82-pushable {
        position: relative;
        border: none;
        background: transparent;
        padding: 50px;
        cursor: pointer;
        outline-offset: 4px;
        transition: filter 250ms;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
    }


    .button-82-edge {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        background: linear-gradient(
                to left,
                hsl(340deg 100% 16%) 0%,
                hsl(340deg 100% 32%) 8%,
                hsl(340deg 100% 32%) 92%,
                hsl(340deg 100% 16%) 100%
        );
    }

    .button-82-front {
        display: block;
        position: relative;
        padding: 12px 27px;
        border-radius: 12px;
        font-size: 1.1rem;
        color: white;
        background: hsl(345deg 100% 47%);
        will-change: transform;
        transform: translateY(-4px);
        transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
    }

    @media (min-width: 768px) {
        .button-82-front {
            font-size: 1.25rem;
            padding: 12px 42px;
        }
    }

    .button-82-pushable:hover {
        filter: brightness(110%);
    }

    .button-82-pushable:hover .button-82-front {
        transform: translateY(-6px);
        transition: transform 250ms cubic-bezier(.3, .7, .4, 1.5);
    }

    .button-82-pushable:active .button-82-front {
        transform: translateY(-2px);
        transition: transform 34ms;
    }

    .button-82-pushable:hover .button-82-shadow {
        transform: translateY(4px);
        transition: transform 250ms cubic-bezier(.3, .7, .4, 1.5);
    }

    .button-82-pushable:active .button-82-shadow {
        transform: translateY(1px);
        transition: transform 34ms;
    }

    .button-82-pushable:focus:not(:focus-visible) {
        outline: none;
    }

    .button-17 {
        align-items: center;
        appearance: none;
        background-color: #fff;
        border-radius: 24px;
        border-style: none;
        box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px, rgba(0, 0, 0, .14) 0 6px 10px 0, rgba(0, 0, 0, .12) 0 1px 18px 0;
        box-sizing: border-box;
        color: #3c4043;
        cursor: pointer;
        display: inline-flex;
        fill: currentcolor;
        font-family: "Google Sans", Roboto, Arial, sans-serif;
        font-size: 14px;
        font-weight: 500;
        height: 48px;
        justify-content: center;
        letter-spacing: .25px;
        line-height: normal;
        max-width: 100%;
        overflow: visible;
        padding: 2px 24px;
        position: relative;
        text-align: center;
        text-transform: none;
        transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1), opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        width: auto;
        will-change: transform, opacity;
        z-index: 0;
    }

    .button-17:hover {
        background: #F6F9FE;
        color: #b71c1c;
    }

    .button-17:active {
        box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
        outline: none;
    }

    .button-17:focus {
        outline: none;
        border: 2px solid #c23e3e;
    }

    .button-17:not(:disabled) {
        box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
    }

    .button-17:not(:disabled):hover {
        box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
    }

    .button-17:not(:disabled):focus {
        box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
    }

    .button-17:not(:disabled):active {
        box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
    }

    .button-17:disabled {
        box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
    }

    .container {
        width: 100%;
        /*margin: 100px auto;*/
        justify-content: center;
    }

    .progressbar {
        counter-reset: step;
        padding-left: 0 !important;
    }

    .progressbar li {
        list-style-type: none;
        width: 25%;
        float: left;
        font-size: 12px;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        color: #7d7d7d;
    }

    .progressbar li:before {
        width: 30px;
        height: 30px;
        content: counter(step);
        counter-increment: step;
        line-height: 30px;
        border: 2px solid #7d7d7d;
        display: block;
        text-align: center;
        margin: 0 auto 10px auto;
        border-radius: 50%;
        background-color: white;
    }

    .progressbar li:after {
        width: 100%;
        height: 2px;
        content: '';
        position: absolute;
        background-color: #7d7d7d;
        top: 15px;
        left: -50%;
        z-index: -1;
    }

    .progressbar li:first-child:after {
        content: none;
    }

    .progressbar li.active {
        color: green;
    }

    .progressbar li.active:before {
        border-color: #55b776;
    }

    .progressbar li.active + li:after {
        background-color: #55b776;
    }

    img {
        max-width: 100%;
        display: block;
        object-fit: cover;
    }

    .card {
        display: flex;
        flex-direction: column;
        /*width: clamp(20rem, calc(20rem + 2vw), 22rem);*/
        width: 100%;
        overflow: hidden;
        box-shadow: 0 .1rem 1rem rgba(0, 0, 0, 0.1);
        border-radius: 1em;
        background: #ECE9E6;
        background: linear-gradient(to right, #FFFFFF, #ECE9E6);

    }

    .payment-card {
        display: flex;
        flex-direction: column;
        width: clamp(20rem, calc(20rem + 2vw), 22rem);
        width: 100%;
        overflow: hidden;
        box-shadow: 0 .1rem 1rem rgba(0, 0, 0, 0.1);
        border-radius: 1em;
        /*background: #ECE9E6;*/
        background: linear-gradient(to right, #FFFFFF, #FFFFFF);

    }


    .card__body {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }

    .tag {
        align-self: flex-start;
        padding: .25em .75em;
        border-radius: 1em;
        font-size: .75rem;
    }

    .tag + .tag {
        margin-left: .5em;
    }

    .tag-blue {
        background: #56CCF2;
        background: linear-gradient(to bottom, #2F80ED, #56CCF2);
        color: #fafafa;
    }

    .btn {
        padding: 15px;
        font-weight: 300;
        font-size: 0.8rem;
        text-decoration: none;
        text-align: center;
        transition: all .5s ease;
    }

    .btn--doar {
        color: #fff;
        padding-right: 0;
        background-color: #c0392b;
        -webkit-clip-path: polygon(0% 0%, 100% 0, 100% 70%, 90% 100%, 0% 100%);
        clip-path: polygon(0 0, 100% 0, 100% 50%, 75% 100%, 0 100%);

    }

    .btn--doar:hover {
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%, 0 100%);
    }

    .btn--doar:after {
        content: "\f004";
        color: #e74c3c;
        font-family: FontAwesome;
        display: inline-block;
        position: relative;
        right: -55px;
        transition: all 0.2s ease;
    }

    .btn--doar:hover:after {
        margin: -5px 15px;
        right: 0px;
    }

    .tag-red {
        background: #cb2d3e;
        background: linear-gradient(to bottom, #ef473a, #cb2d3e);
        color: #fafafa;
    }

    .main-container {
        /*height: 750px;*/
        /*background-color: #7a00ff;*/
        width: 100%;
        /*background: linear-gradient(30deg, #f43e4e 60%, #f65e6b 60%);;*/

    }

    .status-card {
        /*background: linear-gradient(60deg, #fddfe1 60%, #ffffff 60%);;*/
        /*background: linear-gradient(to bottom, #ef473a, #cb2d3e);*/

    }

    .span-center {
        display: flex;
        justify-content: center;
    }

    .both-accepted-container {
        /*height: 600px;*/
        color: black;
    }
    .semi-background {
        background-color: #F0F0F0;
    }
</style>

