import { render, staticRenderFns } from "./LessonTimes.vue?vue&type=template&id=80df671e&scoped=true&"
import script from "./LessonTimes.vue?vue&type=script&lang=js&"
export * from "./LessonTimes.vue?vue&type=script&lang=js&"
import style0 from "./LessonTimes.vue?vue&type=style&index=0&id=80df671e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80df671e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VBtn,VCard,VCardSubtitle,VContainer,VDivider,VForm,VIcon,VRow,VSelect,VSwitch})
