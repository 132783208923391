<template>
<v-container fluid class="faq-page">
    <p class="justify-center sub-main-01 main-color pb-5">자주 묻는 질문</p>

    <FaqTemplate></FaqTemplate>
</v-container>
</template>

<script>
    import FaqTemplate from "./FaqTemplate";
    export default {
        name: "FaqPage",
        components: {FaqTemplate}
    }
</script>

<style scoped>
    .faq-page {
        background: #F3E5E5;
    }
    .justify-center {
        text-align: center;
    }
    .sub-main-01 {
        font-weight: bold;
        /*color: #290F29;*/
        font-size: 36px;
    }
</style>
