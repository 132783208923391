<template>

    <v-container>
        <!--        <h1>{{currentUser}}</h1>-->
        <v-container v-if="currentUser.roles[0] == 'ROLE_TEACHER'">


            <v-card
                    class="mx-auto pa-5 primary--text"
                    max-width="800"
                    elevation="0"
            >
                <span
                        class="font-weight-bold"
                        :class="{
            'text-h6': $vuetify.breakpoint.mdAndDown,
            'text-h5': $vuetify.breakpoint.lgAndUp
            }">
                     수업 스타일 <v-icon color="primary"> mdi-human-male-board</v-icon>
                    <v-divider class="mt-3"></v-divider>

                </span>
                <p class="text-subtitle-2 black--text">
                    희망하시는 수업 진행 방식이나 교재가 있으면 알려주세요! 매칭 시 참고됩니다.
                </p>
            </v-card>
            <!--        <h1>{{teacherContent}}</h1>-->
            <!--            {{items}}-->
            <!--            {{removeEmptyStringFromList(teacherContent.subjectsOfExpertise)}}-->
            <v-card
                    class="mx-auto pa-5 mt-2 black--text justify-center"
                    max-width="800"
                    elevation="0"
            >

                <!-- 대학원 재학 이상 -->
                <v-form v-model="formValid" class="ma-3 pa-3" >
<!--                    <v-card-subtitle-->
<!--                            :class="{}">-->
<!--                        수업 / 과외 관련-->
<!--                    </v-card-subtitle>-->
<!--                    <v-combobox-->
<!--                            v-model="teacherContent.preferredCoverMaterial"-->
<!--                            :items="preferredCoverMaterial"-->
<!--                            label="???"-->
<!--                            multiple-->
<!--                            chips-->
<!--                    ></v-combobox>-->

                    <v-select
                            v-model="teacherContent.preferredLessonStyle"
                            :items="preferredLessonStyle"
                            label="선호 수업방식 (다중 선택 가능)"
                            multiple
                            chips
                            class="select"
                    ></v-select>
                    <v-select
                            v-model="teacherContent.preferredLessonMaterial"
                            :items="preferredLessonMaterial"
                            label="선호 교재"
                            multiple
                            class="select mb-8"
                            chips
                    ></v-select>
                    <v-btn
                            class="mr-3"
                            v-show="teacherContent.registerStatus == 'NOT_STARTED'"
                            :disabled="!formValid"
                            color="secondary"
                            @click="updateTeacherInfo(currentUser.id, teacherContent)">
                        NEXT
                    </v-btn>
                    <v-btn
                            class="mr-3"
                            v-show="teacherContent.registerStatus != 'NOT_STARTED'"
                            :disabled="!formValid"
                            color="secondary"
                            @click="updateTeacherInfo(currentUser.id, teacherContent)">
                        수정하기
                    </v-btn>
                    <v-btn v-show="teacherContent.registerStatus == 'NOT_STARTED'" :to="'/teacher/register/lesson/experience'" text>
                        Back
                    </v-btn>
                    <v-btn v-show="teacherContent.registerStatus != 'NOT_STARTED'" :to="'/teacher/register/teacher/summary'" text>
                        Back
                    </v-btn>

                </v-form>
            </v-card>
        </v-container>
    </v-container>
</template>

<script>
    import TeacherService from '../../../services/teacher.service'
    import info from "../../../../info";

    export default {
        name: "FinalDegree",
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },

        },
        mounted() {
            TeacherService.getTeacherInfo(this.currentUser.id).then(
                (response) => {
                    this.teacherContent = response.data;
                    this.teacherContent.subjectsOfExpertise = this.removeEmptyStringFromList(this.teacherContent.subjectsOfExpertise);
                    this.teacherContent.availableTimes = this.removeEmptyStringFromList(this.teacherContent.availableTimes);
                    this.teacherContent.availableDays = this.removeEmptyStringFromList(this.teacherContent.availableDays);
                    this.teacherContent.preferredLessonStyle = this.removeEmptyStringFromList(this.teacherContent.preferredLessonStyle);
                    this.teacherContent.preferredLessonMaterial = this.removeEmptyStringFromList(this.teacherContent.preferredLessonMaterial);
                    this.teacherContent.preferredCoverMaterial = this.removeEmptyStringFromList(this.teacherContent.preferredCoverMaterial);
                    this.teacherContent.selectedMathSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedMathSubSubjects);
                    this.teacherContent.selectedScienceSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedScienceSubSubjects);
                    this.teacherContent.selectedEnglishSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedEnglishSubSubjects)
                    if (this.teacherContent.highestLevelOfEducation == '대학원 재학중' ||
                        this.teacherContent.highestLevelOfEducation == '석사 졸업' ||
                        this.teacherContent.highestLevelOfEducation == '박사 졸업') {
                        this.isBeyondUndergrad = true;
                    }
                },
                (error) => {
                    this.teacherContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
            console.log(this.teacherContent)

        },
        data() {
            return {
                teacherContent: "",
                formValid: false,
                undergrad: "",
                preferredCoverMaterial: info.teacherRegister.preferredCoverMaterial,
                preferredLessonMaterial: info.teacherRegister.preferredLessonMaterials,
                preferredLessonStyle: info.teacherRegister.preferredLessonStyles,


                rules: {
                    required: (value) => !!value || '필수 입력 값입니다.',
                },
            }
        },
        methods: {
            updateTeacherInfo(userId, requestBody) {

                TeacherService.updateTeacherAboutLecture(userId, requestBody).then(
                    (response) => {
                        this.teacherContent = response.data;
                        // this.$router.push("/teacher/register/teacher/info")
                        if (this.teacherContent.registerStatus == 'NOT_STARTED') {
                            this.$router.push("/teacher/register/teacher/info")
                        } else {
                            this.$router.push("/teacher/register/teacher/summary")
                        }
                        this.next = true
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },
            removeEmptyStringFromList(input) {
                return input.filter(element => {
                    return element !== '';
                });
            },

        }
    }
</script>

<style scoped>

    .select {
        max-width: 500px;
    }
</style>
