<template>
    <div id="app">
        <v-app app>
<!--            <v-system-bar app class="top-banner" color="#FFF4FB" height="28px" v-if="!currentUser">-->
<!--                <a href="/enroll" class="font-weight-bold" target="">지금 결제하면 첫 수업 무료 혜택!-->
<!--                    <v-icon color="#414271" size="25px">mdi-face-man</v-icon>-->
<!--                    <v-icon color="#414271" size="25px">mdi-face-woman</v-icon>-->
<!--                </a>-->
<!--            </v-system-bar>-->
            <NavigationBar></NavigationBar>
            <v-main class="main-style">
                <!--            여기 router-view 는 navBar 밑에 children routes들에 감. -->
                <!--            Home.vue를 따로 만들어야 함. -->
                <v-fade-transition mode="out-in" :appear="true" :duration="200">
                    <router-view></router-view>
                </v-fade-transition>
            </v-main>
            <Footer v-if="showFooter"></Footer>

            <ChannelIO v-show="this.showFloatingButton"></ChannelIO>
            <KakaoChannelButton v-show="this.showFloatingButton"></KakaoChannelButton>
            <KakaoFloatingButton v-show="this.showFloatingButton"></KakaoFloatingButton>
            <FloatingPayButton v-show="this.showFloatingButton"></FloatingPayButton>
        </v-app>
    </div>
</template>
<script>
    import NavigationBar from "../components/NavigationBar";
    import FloatingPayButton from "../components/helpers/FloatingPayButton";
    import Footer from "../components/helpers/Footer";
    import ChannelIO from "../components/helpers/ChannelIO";
    import KakaoFloatingButton from "../components/helpers/KakaoFloatingButton";
    import KakaoChannelButton from "../components/helpers/KakaoChannelButton";

    export default {
        computed: {
            currentUser() {

                return this.$store.state.auth.user;
            },
            showFooter() {
                if (this.$route.name == 'profile') {
                    return false;
                }
                return true;
            },
            showFloatingButton() {
                // console.log(this.currentUser == true);
                if (this.$route.name == 'register'
                    || this.$route.name == 'enrollment'
                    || this.$route.name == 'tuition'
                    || this.$route.name == 'matchingInfoForm'
                    || this.$route.name == 'login'
                    || this.$route.name == 'enrollment'
                    || this.$route.name == 'payment'
                    || this.$route.name == 'paymentConfirmation'
                    || this.$route.name == 'paymentForm'
                    || this.$route.name == 'profile'
                    || this.$route.name == 'enrollment'
                    || this.$route.name == 'registerSuccess') {
                    if(this.currentUser) {
                        if (this.currentUser.roles[0] == 'ROLE_TEACHER') {
                            return false
                        }
                    }
                    return false;
                }
                return true;
            },


        },
        mounted() {
        },
        components: {
            KakaoChannelButton,
            KakaoFloatingButton,
            ChannelIO,
            Footer,
            NavigationBar,
            FloatingPayButton
        },
        methods: {
            // showFloatingButton() {
            //
            // }
        }

    }
</script>
<style scoped>
    .main-style {
        /*background: url(../assets/library_002.jpeg) center center/cover no-repeat;*/
        /*background-color: #b71c1c;*/
    }

    .top-banner {
        margin-bottom: 10px;
        text-align: center;
        /*background-color: #7a00ff;*/
        height: 35px;
        /*color: #;*/
        /*background: linear-gradient(40deg, #FFE5DC 70%, #A9A6DC 35%, #B17065 35%);*/
        background-color: #1F5C00;
        /*linear-gradient(40deg, #FFE5DC 70%, #A9A6DC 35%, #B17065 35%);*/
        /*background: linear-gradient(110deg, #A20916 70%, #DE2828 70%);*/


    }

    .top-banner a, .top-banner span {
        display: inline-block;
    }

    .top-banner a {
        width: 100%;
        /*padding: 15px 0;*/
        text-decoration: none;
        color: #2200cc;
        /*color: rgba(255, 255, 255, .8);*/
    }

    .top-banner span {
        /*width: 50px;*/
        /*heigth: 28px;*/
        text-align: justify-all;
        /*line-height: 28px;*/
        background-color: #ffcccb;
        border-radius: 20px;
    }

    .top-banner a:hover {
        color: #fff9e0;
    }

</style>
