import axios from "axios";
import authHeader from "./auth-header";

import info from "../../info";

// const API_URL = 'http://localhost:8080/api/teacher/';
// const API_URL = 'https://backend-ie.herokuapp.com/api/teacher/';
// https://backend-ie.herokuapp.com/api/test/all

//local
// const API_URL = info.urls.local + 'teacher/';

//dev
const API_URL = info.urls.staging + 'teacher/';

//prod
// const API_URL = info.urls.production + 'teacher/';

class TeacherService {

    getTeacherInfo(userId) {
        return axios.get(API_URL + userId, { headers: authHeader() });
    }
    getTeacherLessonInfo(userId) {
        return axios.get(API_URL + 'lesson/' + userId, { headers: authHeader() });
    }

    getStudentsWhoHaveLessonsWithTeacherId(teacherId) {
        return axios.get(API_URL + teacherId + '/lessons/student', { headers: authHeader() });
    }

    updateTeacherInfo(userId, requestBody) {
        return axios.put(API_URL + userId, requestBody, {headers: authHeader()});
    }

    updateTeacherHighestLevelOfEducation(userId, requestBody) {
        return axios.put(API_URL + userId + "/degree", requestBody, {headers: authHeader()});
    }

    updateTeacherSchools(userId, requestBody) {
        return axios.put(API_URL + userId + "/schools", requestBody, {headers: authHeader()});
    }

    updateTeacherHighSchool(userId, requestBody) {
        return axios.put(API_URL + userId + "/highSchool", requestBody, {headers: authHeader()});
    }

    updateTeacherSubjects(userId, requestBody) {
        return axios.put(API_URL + userId + "/subjects", requestBody, {headers: authHeader()});
    }

    updateTeacherLessonTimes(userId, requestBody) {
        return axios.put(API_URL + userId + "/lesson/times", requestBody, {headers: authHeader()});
    }

    updateTeacherLessonDuration(userId, requestBody) {
        return axios.put(API_URL + userId + "/lesson/duration", requestBody, {headers: authHeader()});
    }

    updateTeacherLessonExperience(userId, requestBody) {
        return axios.put(API_URL + userId + "/lesson/experience", requestBody, {headers: authHeader()});
    }

    updateTeacherAboutLecture(userId, requestBody) {
        return axios.put(API_URL + userId + "/lesson/about/lecture", requestBody, {headers: authHeader()});
    }

    updateTeacherAboutTeacher(userId, requestBody) {
        return axios.put(API_URL + userId + "/lesson/about/teacher", requestBody, {headers: authHeader()});
    }
    updateTeacherRegisterStatus(userId, requestBody) {
        return axios.put(API_URL + userId + "/status", requestBody, {headers: authHeader()});
    }

    updateTeacherMatchingActivateStatus(userId, requestBody) {
        return axios.put(API_URL + userId + "/matching/activate", requestBody, {headers: authHeader()});
    }
}

export default new TeacherService();
