<template>
    <v-container>
        <div>
            <v-card
                    class="mx-auto mt-5 primary--text"
                    max-width="750"
                    outlined
                    v-if="currentUser.roles[0] == 'ROLE_TEACHER'">
                <v-card-title class="font-weight-bold">선생님 정보 수정</v-card-title>
                <v-divider></v-divider>
                <div class="ma-4" v-show="teacherContent.registerStatus == 'REGISTERED'">
                    <span class="text-h6 font-weight-bold" v-if="teacherContent.matchingActivated"> 매칭 비활성화 </span>
                    <span class="text-h6 font-weight-bold" v-if="teacherContent.matchingActivated == false"> 매칭 활성화 </span>
                    <p class="mt-3"> 학생 추천을 멈추고 싶으실때 매칭 비활성화를 하여주세요. <br/>

                    </p>
                    <p>
                        <v-icon color="primary">mdi-information-outline</v-icon>
                        현재 진행중인 과외들에겐 영향이 가지 않습니다.
                    </p>

                    <div align="" class="mt-8">
                        <v-dialog
                            v-model="dialog"
                            width="500"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        color="primary"
                                        x-large>
                                    <span v-if="teacherContent.matchingActivated">비활성화 하기</span>
                                    <span v-if="teacherContent.matchingActivated ==false">활성화 하기</span>
                                </v-btn>
                            </template>

                            <v-card>
                                <v-card-title v-if="teacherContent.matchingActivated"
                                              class="accent--text text-subtitle-1  font-weight-bold primary lighten-2">
                                    IVYeD 매칭 비활성화
                                </v-card-title>
                                <v-card-title v-if="!teacherContent.matchingActivated"
                                              class="accent--text text-subtitle-1  font-weight-bold primary lighten-2">
                                    IVYeD 매칭 활성화
                                </v-card-title>

                                <v-card-text v-if="teacherContent.matchingActivated"
                                             class="ml-1 pa-3 primary--text font-weight-bold text-subtitle-1">
                                    비활성화를 진행하시겠습니까?
                                    <br/>
                                </v-card-text>
                                <v-card-text v-if="!teacherContent.matchingActivated"
                                             class="ml-1 pa-3 primary--text font-weight-bold text-subtitle-1">
                                    활성화를 진행하시겠습니까?
                                    <br/>
                                </v-card-text>
                                <v-card-text class="ml-1 pa-3 primary--text">
                                    <p><span class="font-weight-bold text-subtitle-1"> 현재상태: </span>
                                        <span v-if="teacherContent.matchingActivated" class="text-decoration-underline">활성화 되어있습니다.</span>
                                        <span v-if="!teacherContent.matchingActivated"
                                              class="text-decoration-underline">비활성화 되어있습니다. 추천이 되지 않습니다.</span>
                                    </p>
                                    <br/>
                                    <p v-if="teacherContent.matchingActivated" class="font-weight-medium">
                                        비활성화를 하시면 비활성화 기간동안은 <br/> <strong>새로운</strong> 매칭 진행 (학생 추천)이 되지 않습니다.
                                    </p>
                                    <p v-if="!teacherContent.matchingActivated" class="font-weight-medium">
                                        <v-icon color="primary"> mdi-information-outline</v-icon>
                                        <strong><span class="text-decoration-underline"> 활성화</span>를 하시면 다시 새로운 학생 추천 및
                                            매칭이 진행됩니다. </strong>
                                    </p>
                                </v-card-text>

                                <v-divider></v-divider>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn

                                            color="primary"
                                            class="font-weight-bold"
                                            text
                                            large
                                            @click="updateTeacherMatchingActivateInfo(currentUser.id, teacherContent)">

                                        <span v-if="teacherContent.matchingActivated">비활성화 하기</span>
                                        <span v-if="teacherContent.matchingActivated ==false">활성화 하기</span>

                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </div>


                    <!--                <v-btn @click="updateTeacherMatchingActivateInfo(currentUser.id, {})">-->
                    <!--                    test-->
                    <!--                </v-btn>-->

                </div>
                <div class="ma-4">
                    <v-card-title class="font-weight-bold">선생님 매칭 정보 조회</v-card-title>
                    <v-divider></v-divider>
                    <v-btn
                            color="primary"
                           class="font-weight-bold mt-5"
                           large
                           to="/teacher/summary">
                        선생님 정보 조회
                    </v-btn>
                </div>
            </v-card>
        </div>
    </v-container>
</template>

<script>
    import info from "../../../info";
    // import StudentService from '../../services/student.service'
    import TeacherService from '../../services/teacher.service'

    export default {
        name: "EditTeacherProfile",
        components: {},
        data() {
            return {
                dialog: false,
                userContent: '',
                currentPassword: '',
                passwordMatchInfo: null,
                showPasswordCard: 1,
                // showPasswordCard
                // 0 = true
                // 1 = false
                // 2 = done
                wrongPassword: false,
                passwordData: {
                    password: "",
                    passwordConfirm: ""
                },
                countryCodes: info.countryCodes,
                showPassword: false,
                showPasswordConfirm: false,
                passwordValid: false,
                valid: false,
                kakaoId: '',
                rules: {
                    required: (value) => !!value || 'Required.',
                    passwordMatch: (v1, v2) => v1 === v2 || 'Passwords Do not Match',
                    MinPassLen: (v) => v.length >= 8 || '9자 이상의 패스워드만 허용 됩니다.',
                    passwordDifferent: (v1, v2) => v1 != v2 || '새로운 패스워드만 가능합니다..',
                },
                studentContent: "",
                teacherContent: "",
            }
        },
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            }
        },
        mounted() {
            if (!this.currentUser) {
                this.$router.push('/login');
            }
            // if (this.currentUser.roles[0].includes('STUDENT')) {
            //     StudentService.getStudentInfo(this.currentUser.id).then(
            //         (response) => {
            //             this.studentContent = response.data;
            //             console.log(this.studentContent.kakaoId ==null)
            //             if (this.kakaoId == null) {
            //                 this.kakaoId = this.studentContent.kakaoId;
            //
            //             }
            //         },
            //         (error) => {
            //             this.studentContent =
            //                 (error.response &&
            //                     error.response.data &&
            //                     error.response.data.message) ||
            //                 error.message ||
            //                 error.toString();
            //         }
            //     );
            // }

            if (this.currentUser.roles[0].includes('TEACHER')) {
                TeacherService.getTeacherInfo(this.currentUser.id).then(
                    (response) => {
                        this.teacherContent = response.data;
                        console.log("fuck")
                        if (this.kakaoId == null) {
                            this.kakaoId = this.teacherContent.kakaoId;

                        }
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            }

            // this.kakaoId = this.currentUser.kakaoId;
        },
        methods: {
            updateTeacherMatchingActivateInfo(userId, requestBody) {
                requestBody.matchingActivated = !requestBody.matchingActivated;
                TeacherService.updateTeacherMatchingActivateStatus(userId, requestBody).then(
                    (response) => {
                        this.teacherContent = response.data;
                        this.$router.push("/profile")
                        this.next = true
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            }

        }

    }
</script>

<style scoped>

</style>
