<template>
    <!--------------------------------->
    <!-------- Browser Version -------->
    <!--------------------------------->


    <!--            <div class="d-flex">-->
<!--                <v-checkbox-->
<!--                        v-model="disabled"-->
<!--                        label="Disabled"-->
<!--                ></v-checkbox>-->
<!--            </div>-->
        <v-card width="700" elevation="0" color="#F3E5E5">
            <div class="justify-center">
<!--            <p class="sub-main-01 main-color">FAQ</p>-->
            <p class="sub-main-01 main-color pb-5">자주 묻는 질문</p>
            </div>
            <FaqTemplate></FaqTemplate>
        </v-card>

<!--        <v-container >-->
<!--            <v-card-->
<!--                    class="mx-auto ma-10 primary&#45;&#45;text hidden-sm-and-down"-->
<!--                    max-width="700"-->
<!--                    outlined-->
<!--                    color="accent"-->
<!--            >-->
<!--                <v-card-title class="text-center justify-center py-6">-->
<!--                    <h1 class="font-weight-bold text-h4 basil&#45;&#45;text">-->
<!--                        Frequently Asked Questions-->
<!--                    </h1>-->
<!--                </v-card-title>-->

<!--                <v-tabs-->
<!--                        v-model="tab"-->
<!--                        background-color="transparent"-->
<!--                        color="basil"-->
<!--                        grow-->
<!--                >-->
<!--                    <v-tab-->
<!--                            v-for="item in faqTitles.topics"-->
<!--                            :key="item"-->
<!--                    >-->
<!--                        {{ item }}-->
<!--                    </v-tab>-->
<!--                </v-tabs>-->
<!--                <v-tabs-items v-model="tab" class="primary&#45;&#45;text">-->
<!--                    <v-tab-item>-->
<!--                        <v-card color="basil" flat>-->
<!--                            <v-expansion-panels>-->
<!--                                <v-expansion-panel-->
<!--                                        v-for="(item,i) in faqContent.student"-->
<!--                                        :key="i">-->
<!--                                    <v-expansion-panel-header>-->
<!--                                        {{item.title}}-->
<!--                                    </v-expansion-panel-header>-->
<!--                                    <v-expansion-panel-content>-->
<!--                                        {{item.value}}-->
<!--                                    </v-expansion-panel-content>-->
<!--                                </v-expansion-panel>-->
<!--                            </v-expansion-panels>-->
<!--                        </v-card>-->
<!--                    </v-tab-item>-->
<!--                    <v-tab-item>-->
<!--                        <v-card color="basil" flat>-->
<!--                            <v-expansion-panels>-->
<!--                                <v-expansion-panel-->
<!--                                        v-for="(item,i) in faqContent.teacher"-->
<!--                                        :key="i">-->
<!--                                    <v-expansion-panel-header>-->
<!--                                        {{item.title}}-->
<!--                                    </v-expansion-panel-header>-->
<!--                                    <v-expansion-panel-content>-->
<!--                                        {{item.value}}-->
<!--                                    </v-expansion-panel-content>-->
<!--                                </v-expansion-panel>-->
<!--                            </v-expansion-panels>-->
<!--                        </v-card>-->
<!--                    </v-tab-item>-->
<!--                    <v-tab-item>-->
<!--                        <v-card color="basil" flat>-->
<!--                            <v-expansion-panels>-->
<!--                                <v-expansion-panel-->
<!--                                        v-for="(item,i) in faqContent.payment"-->
<!--                                        :key="i">-->
<!--                                    <v-expansion-panel-header>-->
<!--                                        {{item.title}}-->
<!--                                    </v-expansion-panel-header>-->
<!--                                    <v-expansion-panel-content>-->
<!--                                        {{item.value}}-->
<!--                                    </v-expansion-panel-content>-->
<!--                                </v-expansion-panel>-->
<!--                            </v-expansion-panels>-->
<!--                        </v-card>-->
<!--                    </v-tab-item>-->
<!--                    <v-tab-item>-->
<!--                        <v-card color="basil" flat>-->
<!--                            <h3>need to add content</h3>-->
<!--                            &lt;!&ndash;                    <v-expansion-panels>&ndash;&gt;-->
<!--                            &lt;!&ndash;                        <v-expansion-panel&ndash;&gt;-->
<!--                            &lt;!&ndash;                                v-for="(item,i) in faqContent.teacher"&ndash;&gt;-->
<!--                            &lt;!&ndash;                                :key="i">&ndash;&gt;-->
<!--                            &lt;!&ndash;                            <v-expansion-panel-header>&ndash;&gt;-->
<!--                            &lt;!&ndash;                                {{item.title}}&ndash;&gt;-->
<!--                            &lt;!&ndash;                            </v-expansion-panel-header>&ndash;&gt;-->
<!--                            &lt;!&ndash;                            <v-expansion-panel-content>&ndash;&gt;-->
<!--                            &lt;!&ndash;                                {{item.value}}&ndash;&gt;-->
<!--                            &lt;!&ndash;                            </v-expansion-panel-content>&ndash;&gt;-->
<!--                            &lt;!&ndash;                        </v-expansion-panel>&ndash;&gt;-->
<!--                            &lt;!&ndash;                    </v-expansion-panels>&ndash;&gt;-->
<!--                        </v-card>-->
<!--                    </v-tab-item>-->
<!--                    <v-tab-item>-->
<!--                        <v-card color="basil" flat>-->
<!--                            <v-expansion-panels>-->
<!--                                <v-expansion-panel-->
<!--                                        v-for="(item,i) in faqContent.etc"-->
<!--                                        :key="i">-->
<!--                                    <v-expansion-panel-header>-->
<!--                                        {{item.title}}-->
<!--                                    </v-expansion-panel-header>-->
<!--                                    <v-expansion-panel-content>-->
<!--                                        {{item.value}}-->
<!--                                    </v-expansion-panel-content>-->
<!--                                </v-expansion-panel>-->
<!--                            </v-expansion-panels>-->
<!--                        </v-card>-->
<!--                    </v-tab-item>-->


<!--                </v-tabs-items>-->
<!--            </v-card>-->

<!--            &lt;!&ndash;-&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&ndash;&gt;-->
<!--            &lt;!&ndash;&#45;&#45;&#45;&#45;&#45;&#45; Mobile Version  &#45;&#45;&#45;&#45;&#45;&#45;&ndash;&gt;-->
<!--            &lt;!&ndash;-&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&ndash;&gt;-->
<!--            <v-card-->
<!--                    class="mx-auto ma-10 primary&#45;&#45;text hidden-md-and-up"-->
<!--                    max-width="700"-->
<!--                    outlined>-->
<!--                <v-card-title class="text-center justify-center py-6">-->
<!--                    <h5 class="font-weight-bold text-md-h5 basil&#45;&#45;text">-->
<!--                        Frequently Asked Questions-->
<!--                    </h5>-->
<!--                </v-card-title>-->

<!--                <v-tabs-->
<!--                        v-model="tab"-->
<!--                        background-color="transparent"-->
<!--                        color="basil"-->
<!--                        grow-->
<!--                        show-arrows-->
<!--                >-->
<!--                    <v-tab-->
<!--                            v-for="item in faqTitles.topics"-->
<!--                            :key="item"-->
<!--                    >-->
<!--                        {{ item }}-->
<!--                    </v-tab>-->
<!--                </v-tabs>-->


<!--                <v-tabs-items v-model="tab" class="primary&#45;&#45;text">-->
<!--                    <v-tab-item>-->
<!--                        <v-card color="basil" flat>-->
<!--                            <v-expansion-panels>-->
<!--                                <v-expansion-panel-->
<!--                                        v-for="(item,i) in faqContent.student"-->
<!--                                        :key="i">-->
<!--                                    <v-expansion-panel-header>-->
<!--                                        {{item.title}}-->
<!--                                    </v-expansion-panel-header>-->
<!--                                    <v-expansion-panel-content>-->
<!--                                        {{item.value}}-->
<!--                                    </v-expansion-panel-content>-->
<!--                                </v-expansion-panel>-->
<!--                            </v-expansion-panels>-->
<!--                        </v-card>-->
<!--                    </v-tab-item>-->
<!--                    <v-tab-item>-->
<!--                        <v-card color="basil" flat>-->
<!--                            <v-expansion-panels>-->
<!--                                <v-expansion-panel-->
<!--                                        v-for="(item,i) in faqContent.teacher"-->
<!--                                        :key="i">-->
<!--                                    <v-expansion-panel-header>-->
<!--                                        {{item.title}}-->
<!--                                    </v-expansion-panel-header>-->
<!--                                    <v-expansion-panel-content>-->
<!--                                        {{item.value}}-->
<!--                                    </v-expansion-panel-content>-->
<!--                                </v-expansion-panel>-->
<!--                            </v-expansion-panels>-->
<!--                        </v-card>-->
<!--                    </v-tab-item>-->
<!--                    <v-tab-item>-->
<!--                        <v-card color="basil" flat>-->
<!--                            <v-expansion-panels>-->
<!--                                <v-expansion-panel-->
<!--                                        v-for="(item,i) in faqContent.payment"-->
<!--                                        :key="i">-->
<!--                                    <v-expansion-panel-header>-->
<!--                                        {{item.title}}-->
<!--                                    </v-expansion-panel-header>-->
<!--                                    <v-expansion-panel-content>-->
<!--                                        {{item.value}}-->
<!--                                    </v-expansion-panel-content>-->
<!--                                </v-expansion-panel>-->
<!--                            </v-expansion-panels>-->
<!--                        </v-card>-->
<!--                    </v-tab-item>-->
<!--                    <v-tab-item>-->
<!--                        <v-card color="basil" flat>-->
<!--                            <h3>need to add content</h3>-->
<!--                            &lt;!&ndash;                    <v-expansion-panels>&ndash;&gt;-->
<!--                            &lt;!&ndash;                        <v-expansion-panel&ndash;&gt;-->
<!--                            &lt;!&ndash;                                v-for="(item,i) in faqContent.teacher"&ndash;&gt;-->
<!--                            &lt;!&ndash;                                :key="i">&ndash;&gt;-->
<!--                            &lt;!&ndash;                            <v-expansion-panel-header>&ndash;&gt;-->
<!--                            &lt;!&ndash;                                {{item.title}}&ndash;&gt;-->
<!--                            &lt;!&ndash;                            </v-expansion-panel-header>&ndash;&gt;-->
<!--                            &lt;!&ndash;                            <v-expansion-panel-content>&ndash;&gt;-->
<!--                            &lt;!&ndash;                                {{item.value}}&ndash;&gt;-->
<!--                            &lt;!&ndash;                            </v-expansion-panel-content>&ndash;&gt;-->
<!--                            &lt;!&ndash;                        </v-expansion-panel>&ndash;&gt;-->
<!--                            &lt;!&ndash;                    </v-expansion-panels>&ndash;&gt;-->
<!--                        </v-card>-->
<!--                    </v-tab-item>-->
<!--                    <v-tab-item>-->
<!--                        <v-card color="basil" flat>-->
<!--                            <v-expansion-panels>-->
<!--                                <v-expansion-panel-->
<!--                                        v-for="(item,i) in faqContent.etc"-->
<!--                                        :key="i">-->
<!--                                    <v-expansion-panel-header>-->
<!--                                        {{item.title}}-->
<!--                                    </v-expansion-panel-header>-->
<!--                                    <v-expansion-panel-content>-->
<!--                                        {{item.value}}-->
<!--                                    </v-expansion-panel-content>-->
<!--                                </v-expansion-panel>-->
<!--                            </v-expansion-panels>-->
<!--                        </v-card>-->
<!--                    </v-tab-item>-->


<!--                </v-tabs-items>-->
<!--            </v-card>-->
<!--        </v-container>-->

</template>
<script>

    import info from "../../../info";
    import FaqTemplate from "./FaqTemplate";

    export default {
        name: 'FAQ',
        components: {FaqTemplate},
        data() {
            return {
                tab: null,
                // items: [
                //     '학생', '선생님', '결제', '화이트보드', '수업문의',
                // ],
                faqTitles: info.faq,
                faqContent: info.faqContent,
                panel: [0, 1],
                disabled: false,
                readonly: false,
            }
        },
    }
</script>
<style scoped>
    .faq-style {
        /*background: url(../../assets/library_cornell_001_dark.png) center center/cover no-repeat;*/
    }
    .container {
        margin: 0 auto;
        max-width: 600px;
        padding: 1rem;
    }

    .justify-center {
        text-align: center;
    }
    .sub-main-01 {
        font-weight: bold;
        /*color: #290F29;*/
        font-size: 36px;
    }
    .question-font {
        font-size: 17px;
    }
    .test {
        /*box-shadow: 0px -2px 2px rgba(34, 34, 34, 0.6);*/

    }
</style>
