<template>
  <div class="primary--text">
<h1>수업</h1>
  </div>
</template>

<script>

  export default {
  name: 'Tuition',
  components: {
  },
};
</script>

<style scoped>

</style>
