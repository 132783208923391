<template>
    <v-container fluid>
        <!--        {{userTeachers}}-->
        <v-card width="100%">
            <v-card-title>
                Teachers
                <v-spacer></v-spacer>
                <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                ></v-text-field>
            </v-card-title>
            <v-data-table
                    :headers="headers"
                    :items="userTeachers"
                    class="elevation-1"
                    :search="search"
            >
                <template v-slot:top>
                    <v-toolbar
                            flat
                    >

                        <v-divider
                                class="mx-4"
                                inset
                                vertical
                        ></v-divider>
                        <v-spacer></v-spacer>
                        <v-dialog
                                v-model="dialog"
                                max-width="500px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                        color="primary"
                                        dark
                                        class="mb-2"
                                        v-bind="attrs"
                                        v-on="on"
                                >
                                    New Item
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-container>
<!--                                        {{editedItem}}-->
                                        <v-row>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedItem.username"
                                                        label="username"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <!--                                            username: '',-->
                                                <!--                                            firstName: '',-->
                                                <!--                                            email: '',-->
                                                <!--                                            phoneNumber: '',-->
                                                <!--                                            roleType: '',-->
                                                <v-text-field
                                                        v-model="editedItem.firstName"
                                                        label="first name"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedItem.email"
                                                        label="Email"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedItem.phoneNumber"
                                                        label="phone number"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-select
                                                        v-model="editedItem.teacher.registerStatus"
                                                        label="register status"
                                                        :items="teacherRegisterStatusItems"
                                                ></v-select>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedItem.roleType"
                                                        label="role type"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="close"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="save"
                                    >
                                        Save
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogSuggestStudent" max-width="500px">
                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">Suggest Student</span>
                                    <!--                            <h1>{{value}}</h1>-->
                                    <!--                            <v-divider></v-divider>-->
                                    <!--                            <h2>teacher ID: {{editedItem.id}}</h2>-->
                                    <!--                            <v-divider></v-divider>-->
                                    <!--                            <h2>student: {{studentsWithMatchingRequests}}</h2>-->
                                </v-card-title>

                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <!--                                        <v-text-field-->
                                                <!--                                                v-model="editedItem.username"-->
                                                <!--                                                label="username"-->
                                                <!--                                        ></v-text-field>-->

                                                <v-autocomplete
                                                        v-model="value"
                                                        :items="studentsWithMatchingRequests"
                                                        item-text="studentId"
                                                        item-value="studentId"
                                                        return-object
                                                        dense
                                                        filled
                                                        label="Student ID"
                                                ></v-autocomplete>
                                            </v-col>
                                        </v-row>
                                        <v-card>
                                            <v-card-title><h2>Lesson Info</h2></v-card-title>
                                            <h2 class="text--primary">
                                                Student ID: {{value.studentId}}
                                                <v-divider></v-divider>
                                                <strong>Student username: </strong>{{value.username}}
                                                <v-divider></v-divider>
                                                <strong>Payment ID:</strong> {{value.paymentId}}
                                                <v-divider></v-divider>
                                                <strong>Teacher ID:</strong> {{editedItem.id}}
                                            </h2>
                                        </v-card>

                                    </v-container>
                                </v-card-text>


                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="closeSuggestStudent"
                                    >
                                        Cancel
                                    </v-btn>

                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="submitSuggestStudent"
                                    >
                                        Suggest Student
                                    </v-btn>
                                </v-card-actions>
                            </v-card>

                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-icon
                            small
                            class="mr-2"
                            @click="suggestStudent(item)"

                    >
                        mdi-account-plus
                    </v-icon>

                    <v-icon
                            small
                            class="mr-2"
                            @click="editItem(item)"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                            small
                            @click="deleteItem(item)"
                    >
                        mdi-delete
                    </v-icon>
                </template>
                <template v-slot:no-data>
                    <v-btn
                            color="primary"
                            @click="initialize"
                    >
                        Reset
                    </v-btn>
                </template>
            </v-data-table>
            <!--        {{userStudents}}-->
        </v-card>

        <v-divider class="mt-15 mb-15"></v-divider>
        <v-card width="100%">
            <!--            {{userTeachers}}-->
            <v-card-title>
                선생별 과외 리스트
            </v-card-title>
            <v-container fluid v-for="item in userTeachers" :key="item.id">
                <v-card-subtitle class="text-strong">
                    Teacher: {{item.username}}
                </v-card-subtitle>
                <v-divider></v-divider>
                <!--                {{item.teacher.lessons}}-->
                <v-data-table
                        :headers="lessonHeaders"
                        :items="item.teacher.lessons"
                        class="elevation-1"
                >

                </v-data-table>
            </v-container>
        </v-card>

    </v-container>
</template>

<script>
    import UserService from "../../services/user.service"
    import StudentService from "../../services/student.service"
    import LessonService from "../../services/lesson.service";
    import EmailService from '../../services/email.service'

    export default {
        name: "TeachersDataTable",
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
            formTitle() {
                return this.editedIndex === -1 ? 'new item' : 'edit item'
            },
        },
        mounted() {
            if (!this.currentUser) {
                this.$router.push('/login');
            }

            UserService.getAllUsers().then(
                (response) => {
                    this.users = response.data;
                },
                (error) => {
                    this.users =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

            UserService.getAllUserStudents().then(
                (response) => {
                    this.userStudents = response.data;
                },
                (error) => {
                    this.userStudents =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

            UserService.getAllUserTeachers().then(
                (response) => {
                    this.userTeachers = response.data;
                },
                (error) => {
                    this.userTeachers =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

            // add student info fetcher
            StudentService.getStudentInfo(this.currentUser.id).then(
                (response) => {
                    this.studentContent = response.data;
                },
                (error) => {
                    this.studentContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

            StudentService.getAllStudents().then(
                (response) => {
                    this.students = response.data;
                },
                (error) => {
                    this.students =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
            // if()
        },
        data: () => ({
            // editedIndex: -1,
            lessonRequestBody: {
                teacherId: '',
                studentId: '',
                paymentId: '',
            },
            lessonResponseBody: {},
            userStudents: [],
            studentsWithMatchingRequests: [],
            value: '',
            search: '',
            userTeachers: [],
            studentContent: "",
            requestBody: "",
            students: [],
            dialog: false,
            dialogDelete: false,
            dialogSuggestStudent: false,
            users: [],
            teacherRegisterStatusItems:[
                "NOT_STARTED",
                "STARTED",
                "REGISTER_INFO_FILLED",
                "PENDING",
                "PENDING_COFFEE_CHAT",
                "BEFORE_CONTRACT_SIGNING",
                "REGISTERED",
                "DEACTIVATED"
            ],
            headers: [
                {
                    text: 'user_id',
                    align: 'start',
                    value: 'id',
                },
                {text: 'username', value: 'username'},
                // {text: 'password', value: 'password'},
                // {text: 'firstname', value: 'firstName'},
                // {text: 'email', value: 'email'},
                // {text: 'phone number', value: 'phoneNumber'},
                // {text: 'matching status', value: 'student.matchingStatus'},
                {text: 'register status', value: 'teacher.registerStatus'},
                {text: 'matching status', value: 'teacher.teacherMatchingStatus'},
                {text: '수업', value: 'teacher.subjectsOfExpertise'},
                {text: '시간', value: 'teacher.availableTimes'},
                {text: '과외', value: 'teacher.lessons'},
                // {text: 'location', value: 'student.currentLocation'},
                {text: 'last login', value: 'lastLogin'},
                {text: 'Actions', value: 'actions', sortable: false},

            ],
            lessonHeaders: [
                {text: 'lesson_id', value: 'id', align: 'start',},
                {text: 'lesson_status', value: 'status'},
                {text: 'student_id', value: 'sid'},
                {text: 'pageCallLink', value: 'pageCallLink',},
                {text: 'teacherMatchingStatus', value: 'teacherMatchingStatus'},
                {text: 'studentMatchingStatus', value: 'studentMatchingStatus'},
                {text: 'schedule', value: 'schedule',},
                {text: 'message', value: 'message',},
            ],
            desserts: [],
            editedIndex: -1,
            editedItem: {
                username: '',
                firstName: '',
                email: '',
                phoneNumber: '',
                roleType: '',
                teacher: '',
            },
            defaultItem: {
                username: '',
                firstName: '',
                email: '',
                phoneNumber: '',
                roleType: '',
                teacher: '',
            },
        }),
        watch: {
            dialog(val) {
                val || this.close()
            },
            dialogDelete(val) {
                val || this.closeDelete()
            },
            dialogSuggestStudent(val) {
                val || this.closeSuggestStudent()
            }
        },

        created() {
            this.initialize()
        },
        methods: {

            getWaitingStudents() {


                for (let i = 0; i < this.userStudents.length; i++) {
                    if (this.userStudents[i].student.matchingStatus == 'REQUESTED') {
                        console.log("HERE")
                        console.log(this.userStudents[i].student.payments[this.userStudents[i].student.activePaymentId].id)
                        console.log("HERE")
                        let sex = {
                            studentId: this.userStudents[i].id,
                            username: this.userStudents[i].username,
                            paymentId: this.userStudents[i].student.payments[this.userStudents[i].student.activePaymentId].id
                        };
                        this.studentsWithMatchingRequests.push(sex)
                    }
                }
            },

            findOutToWhomToEmailTo(user) {
                console.log(user);
                console.log(user);
                console.log(user.roles[0]);
                if (user.roles[0].name == 'ROLE_TEACHER') {
                    // if (user.teacher.registerStatus ==  'REGISTER_INFO_FILLED') {
                    //
                    // } else if (user.teacher.registerStatus ==  'PENDING') {
                    //
                    // } else
                    if (user.teacher.registerStatus == 'PENDING_COFFEE_CHAT') {
                        this.sendNotificationEmail({
                            "email": user.email, "emailBody": "[WIP] PENDING_COFFEE_CHAT"
                        })
                    } else if (user.teacher.registerStatus == 'BEFORE_CONTRACT_SIGNING') {
                        this.sendNotificationEmail({
                            "email": user.email, "emailBody": "[WIP] BEFORE_CONTRACT_SIGNING"
                        })
                    }
                    else if (user.teacher.registerStatus == 'REGISTERED') {
                        this.sendNotificationEmail({
                            "email": user.email, "emailBody": "[WIP] REGISTERED"
                        })
                    }
                }
            },

            sendNotificationEmail(requestBody) {
                EmailService.sendEmailNotification(requestBody).then(
                    (response) => {
                        console.log(response.data)
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },


            updateUserForAdmin(userId, requestBody) {
                UserService.updateUserForAdmin(userId, requestBody).then(
                    (response) => {
                        this.findOutToWhomToEmailTo(requestBody);
                        console.log(response.data);
                        return response.data;
                    },
                    (error) => {
                        // this.users =
                        return (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },


            initialize() {
            },

            suggestStudent(user) {
                console.log("====IN SUGGEST STUDENT====")
                console.log("====================================")
                console.log(user)

                this.editedIndex = this.users.indexOf(user);
                this.editedItem = Object.assign({}, user)

                console.log("====================================")
                console.log(this.editedIndex)
                console.log("====================================")

                this.getWaitingStudents();
                this.dialogSuggestStudent = true;
            },
            editItem(user) {
                this.editedIndex = this.users.indexOf(user)
                this.editedItem = Object.assign({}, user)
                console.log("====================================")
                console.log("====IN EDIT ITEM====")
                console.log(this.editedIndex)
                console.log("====================================")

                // updateUserForAdmin()
                this.dialog = true
            },

            deleteItem(item) {
                this.editedIndex = this.desserts.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialogDelete = true
            },

            deleteItemConfirm() {
                this.desserts.splice(this.editedIndex, 1)
                this.closeDelete()
            },

            close() {
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            closeDelete() {
                this.dialogDelete = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            closeSuggestStudent() {
                this.dialogSuggestStudent = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            submitSuggestStudent() {
                console.log(this.editedIndex);
                if (this.editedIndex > -1) {
                    Object.assign(this.users[this.editedIndex], this.editedItem)


                } else {

                    //call method here
                    // console.log("+++++++++++++++++++++++++++++++++++++")
                    // console.log("+++++++++++++++++++++++++++++++++++++")
                    // console.log(this.value)
                    // console.log(this.editedItem.id)
                    // console.log("+++++++++++++++++++++++++++++++++++++")
                    // console.log("+++++++++++++++++++++++++++++++++++++")
                    this.lessonRequestBody.teacherId = this.editedItem.id
                    this.lessonRequestBody.studentId = this.value.studentId
                    this.lessonRequestBody.paymentId = this.value.paymentId
                    this.createEmptyLesson(this.lessonRequestBody);
                    this.users.push(this.editedItem)
                }
                this.closeSuggestStudent()
            },

            save() {

                if (this.editedIndex > -1) {
                    Object.assign(this.users[this.editedIndex], this.editedItem)

                } else {
                    this.updateUserForAdmin(this.editedItem.id, this.editedItem)
                    this.users.push(this.editedItem)
                }
                this.close()
            },

            getUserInfoOfStudent(userId) {
                StudentService.getUserInfoOfStudent(userId).then(
                    (response) => {
                        console.log(response.data)
                        this.studentContent = response.data;
                    },
                    (error) => {
                        this.studentContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },
            createEmptyLesson(requestBody) {
                console.log("lesson creation started!");
                console.log(requestBody);
                // requestBody.teacherMatchingStatus = '';
                LessonService.createEmptyLesson(requestBody).then(
                    (response) => {
                        this.lessonResponseBody = response.data;
                        console.log(requestBody);
                        this.sendNotificationEmail({
                           "teacherId": requestBody.teacherId +"",
                           "emailBody": "[WIP] 학생 추천입니다. 수락해라"
                        });
                        console.log(this.lessonResponseBody)
                    },
                    (error) => {
                        this.lessonResponseBody =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            }
        },

    }
</script>

<style scoped>

</style>
