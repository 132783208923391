<template>
    <v-container>
        <v-container v-if="currentUser.roles[0] == 'ROLE_TEACHER'">
            <h2>
                <router-link to="/profile"> BACK TO PROFILE</router-link>
            </h2>
        </v-container>
        <v-container v-if="currentUser.roles[0] == 'ROLE_STUDENT'">
            <!--            <v-btn @click="temp=!temp">temp</v-btn>-->
            <!--            <LoadingAnimation v-show="temp"></LoadingAnimation>-->
            <v-card
                    class="mx-auto primary--text"
                    max-width="750"
                    outlined
                    :class="{
                    // 'mt-5': $vuetify.breakpoint.lgAndUp,
                    // 'ml-5': $vuetify.breakpoint.lgAndUp,
                    // 'mb-5': $vuetify.breakpoint.lgAndUp,
                    }"
            >
                <div class="ml-5 mt-5 mr-5 mb-5">
                    <span
                            :class="{
                    'text-h6': $vuetify.breakpoint.mdAndDown,
                    'text-h5': $vuetify.breakpoint.lgAndUp
                    }"
                    >
                        <strong>아래 내용을 꼭 작성해주세요!</strong>
                        <hr>
                    </span>
                    <span
                            :class="{
                    'text-h8': $vuetify.breakpoint.mdAndDown,
                    'text-h7': $vuetify.breakpoint.lgAndUp
                    }">
                        적어주신 내용은 선생님을 매칭하는데 활용 되고 있어요.
                        <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">

                        <v-icon class="primary--text"
                                v-bind="attrs"
                                v-on="on">
                            mdi-message-question-outline
                        </v-icon>
                                  </template>
                            <span>알아보기</span>
                            </v-tooltip>
                    </span>
                    <!--                    <h1> 아직 아이비에드에 학생으로 등록하지 않으셨군요! </h1>-->
                    <!--                    <h1> 등록해라!! 돈내라!! </h1>-->
                    <!--                    <v-divider></v-divider>-->
                    <!--                    <h1> 학생 수업관련 입력 후 결제 하는 페이지로 이동</h1>-->
                </div>
                <v-divider></v-divider>
                <!--            <h2>{{currentUser}}</h2>-->
                <!--            <v-divider></v-divider>-->
                <!--    <h1> {{studentContent}} </h1>-->
                <template>

                    <v-form class="ml-5 mr-5 mt-5 mb-5"
                            ref="form"
                            v-model="valid"
                            lazy-validation
                    >
                        <!--                    <v-text-field-->
                        <!--                            v-model="formContents.subjects"-->
                        <!--                            :counter="100"-->
                        <!--                            :rules="nameRules"-->
                        <!--                            label="어떤 과목들 도움이 필요하신가요?"-->
                        <!--                            required-->
                        <!--                    ></v-text-field>-->
                        <v-row>
                            <v-col cols="12">
                                <v-combobox
                                        v-model="studentContent.subjectsOfInterest"
                                        :items="items"
                                        label="과목선택"
                                        multiple
                                        chips
                                >

                                </v-combobox>

                            </v-col>
                        </v-row>
                        <!--                        <v-text-field-->
                        <!--                                v-model="studentContent.currentLocation"-->
                        <!--                                :rules="nameRules"-->
                        <!--                                label="지금 사는 지역은 어디신가요?"-->
                        <!--                                required-->
                        <!--                        ></v-text-field>-->

                        <v-select
                                v-model="studentContent.currentLocation"
                                :items="countryItems"
                                :rules="[v => !!v || 'Item is required']"
                                label="현재 거주하시는 국가는 어디신가요?"
                                required
                        ></v-select>
                        <v-text-field v-if="studentContent.currentLocation == '직접입력'"
                                      v-model="currentLocation"
                                      :rules="nameRules"
                                      label="거주국가"
                                      required>
                        </v-text-field>
                        <v-select
                                v-model="studentContent.currentGrade"
                                :items="possibleGrades"
                                :rules="[v => !!v || 'Item is required']"
                                label="현재 학년"
                                required
                        ></v-select>
                        <v-select
                                v-model="studentContent.schoolType"
                                :items="possibleSchoolTypes"
                                :rules="[v => !!v || 'Item is required']"
                                label="재학중인 학교 종류"
                                required
                        ></v-select>
                        <v-text-field
                                v-model="studentContent.school"
                                :rules="nameRules"
                                label="학교 이름"
                                required>
                        </v-text-field>
                        <v-combobox
                                v-model="studentContent.availableDays"
                                :items="preferredLessonDays"
                                label="선호 수업 요일 (다중 선택 가능)"
                                multiple
                                chips
                        ></v-combobox>
                        <v-combobox
                                v-model="studentContent.availableTimes"
                                :items="preferredLessonTimes"
                                label="선호 시간대 (다중 선택 가능)"
                                multiple
                                chips
                        ></v-combobox>
                        <v-select
                                v-model="studentContent.expectedNumberOfLessonsPerWeek"
                                :items="frequencyItems"
                                :rules="[v => !!v || 'Item is required']"
                                label="주 몇회 과외를 원하시나요?"
                                required
                        ></v-select>
                        <v-select
                                v-model="studentContent.expectedLessonDuration"
                                :items="durationItems"
                                :rules="[v => !!v || 'Item is required']"
                                label="총 얼마동안?"
                                required
                        ></v-select>
                        <v-select
                                v-model="studentContent.tablet"
                                :items="tabletOptions"
                                :rules="[v => !!v || 'Item is required']"
                                label="태블릿?"
                                required
                        ></v-select>
                        <v-combobox
                                v-model="studentContent.goalOfLesson"
                                :items="goalOfLessons"
                                label="과외 목적"
                                multiple
                                chips
                        ></v-combobox>

                        <v-combobox
                                v-model="studentContent.preferredLessonStyle"
                                :items="preferredLessonStyles"
                                label="선호 하는 수업방식 (다중 선택 가능)"
                                multiple
                                chips
                        ></v-combobox>
                        <v-combobox
                                v-model="studentContent.preferredLessonMaterial"
                                :items="preferredLessonMaterials"
                                label="선호 교재"
                                multiple
                                chips
                        ></v-combobox>
                        <v-container fluid>
                            <v-card-title>수업 횟수 / 시간 선택</v-card-title>
                            <v-checkbox
                                    v-model="studentContent.payableItem"
                                    label="주 1회 총 5회권 500,000원"
                                    value="500000"
                            ></v-checkbox>
                            <v-checkbox
                                    v-model="studentContent.payableItem"
                                    label="주 2회 총 10회권 1,000,000원"
                                    value="1000000"
                            ></v-checkbox>
                        </v-container>
                        <v-divider></v-divider>
                        <v-checkbox
                                v-model="formContents.privateInfoAgree"
                                :rules="[v => !!v || 'You must agree to continue!']"
                                label="동의함"
                                required
                                @click="updateStudent(currentUser.id, studentContent)"
                        ></v-checkbox>

                        <v-btn
                                :disabled="!valid"
                                color="primary"
                                class="mr-4"
                                to="/payment"
                        >
                            수업 결제하기
                        </v-btn>

                        <!--                    <v-btn-->
                        <!--                            color="error"-->
                        <!--                            class="mr-4"-->
                        <!--                            @click="reset"-->
                        <!--                    >-->
                        <!--                        다시 작성-->
                        <!--                    </v-btn>-->

                        <!--                    <v-btn-->
                        <!--                            color="warning"-->
                        <!--                            @click="resetValidation"-->
                        <!--                    >-->
                        <!--                        Reset Validation-->
                        <!--                    </v-btn>-->
                    </v-form>
                </template>
                <!--                <h1>{{studentContent}}</h1>-->
                <!--                {{valid}}-->
                <!--            <h1>{{formContents}}</h1>-->
                <v-divider></v-divider>
                <!--            <v-card>-->
                <!--                <h2> Premimum Member: {{studentContent.premiumMember}} </h2>-->
                <!--                <h2> Active: {{studentContent.active}} </h2>-->


                <!--            </v-card>-->
                <v-divider></v-divider>
                <!--            <v-card>-->
                <!--                <h2>-->
                <!--                    학생 정보 입력 섹션-->

                <!--                </h2>-->
                <!--            </v-card>-->
                <v-divider></v-divider>
                <!--            <v-card>-->
                <!--                <h2>-->
                <!--                    과목 선택-->

                <!--                </h2>-->
                <!--            </v-card>-->
            </v-card>
        </v-container>

    </v-container>

</template>

<script>
    import StudentService from "../../services/student.service"
    import info from "../../../info";

    export default {
        name: "PaymentForm",
        components: {},
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
        },
        mounted() {
            if (!this.currentUser) {
                this.$router.push('/login');
            }

            StudentService.getStudentInfo(this.currentUser.id).then(
                (response) => {
                    this.studentContent = response.data;
                    this.studentContent.subjectsOfInterest = this.removeEmptyStringFromList(this.studentContent.subjectsOfInterest);
                    this.studentContent.availableDays = this.removeEmptyStringFromList(this.studentContent.availableDays);
                    this.studentContent.availableTimes = this.removeEmptyStringFromList(this.studentContent.availableTimes);
                    this.studentContent.goalOfLesson = this.removeEmptyStringFromList(this.studentContent.goalOfLesson);
                    this.studentContent.preferredLessonMaterial = this.removeEmptyStringFromList(this.studentContent.preferredLessonMaterial);
                    this.studentContent.preferredLessonStyle = this.removeEmptyStringFromList(this.studentContent.preferredLessonStyle);
                    // console.log("IN GET STUDENT INFO")
                    // console.log(this.studentContent)
                    // console.log("IN GET STUDENT INFO")
                },
                (error) => {
                    this.studentContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        methods: {
            isCurrentLocationNotInTheList() {
                // const currentLocation = this.studentContent.currentLocation;
                if (this.studentContent.currentLocation == '직접입력') {
                    this.currentLocationDifferent = true;
                    this.studentContent.currentLocation = '';

                    return true;
                }
                return false;
            },
            // validate() {
            //     this.$refs.form.validate()
            // },
            // reset() {
            //     this.$refs.form.reset()
            // },
            // resetValidation() {
            //     this.$refs.form.resetValidation()
            // },
            // applyStudentRegisterData(userId, requestBody) {
            //     // console.log(this.formContents);
            //     // this.$store.state.studentRegisterData = this.formContents;
            //     // console.log("====================");
            //     // console.log(this.$store.state.studentRegisterData);
            //
            //
            //
            // },
            removeEmptyStringFromList(input) {
                return input.filter(element => {
                    return element !== '';
                });
            },

            removeStringFromString(input) {
                return input.replace(/\D/g, '');
            },

            updateStudent(userId, requestBody) {
                if (this.studentContent.currentLocation == '직접입력') {
                    console.log("============================================")
                    console.log(this.currentLocation)
                    console.log("============================================")
                    this.studentContent.currentLocation = this.currentLocation;
                }

                this.$store.state.studentRegisterData = this.studentContent;

                requestBody.expectedNumberOfLessonsPerWeek = this.removeStringFromString(requestBody.expectedNumberOfLessonsPerWeek);
                requestBody.expectedLessonDuration = this.removeStringFromString(requestBody.expectedLessonDuration);
                console.log("============================================")
                console.log(requestBody)
                console.log("============================================")
                StudentService.updateStudent(userId, requestBody).then(
                    (response) => {
                        console.log(response.data);
                    },
                    (error) => {
                        this.studentContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        console.log(error.response)
                    }
                )
            },


            moveToProfile() {
                // this.$store.dispatch('auth/logout');
                this.$router.push('/');
            }
        },
        data: () => (
            {
                formContents: {
                    subjects: [],
                    country: "",
                    frequency: "",
                    duration: "",
                    payableItem: [''],
                    agree: false,
                    tablet: "",

                },
                temp: false,
                currentLocation: "",
                currentLocationDifferent: false,
                items: info.teacherRegister.subjectsOfExpertise,
                studentContent: "",
                valid: false,
                name: '',
                nameRules: [
                    v => !!v || '필수 입력',
                    v => (v && v.length <= 100) || 'must be less than 100 characters',
                ],
                email: '',
                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ],
                select: null,
                frequencyItems: [
                    '1회',
                    '2회',
                    '3회',
                    '5회',
                ],
                possibleSchoolTypes: [
                    '공립학교',
                    '사립학교',
                    '외국인/국제학교',
                    '외국어고등학교',
                    '과학고',
                    '홈스쿨링',
                    '직접입력',
                ],
                possibleGrades: [
                    '6학년',
                    '7학년',
                    '8학년',
                    '9학년',
                    '10학년',
                    '11학년',
                    '12학년',
                ],
                preferredLessonStyles: [
                    '내신 관리 / 문제풀이 위주',
                    '시험 준비',
                    '개념 정리',
                ],
                preferredLessonMaterials: [
                    '개인 소유 / 선호교재 (학교 자료 등)',
                    '선생님이 추천해주는 교재로 진행'
                ],
                goalOfLessons: [
                    '입시 시험 대비 (SAT, GRE 등)',
                    '내신 관리 / 성적 향상'
                ],
                preferredLessonTimes: [
                    '오전',
                    '오후',
                    '저녁 이후'
                ],
                preferredLessonDays: [
                    '월',
                    '화',
                    '수',
                    '목',
                    '금',
                    '토',
                    '일',
                ],

                countryItems: [
                    '대한민국',
                    '미국',
                    '호주',
                    '영국',
                    '캐나다',
                    '뉴질랜드',
                    '중국',
                    '일본',
                    '싱가포르',
                    '인도네시아',
                    '직접입력',
                ],
                durationItems: [
                    '1개월',
                    '2개월',
                    '3개월',
                    '6개월',
                    '12개월'
                ],
                tabletOptions: [
                    '줘',
                    '필요없음'
                ],
                checkbox: false,
                checkbox1: true,
                checkbox2: false,
            }
        )
    }
</script>

<style scoped>

</style>
