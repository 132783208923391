var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"hero-container white--text",attrs:{"fluid":""}},[_c('v-container',{staticClass:"new-container"},[_c('v-row',{attrs:{"dense":"","align":"center"}},[_c('v-col',{staticClass:"center-horizontal",attrs:{"cols":"12"}},[_c('h1',{staticClass:"font-weight-bold mb-5 text-center",class:{
              'text-h1': _vm.$vuetify.breakpoint.xs,
              'text-h1': _vm.$vuetify.breakpoint.sm,
              'text-medium': _vm.$vuetify.breakpoint.mdAndUp,
            }},[_vm._v(" 아이비에드의 수강생은 ")]),_c('p',{staticClass:"mb-10",class:{
              'text-h5': _vm.$vuetify.breakpoint.xs,
              'text-center': _vm.$vuetify.breakpoint.xs,
              'text-h3': _vm.$vuetify.breakpoint.sm,
              'text-h3': _vm.$vuetify.breakpoint.mdAndUp,
            }},[_vm._v(" 처음부터 완벽한 온라인 과외를 경험했습니다. ")]),_c('p',[_vm._v("placeholder 이미지들")]),_c('v-carousel',{attrs:{"cycle":"","height":"400","hide-delimiter-background":"","show-arrows-on-hover":""}},_vm._l((_vm.items),function(item,i){return _c('v-carousel-item',{key:i,attrs:{"src":item.src,"reverse-transition":"fade-transition","transition":"fade-transition"}})}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }