<template>
    <!--    <div id="faq">-->
    <!--        <h1>자주 묻는 질문</h1>-->
    <!--        <ul>-->
    <!--            <li>-->
    <!--                <input type="checkbox" checked>-->
    <!--                <i></i>-->
    <!--                <div class="" :class="{'question-font': $vuetify.breakpoint.mdAndUp,-->
    <!--                            'mobile-question-font': $vuetify.breakpoint.smAndDown}">-->
    <!--                    <span class="primary&#45;&#45;text">Q. </span>선생님 매칭은 어떻게 되나요?</div>-->
    <!--                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae debitis iusto voluptatum doloribus rem, qui nesciunt labore tempore fugit iste deserunt incidunt error provident repudiandae laudantium quo ipsa unde perspiciatis, nihil autem distinctio! Deserunt, aspernatur.</p>-->
    <!--            </li>-->
    <!--            <li>-->
    <!--                <input type="checkbox" checked>-->
    <!--                <i></i>-->
    <!--                <h2>Hvorfor..?</h2>-->
    <!--                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sapiente quasi, quia provident facere recusandae itaque assumenda fuga veniam dicta earum dolorem architecto facilis nisi pariatur.</p>-->
    <!--            </li>-->
    <!--            <li>-->
    <!--                <input type="checkbox" checked>-->
    <!--                <i></i>-->
    <!--                <h2>Hvad..?</h2>-->
    <!--                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nam quas placeat assumenda mollitia magni consequatur dolorum, quod nihil distinctio aperiam officia alias! Voluptate dolore ex officiis sit, magnam non a, eligendi pariatur aut, earum dolores tenetur ipsam id illo deleniti. Laudantium deserunt eaque ipsam voluptatum consequuntur voluptatibus sed minima ad accusamus debitis eos similique laboriosam, molestiae? Consequatur neque tempore quis. Eligendi, in ut aspernatur esse nesciunt libero.</p>-->
    <!--            </li>-->
    <!--        </ul>-->
    <!--    </div>-->
    <div class="faq-container">
        <nav class="accordion arrows">
            <!--        <header class="box">-->
            <!--            <label for="acc-close" class="box-title">Accordion menu</label>-->
            <!--        </header>-->
            <input type="radio" name="accordion" id="cb1"/>
            <section class="box">
                <label class="box-title" for="cb1"><span class="question-text"
                :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"
                ><span class="primary--text">Q.</span> IVYED는 어떤 점이 좋은가요?</span></label>
                <label class="box-close" for="acc-close"></label>
                <div class="box-content">
                    <span class="font-italic font-weight-bold">ANYWHERE</span><br/>
                    학생과 선생님이 각자 원하는 장소에서 태블릿으로 수업을 진행합니다.
                    <br/>
                    <br/>
                    <span class="font-italic font-weight-bold">TOP CLASS TUTORS</span><br/>
                    아이비리그 및 미국 최상위 명문대 선생님들을 만나 볼 수 있습니다.
                    <br/>
                    <br/>

                    <span class="font-italic font-weight-bold">SMART MATCHING</span><br/>

                    학생의 요청사항을 맞춰 나에게 딱 맞는 선생님을 배정해드립니다.
                </div>
            </section>
            <input type="radio" name="accordion" id="cb2"/>
            <section class="box">
                <label class="box-title" for="cb2"><span class="question-text"
                                                         :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"><span class="primary--text">Q.</span> 선생님 매칭은 어떻게 되나요?</span></label>
                <label class="box-close" for="acc-close"></label>
                <div class="box-content">
                    선생님 매칭은 '과외신청서' 제출이 완료되면 시작됩니다!
                    <br/>
                    <br/>
                    수강신청 후 '과외신청서'를 작성할 때, 원하는 선생님 스타일과 학생의 정보 (재학중인 학교, 희망 전공 계열, 선호 수업시간대 등)을 꼼꼼히 작성해주시면 학생에게 맞는 최적의 선생님을 매칭해 드리고있습니다.
                    <br/>
                    <br/>
                    선생님 매칭은 신청서 제출 후 영업일 기준 3일 정도 소요됩니다.
                    <br/>
                    <br/>
                    단, 수업 가능 시간대가 너무 적거나 매칭이 어려운 조건으로 요청하실 경우 매칭기간이 좀 더 소요될 수 있으며, 이 경우 사전에 안내를 드리고 있습니다.
                </div>
            </section>
            <input type="radio" name="accordion" id="cb3"/>
            <section class="box">
                <label class="box-title" for="cb3"><span class="question-text"
                                                         :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"><span class="primary--text">Q.</span> 과외 횟수나 시간을 선택할 수 있나요?</span></label>
                <label class="box-close" for="acc-close"></label>
                <div class="box-content hidden-md-and-up">
                    IVYED의 과외 선생님들은 <br/>Harvard, Stanford, Yale, MIT 등 <br/>미국 상위 1% 대학교 출신의 재학, 휴학, <br/> 졸업하신 선생님들로 이루어져 있습니다.
                    <br/>이 최소 조건 외에도 과외 경력과 교습능력 <br/>
                    등을 꼼꼼히 평가받으신 분들로 구성되어 있습니다.
                    <br/>
                    <br/>
                    재학생 선생님은 입시를 최근에 경험하셔서 입시 관련 상담까지 일부 도와드릴 수 있는 장점이 있고, 졸업생 선생님은 좀 더 교수 경험이 풍부하다는 장점이 있습니다.😊
                </div>
                <div class="box-content hidden-sm-and-down">
                    IVYED의 과외 선생님들은 Harvard, Stanford, Yale, MIT 등 미국 상위 1% 대학교 출신의 재학, 휴학, 졸업하신 선생님들로 이루어져 있습니다.<br/><br/>
                    이 최소 조건 외에도 과외 경력과 교습능력 등을 꼼꼼히 평가받으신 분들로 구성되어 있습니다.<br/><br/>

                    재학생 선생님은 입시를 최근에 경험하셔서 입시 관련 상담까지 일부 도와드릴 수 있는 장점이 있고, 졸업생 선생님은 좀 더 교수 경험이 풍부하다는 장점이 있습니다.😊
                </div>

            </section>

            <input type="radio" name="accordion" id="cb4"/>
            <section class="box">
                <label class="box-title" for="cb4"><span class="question-text"
                                                         :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"><span class="primary--text">Q.</span> 선생님을 중간에 바꿀 수 있나요?</span></label>
                <label class="box-close" for="acc-close"></label>
                <div class="box-content hidden-md-and-up">
                    🙆🏻 ‍️IVYED는 학생이 <br/>'과외신청서'(수강신청 후 작성 가능)에
                    <br/>적어주신 내용을 반영하여 선생님을 매칭해 드리고 있습니다!
                    <br/><br/>매칭된 선생님과 수업 진행 중, 선생님 변경이 필요하신 경우 월 1회까지 선생님 변경 요청이 가능합니다.

                    <br/><br/>단, 재매칭이 진행되는 동안 기존 수업은 진행되지 않습니다.
                    IVYED는 횟수제 수업으로 새로운 선생님 매칭 이후 남은 수업을 진행해 주시면 됩니다! 😀
                </div>
                <div class="box-content hidden-sm-and-down">
                    🙆🏻‍️IVYED는 학생이 '과외신청서'(수강신청 후 작성 가능)에 적어주신 내용을 반영하여 선생님을 매칭해 드리고 있습니다!<br/><br/>
                    매칭된 선생님과 수업 진행 중, 선생님 변경이 필요하신 경우 월 1회까지 선생님 변경 요청이 가능합니다.<br/><br/>

                    단, 재매칭이 진행되는 동안 기존 수업은 진행되지 않습니다.<br/>
                    IVYED는 횟수제 수업으로 새로운 선생님 매칭 이후 남은 수업을 진행해 주시면 됩니다! 😀
                </div>
                <br/>
                <br/>
            </section>

        </nav>
        <div class="text-center pt-4">
            <a target="_blank" :class="{

                    }" :href="'https://ivyed.notion.site/IVYED-FAQ-3d409c7775994ad598ca418c3ad833e6'">  자주 묻는 질문 더 보기 </a>

        </div>

    </div>
</template>

<script>
    export default {
        name: "FaqTemplate"
    }
</script>

<style scoped>
    body {
        height: calc(100% - 20px);
        width: calc(100% - 20px);
        margin: 0;
        padding: 10px;
        display: flex;
        background: #f2f2f2;
        color: rgba(0, 0, 0, .87);
        font-family: 'Roboto', sans-serif;
    }

    .accordion {
        margin: auto;
        max-width: 600px;
    }

    .accordion input {
        display: none;
    }

    .box {
        /*border-radius: 1;*/
        vertical-align: center;
        position: relative;
        background: white;
        height: 52px;
        margin-bottom: 20px;
        transition: all .15s ease-in-out;
    }

    .box::before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        pointer-events: none;
        vertical-align: center;
        box-shadow: 0 -1px 0 #e5e5e5, 0 0 2px rgba(0, 0, 0, .12), 0 2px 4px rgba(0, 0, 0, .24);
    }

    header.box {
        background: #00BCD4;
        z-index: 100;
        cursor: initial;
        box-shadow: 0 -1px 0 #e5e5e5, 0 0 2px -2px rgba(0, 0, 0, .12), 0 2px 4px -4px rgba(0, 0, 0, .24);
    }

    header .box-title {
        margin: 0;
        font-weight: normal;
        font-size: 16pt;
        color: white;
        cursor: initial;
    }

    .box-title {
        /*width: calc(100% - 40px);*/
        height: 64px;
        line-height: 52px;
        /*vertical-align: center;*/
        padding: 0 20px;
        /*display: inline-block;*/
        cursor: pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .box-content {
        /*width: calc(100% - 40px);*/
        padding: 30px 20px;
        font-size: 12pt;
        /*color: rgba(0,0,0,.54);*/
        display: none;
    }

    .box-close {
        position: absolute;
        height: 64px;
        width: 100%;
        top: 0;
        left: 0;
        cursor: pointer;
        display: none;
    }

    input:checked + .box {
        height: auto;
        margin: 16px 0;
        box-shadow: 0 0 6px rgba(0, 0, 0, .16), 0 6px 12px rgba(0, 0, 0, .32);
    }

    input:checked + .box .box-title {
        /*border-bottom: 1px solid rgba(0, 0, 0, .18);*/
    }

    input:checked + .box .box-content,
    input:checked + .box .box-close {
        display: inline-block;
    }

    .arrows section .box-title {
        padding-left: 44px;
        width: calc(100% - 64px);
    }

    .arrows section .box-title:before {
        position: absolute;
        display: block;
        content: '\203a';
        /*vertical-align: center;*/
        font-size: 18pt;
        left: 20px;
        top: -2px;
        transition: transform .15s ease-in-out;
        color: rgba(0, 0, 0, .54);
    }

    input:checked + section.box .box-title:before {
        transform: rotate(90deg);
    }

    .question-text {
        font-weight: bold;
    }
    .question-text-mobile {
        font-weight: bold;
        font-size: 14px;
    }
    .faq-container {
        height: 100%;
    }
</style>
