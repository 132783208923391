const info = {
    home:
        {
            main_description: 'IVY 출신',
            secondary_description: '지금 바로 가입하세요!',
            selling_points: {
                first: "필요한 유학 과외선생님을 찾는데 얼마나 걸리셨나요?",
                answer_001: "더 이상 공부를 도와줄 선생님을 찾는데 시간 낭비 하지 마세요!",
                answer_002: "요청 후 2일안에 학생에게 딱 필요한 선생님을 찾아드려요."
            },
            page_three: {
                title: "성적 관리에만 집중 하세요!",
                subtitle: "이동시간 없이 100% 비대면 과외",
            }
        },
    logo_name: "IVyED",
    policies: {
        termsOfUse: "https://ivyed.notion.site/b186e716ffd443acbccc5ce45717e762",
        faq: "https://ivyed.notion.site/IVYED-FAQ-3d409c7775994ad598ca418c3ad833e6",
        personalInfoHandling: "https://ivyed.notion.site/53679ae3be6d4aad87f2d32438444eaf",
        personalInfoCollect: "https://ivyed.notion.site/6d4248d37ff64ac3bd7f5c553763f5c9",
        marketing: "https://ivyed.notion.site/f25259f37bfd426690222952f5dd5110",
        thirdParty: "https://ivyed.notion.site/3-31aea9057e5f479b91681e5c17b29cfe",
        refundTerms: "",
    },
    faq: {
        topics: [
            '학생', '선생님', '결제', '화이트보드', '수업문의',
        ],
        qa: [
            {
                title: '매칭 된 선생님과는 어떻게 수업을 진행하나요?',
                value: 'Hello World',
                category: 'lessonInquiry',
            },
            {
                title: '과외비는 어떤 주기로 정산 받나요?',
                value:
                    'No money fo you',
                category:
                    'teacher',
            }
            ,
            {
                title: '학생이 수업에 들어오질 않았어요.. :(',
                value:
                    'Yo still get paid. no worries',
                category:
                    'teacher',
            }
            ,
            {
                title: '결제 후 바로 수업 진행이 가능한가요?',
                value:
                    'No mang, yo gotta wait a year',
                category:
                    'payment',
            }
            ,
            {
                title: '아이패드 안주나요?',
                value:
                    'Give us some time',
                category:
                    'lessonInquiry',
            }
            ,
            {
                title: '얼마에요?',
                value:
                    'Give us some time',
                category:
                    'student',
            },
        ],
    },
    faqContent: {
        teacher:
            [
                {
                    title: '과외비는 어떤 주기로 정산 받나요?',
                    value: 'No money fo you',
                    category: '선생님',
                },
                {
                    title: '학생이 수업에 들어오질 않았어요.. :(',
                    value: 'Yo still get paid. no worries',
                    category: '선생님',
                },
            ],
        payment:
            [
                {
                    title: '결제 후 바로 수업 진행이 가능한가요?',
                    value: 'No mang, yo gotta wait a year',
                    category: '결제/환불',
                },
            ],
        student:
            [
                {
                    title: '아이패드 안주나요?',
                    value: 'Give us some time',
                    category: '기타',
                },
                {
                    title: '얼마에요?',
                    value: 'Give us some time',
                    category: '기타',
                }
            ],
        etc:
            [
                {
                    title: '매칭 된 선생님과는 어떻게 수업을 진행하나요?',
                    value: 'Hello World',
                    category: '수업 방식',
                },
            ]


    },
    teacherRegister: {
        highestLevelOfEducation: [
            "학부 재학중 (휴학 포함)",
            "학부 졸업",
            "대학원 재학중",
            "석사 졸업",
            "박사 졸업"
        ],
        subjectsOfExpertise:
            ['Computer Science', 'Mathematics', 'English Literature', 'Statistics', 'Writing', 'SAT', 'SAT2'],
        preferredLessonStyles: [
            '문제/과제풀이 위주',
            '개념 정리 위주'
        ],
        preferredLessonMaterials: [
            '학생의 학교 숙제, 교과서 위주',
            '선생님의 추천 및 준비 교재'
        ],
        preferredCoverMaterial: [
            '학교 수업 및 과제 보충',
            '입시 시험 대비'
        ],

    },
    preferredLessonTimeSlots: [
        "00~04시",
        "04~08시",
        "08~12시",
        "12~16시",
        "16~20시",
        "20~24시"
    ],
    preferredLessonTimes: [
        '오전',
        '오후',
        '저녁 이후'
    ],
    preferredLessonDays: [
        '월',
        '화',
        '수',
        '목',
        '금',
        '토',
        '일',
    ],
    demandingHoursPerWeek: [
        "1~2시간",
        "3~4시간",
        "5~8시간",
        "8시간~12시간",
        "최대한 많이",
    ],

    demandingTotalDuration: [
        '1개월',
        '2개월',
        '3개월',
        '4개월',
        '5개월',
        '6개월',
        '7개월',
        '8개월',
        '9개월',
        '10개월',
        '11개월',
        '12개월'
    ],
    countryItems: [
        '대한민국',
        '미국',
        '호주',
        '영국',
        '캐나다',
        '뉴질랜드',
        '중국',
        '일본',
        '싱가포르',
        '인도네시아',
        '직접입력',
    ],
    countryCodes: [
        "Australia +61",
        "USA / Canada +1",
        "China +86",
        "Guam +1-671",
        "Hong Kong +852",
        "Singapore +65",
        "South Korea +82",
        "United Kingdom +44",
        "직접 입력"],


    mainSubjects: [
        "Mathematics",
        "Science",
    ],
    scienceSubSubjects: [
        // "Elementary school",
        "AP Physics 1",
        "AP Physics 2",
        "AP Physics C Mech",
        "AP Physics C E&M",
        "Physics",
        "AP Chemistry",
        "Chemistry",
        "AP Biology",
        "Biology",
        "AP Environmental Science",
        "Astronomy",
        "Environmental Science",
        "Science (Middle School)",
    ],
    mathSubSubjects: [
        // "Middle school",
        // // "Elementary school",
        // "Algebra",
        // "Geometry",
        // "Pre-calculus",
        // "Calculus",
        // "AP Calc AB",
        // "AP Calc BC",
        // "Statistics",
        // "AP Statistics",
        // "Multi-variate Calculus",
        // "AP CS Principles",
        // "AP CS A",
        // "SAT"
        "AP CS Principles",
        "AP CS A",
        "Programming",
        "Robotics",
        "Multi-variate Calculus",
        "AP Calc AB",
        "AP Calc BC",
        "Calculus",
        "Pre-calculus",
        "Algebra",
        "Geometry",
        "AP Statistics",
        "Statistics",
        "SAT",
        "Math (Middle School)",
    ],
    englishSubSubjects: [
        "AP Literature",
        "AP Language",
        "High school ",
        "Middle school",
        // "Elementary school",
        "Essay Writing",
        "Speech/Debate",
        "SAT Reading/Writing"
    ],

    availableDays: [
        "월요일",
        "화요일",
        "수요일",
        "목요일",
        "금요일",
        "토요일",
        "일요일",

    ],
    timeSlots:[
        "오전",
        "오후",
    ],
    financingOptions:[
        '일시불',
        '1개월',
        '2개월',
        '3개월',
        '4개월',
        '5개월',
        '6개월',
        '7개월',
        '8개월',
        '9개월',
        '10개월',
        '11개월',
        '12개월',],

    gradSchoolType: [
        '석사',
        '박사',
        '로스쿨',
        '의대',
        '치대'
    ],
    possibleHighSchoolTypes: [
        "통학 / Day-school",
        "기숙 / Boarding School",
        "외국인/국제학교",
        "외국어고등학교",
        "과학고등학교",
        "홈스쿨링",
    ],

    desiredMajors: [
        "Natural Science",
        "Engineering",
        "Business/Econ",
        "Arts",
        "Language",
        "Education",
        "기타"
    ],

    americanUniversities: [
        "직접 입력",
        "Amherst College",
        "Barnard College",
        "Bates College",
        "Boston College",
        "Boston University",
        "Bowdoin College",
        "Brandeis University",
        "Brown University",
        "California Institute of Technology",
        "Carleton College",
        "Carleton College",
        "Carnegie Mellon University",
        "Case Western Reserve University",
        "Claremont McKenna College",
        "Colby College",
        "Colgate University",
        "Colorado College",
        "Columbia University",
        "Cornell University",
        "Dartmouth College",
        "Davidson College",
        "Duke University",
        "Emory University",
        "Georgetown University",
        "Georgia Institute of Technology",
        "Grinnell College",
        "Hamilton College",
        "Harvard University",
        "Harvey Mudd College",
        "Haverford College",
        "Johns Hopkins University",
        "Lehigh University",
        "Macalester College",
        "Massachusetts Institute of Technology",
        "Middlebury College",
        "New York University",
        "Northwestern University",
        "Pomona College",
        "Princeton University",
        "Rice University",
        "Smith College",
        "Stanford University",
        "Swarthmore College",
        "Tufts University",
        "Tulane University",
        "University of California Berkeley",
        "University of California Davis",
        "University of California Irvine",
        "University of California Los Angeles",
        "University of California San Diego",
        "University of California Santa Barbara",
        "University of Chicago",
        "University of Florida",
        "University of Georgia",
        "University of Illinois Urbana Champaign",
        "University of Michigan Ann Arbor",
        "University of North Carolina Chapel Hill",
        "University of Notre Dame",
        "University of Pennsylvania",
        "University of Richmond",
        "University of Rochester",
        "University of Southern California",
        "University of Texas at Austin",
        "University of Virginia",
        "University of Wisconsin Madison",
        "Vanderbilt University",
        "Vassar College",
        "Wake Forest University",
        "Washington and Lee University",
        "Washington University in St. Louis",
        "Wellesley College",
        "Wesleyan University",
        "William & Mary",
        "Williams College",
        "Yale University",
    ],
    urls: {
        staging: "https://still-beyond-52824.herokuapp.com/api/",
        production: "https://backend-ie.herokuapp.com/api/",
        local: "localhost:8080/api/",
    }

};

export default info;
