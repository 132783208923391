<template>

    <v-container class="enroll-page py-10">
        <div class="semi-background">
        <v-container v-if="!this.currentUser" class="">
            <v-card
                    class="mx-auto primary--text"
                    width="100%"
                    flat
                    :class="{
                                // 'mt-5': $vuetify.breakpoint.lgAndUp,
                                // 'ml-5': $vuetify.breakpoint.lgAndUp,
                                // 'mb-5': $vuetify.breakpoint.lgAndUp,
                                }"
            >
                <v-card-title class="main-title" :class="{}">
                    수강 신청
                </v-card-title>
                <div class="ml-5 mr-5 mb-5">
                                <span
                                        :class="{
                                // 'text-sub': $vuetify.breakpoint.mdAndDown,
                                // 'text-h5': $vuetify.breakpoint.lgAndUp
                                }"
                                        class="black--text"
                                >
                                    수업 사항을 선택하여 주세요.
                                </span>
                </div>
                <v-divider></v-divider>
                <template>
                    <v-form class="ml-5 mr-5 mt-5 mb-5"
                            ref="form"
                            v-model="valid"
                            lazy-validation>
                                    <span class="subtitle-text">
                                        수업 과목 선택
                                    </span>
                        <v-row>
                            <v-col cols="12">
                                <v-radio-group
                                        v-model="enrollmentData.subjectsOfInterest"
                                        :rules="[rules.required, rules.listNotEmpty]"

                                >
                                    <v-radio
                                            label="Math / Computer Science"
                                            value="Mathematics"
                                    ></v-radio>
                                    <v-select
                                            :rules="[rules.required, rules.listNotEmpty]"
                                            :items="mathSubSubjects"
                                            v-model="enrollmentData.subSubjects"
                                            placeholder="세부 과목을 선택해주세요."
                                            v-if="enrollmentData.subjectsOfInterest=='Mathematics'">


                                    </v-select>
                                    <v-radio
                                            label="Science"
                                            value="Science"
                                    >
                                    </v-radio>
                                    <v-select
                                            :rules="[rules.required, rules.listNotEmpty]"
                                            :items="scienceSubSubjects"
                                            v-model="enrollmentData.subSubjects"
                                            v-if="enrollmentData.subjectsOfInterest=='Science'"
                                            placeholder="세부 과목을 선택해주세요.">

                                    </v-select>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-divider class="mb-2"></v-divider>
                        <span class="subtitle-text">
                                        수업 횟수
                                    </span>
                        <v-row>
                            <v-col cols="12">
                                <v-radio-group
                                        v-model="enrollmentData.expectedNumberOfLessonsPerWeek"
                                        column
                                        :rules="[rules.required, rules.listNotEmpty]"
                                >
                                    <v-radio
                                            label="주 1회"
                                            value="주 1회"
                                    ></v-radio>
                                    <v-radio
                                            label="주 2회"
                                            value="주 2회"
                                    ></v-radio>
                                    <v-radio
                                            label="주 3회"
                                            value="주 3회"
                                    ></v-radio>
                                    <v-radio
                                            label="주 5회"
                                            value="주 5회"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-divider class="mb-2"></v-divider>
                        <span class="subtitle-text">
                                        수업 기간 선택
                                    </span>
                        <v-row>
                            <v-col cols="12">
                                <v-radio-group
                                        :rules="[rules.required, rules.listNotEmpty]"
                                        v-model="enrollmentData.expectedLessonDuration"
                                        column

                                >
                                    <v-radio
                                            label="1개월"
                                            value="1개월"
                                    >
                                        <template v-slot:label>
                                            <div>1개월
                                                &nbsp;
                                                <span class="highlight-container"><span class="highlight">20% 할인</span></span>
                                            </div>
                                        </template>
                                    </v-radio>
                                    <v-radio
                                            label="3개월"
                                            value="3개월"
                                    >
                                        <template v-slot:label>
                                            <div>3개월
                                                &nbsp;
                                                <span class="highlight-container"><span class="highlight">28% 할인</span></span>
                                            </div>
                                        </template>
                                    </v-radio>
                                    <v-radio
                                            label="12개월"
                                            value="12개월"
                                    >
                                        <template v-slot:label>
                                            <div>12개월
                                                &nbsp;
                                                <span class="highlight-container"><span class="highlight">40% 할인</span></span>
                                            </div>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>

                    </v-form>
                </template>
            </v-card>
            <hr/>
            <v-card class="center-horizontal mx-auto mt-2 enroll-page"
                    elevation="0"
                    width="100%" >
                <v-row class="pb-5">

                    <v-col class="center-vertical  mt-4" md="">
                        <div class="smaller-desc center-horizontal" v-if="virtualPriceToPay != ''">
                            <!--                                        <span class="text-next-to-button-01">최대 40% 할인</span>-->
                            <span class="highlight-container"><span class="highlight"><strong>{{selectedDiscountRate}}</strong> 할인</span></span>
                            <span class="font-weight-bold">
                                            {{virtualPriceToPay}}
                                        </span>
                            <span class="text-next-to-button-03">{{enrollmentData.expectedNumberOfLessonsPerWeek}}(60분)•{{enrollmentData.expectedLessonDuration}}</span>
                        </div>
                        <div class="smaller-desc center-horizontal" v-if="virtualPriceToPay == ''">
                            <span class=" text-next-to-button-01">최대 40% 할인</span>

                            <span class="text-next-to-button-02">
                                            수업 정보를 <br/>선택하세요.
                                        </span>
                        </div>
                    </v-col>
                    <v-col  class="center-vertical mt-4">
                        <v-btn
                                class=" rounded-lg"
                                :disabled="!valid"
                                color="secondary"
                                @click="updateEnrollmentDataToStore"
                                height="70px"
                                x-large


                        >
                            <button class="">
                                <h3 class="">수업 결제하기</h3>
                            </button>

                        </v-btn>

                    </v-col>
                </v-row>
            </v-card>

        </v-container>

        <!-- IF REGISTED as a member BUT DOES NOT HAVE ACTIVE PAYMENT but has NOT filled out the enrollment form-->
        <v-container class="" v-if="this.currentUser && studentContent.activePaymentId == null && studentContent.subjectsOfInterest == null">

            <v-card
                    class="mx-auto primary--text"
                    max-width="100%"
                    flat
                    :class="{
                                // 'mt-5': $vuetify.breakpoint.lgAndUp,
                                // 'ml-5': $vuetify.breakpoint.lgAndUp,
                                // 'mb-5': $vuetify.breakpoint.lgAndUp,
                                }"
            >
                <v-card-title class="main-title"
                              :class="{
                                'text-h4': $vuetify.breakpoint.lgAndUp,
                                'text-h5': $vuetify.breakpoint.mdAndDown,
                                // 'ml-5': $vuetify.breakpoint.lgAndUp,
                                // 'mb-5': $vuetify.breakpoint.lgAndUp,
                                }"> 수강 신청
                </v-card-title>
                <div class="ml-5 mt-5 mr-5 mb-5">
                                <span
                                        :class="{
                                // 'text-sub': $vuetify.breakpoint.mdAndDown,
                                'text-h5': $vuetify.breakpoint.lgAndUp
                                }"
                                        class="black--text"
                                >
                                    아래에서 <strong>{{currentUser.firstName}}</strong>님이 원하시는 <br/> 수업 사항을 선택 후, 결제를 진행해주세요.
                                </span>

                </div>
                <v-divider></v-divider>

                <template>
                    <v-form class="ml-5 mr-5 mt-5 mb-5 black--text"
                            ref="form"
                            v-model="valid"
                            lazy-validation
                    >
                                    <span class="subtitle-text">
<!--                                            :class="{-->
                                        <!--                                    'text-h4': $vuetify.breakpoint.mdAndDown,-->
                                        <!--                                    'text-h4': $vuetify.breakpoint.lgAndUp-->

                                        <!--                                    }"-->

                                        수업 과목 선택
                                    </span>
                        <v-row>
                            <v-col cols="12">
                                <v-radio-group
                                        v-model="enrollmentData.subjectsOfInterest"

                                >
                                    <v-radio
                                            label="Math / Computer Science"
                                            value="Mathematics"
                                            :rules="[rules.required]"
                                    ></v-radio>
                                    <v-select
                                            :items="mathSubSubjects"
                                            v-model="enrollmentData.subSubjects"
                                            placeholder="세부 과목을 선택해주세요."
                                            :rules="[rules.required]"
                                            v-if="enrollmentData.subjectsOfInterest=='Mathematics'">


                                    </v-select>
                                    <v-radio
                                            label="Science"
                                            value="Science"
                                    >
                                    </v-radio>
                                    <v-select
                                            :items="scienceSubSubjects"
                                            v-model="enrollmentData.subSubjects"
                                            v-if="enrollmentData.subjectsOfInterest=='Science'"
                                            :rules="[rules.required]"
                                            placeholder="세부 과목을 선택해주세요.">

                                    </v-select>

                                    <!--                                    <v-radio-->
                                    <!--                                            label="English"-->
                                    <!--                                            value="English"-->
                                    <!--                                    ></v-radio>-->
                                    <!--                                    <v-select-->
                                    <!--                                            :items="englishSubSubjects"-->
                                    <!--                                            v-model="enrollmentData.subSubjects"-->
                                    <!--                                            v-if="enrollmentData.subjectsOfInterest=='English'"-->
                                    <!--                                              placeholder="세부 과목을 선택해주세요.">-->


                                    <!--                                    </v-select>-->
                                </v-radio-group>
                                <!--                                            {{enrollmentData.lessonCategory}}-->
                                <!--                                            {{enrollmentData.lessonSubCateogry}}-->


                            </v-col>
                        </v-row>
                        <v-divider class="mb-2"></v-divider>
                        <span class="subtitle-text">
                                        수업 횟수
                                    </span>
                        <v-row>
                            <v-col cols="12">
                                <v-radio-group
                                        v-model="enrollmentData.expectedNumberOfLessonsPerWeek"
                                        column

                                >
                                    <v-radio
                                            label="주 1회"
                                            value="주 1회"
                                    ></v-radio>
                                    <v-radio
                                            label="주 2회"
                                            value="주 2회"
                                    ></v-radio>
                                    <v-radio
                                            label="주 3회"
                                            value="주 3회"
                                    ></v-radio>
                                    <v-radio
                                            label="주 5회"
                                            value="주 5회"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-divider class="mb-2"></v-divider>
                        <span class="subtitle-text">
                                        수업 기간 선택
                                    </span>
                        <v-row>
                            <v-col cols="12">
                                <v-radio-group

                                        v-model="enrollmentData.expectedLessonDuration"
                                        column
                                >
                                    <v-radio
                                            label="1개월"
                                            value="1개월"
                                    >
                                        <template v-slot:label>
                                            <div>1개월
                                                &nbsp;
                                                <span class="highlight-container"><span class="highlight">20% 할인</span></span>
                                            </div>
                                        </template>

                                    </v-radio>
                                    <v-radio
                                            label="3개월"
                                            value="3개월"
                                    >
                                        <template v-slot:label>
                                            <div>3개월
                                                &nbsp;
                                                <span class="highlight-container"><span class="highlight">28% 할인</span></span>
                                            </div>
                                        </template>

                                    </v-radio>
                                    <v-radio
                                            label="12개월"
                                            value="12개월"
                                    >
                                        <template v-slot:label>
                                            <div>12개월
                                                &nbsp;
                                                <span class="highlight-container"><span class="highlight">40% 할인</span></span>
                                            </div>
                                        </template>

                                    </v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>

                    </v-form>
                </template>
            </v-card>
            <hr/>
            <v-card class="center-horizontal mx-auto enroll-page mt-2"
                    elevation="0"
                    width="100%" >
                <v-row class="pb-5">

                    <v-col class="center-vertical  mt-4" md="">
                        <div class="smaller-desc center-horizontal" v-if="virtualPriceToPay != ''">
                            <!--                                        <span class="text-next-to-button-01">최대 40% 할인</span>-->
                            <span class="highlight-container"><span class="highlight"><strong>{{selectedDiscountRate}}</strong> 할인</span></span>
                            <span class="font-weight-bold">
                                            {{virtualPriceToPay}}
                                        </span>
                            <span class="text-next-to-button-03">{{enrollmentData.expectedNumberOfLessonsPerWeek}}(60분)•{{enrollmentData.expectedLessonDuration}}</span>
                        </div>
                        <div class="smaller-desc center-horizontal" v-if="virtualPriceToPay == ''">
                            <span class=" text-next-to-button-01">최대 40% 할인</span>

                            <span class="text-next-to-button-02">
                                            수업 정보를 <br/>선택하세요.
                                        </span>
                        </div>
                    </v-col>
                    <v-col  class="center-vertical mt-4">
                        <v-btn
                                class=" rounded-lg"
                                :disabled="!valid"
                                color="secondary"
                                @click="updateEnrollmentDataAndMoveToPayment(currentUser.id, enrollmentData)"
                                height="70px"
                                x-large


                        >
                            <button class="">
                                <h3 class="">수업 결제하기</h3>
                            </button>

                        </v-btn>

                    </v-col>
                </v-row>
            </v-card>


        </v-container>

        <!-- IF REGISTED as a member BUT DOES NOT HAVE ACTIVE PAYMENT but has filled out the enrollment form-->
        <v-container v-if="this.currentUser
        && studentContent.activePaymentId == null
        && studentContent.subjectsOfInterest != null">
            <v-card
                    class="mx-auto primary--text"
                    max-width="750"
                    flat
                    :class="{
                                // 'mt-5': $vuetify.breakpoint.lgAndUp,
                                // 'ml-5': $vuetify.breakpoint.lgAndUp,
                                // 'mb-5': $vuetify.breakpoint.lgAndUp,
                                }"
            >
                <v-card-title class="main-title"> 수강 신청</v-card-title>
                <div class="ml-5 mt-5 mr-5 mb-5">
                                <span
                                        :class="{
                                // 'text-sub': $vuetify.breakpoint.mdAndDown,
                                'text-h5': $vuetify.breakpoint.lgAndUp
                                }"
                                        class="black--text"
                                >
                                    아래에서 <strong>{{currentUser.firstName}}</strong>님이 원하시는 <br/> 수업 사항을 선택 후, 결제를 진행해주세요.
                                </span>
                    <!--                    <h1> 아직 아이비에드에 학생으로 등록하지 않으셨군요! </h1>-->
                    <!--                    <h1> 등록해라!! 돈내라!! </h1>-->
                    <!--                    <v-divider></v-divider>-->
                    <!--                    <h1> 학생 수업관련 입력 후 결제 하는 페이지로 이동</h1>-->
                </div>
                <!--            <h2>{{currentUser}}</h2>-->
                <!--            <v-divider></v-divider>-->
                <!--    <h1> {{studentContent}} </h1>-->
                <template>

                    <v-form class="ml-5 mr-5 mt-5 mb-5"
                            ref="form"
                            v-model="valid"
                            lazy-validation
                    >
                        <!--                    <v-text-field-->
                        <!--                            v-model="formContents.subjects"-->
                        <!--                            :counter="100"-->
                        <!--                            :rules="nameRules"-->
                        <!--                            label="어떤 과목들 도움이 필요하신가요?"-->
                        <!--                            required-->
                        <!--                    ></v-text-field>-->
                        <span class="subtitle-text">
                                        수업 과목 선택
                                    </span>
                        <!--                        {{this.studentContent}}-->
                        <v-row>
                            <v-col cols="12">
                                <v-radio-group
                                        v-model="studentContent.subjectsOfInterest"
                                >
                                    <v-radio
                                            label="Math / Computer Science"
                                            value="Mathematics"
                                    ></v-radio>
                                    <v-select
                                            :items="mathSubSubjects"
                                            v-model="studentContent.subSubjects"
                                            placeholder="세부 과목을 선택해주세요."
                                            v-if="studentContent.subjectsOfInterest=='Mathematics'">


                                    </v-select>
                                    <v-radio
                                            label="Science"
                                            value="Science"
                                    >
                                    </v-radio>
                                    <v-select
                                            :items="scienceSubSubjects"
                                            v-model="studentContent.subSubjects"
                                            v-if="studentContent.subjectsOfInterest=='Science'"
                                            placeholder="세부 과목을 선택해주세요.">

                                    </v-select>

                                    <!--                                    <v-radio-->
                                    <!--                                            label="English"-->
                                    <!--                                            value="English"-->
                                    <!--                                    ></v-radio>-->
                                    <!--                                    <v-select v-if="studentContent.subjectsOfInterest=='English'"-->
                                    <!--                                              placeholder="세부 과목을 선택해주세요.">-->


                                    <!--                                    </v-select>-->
                                </v-radio-group>
                                <!--                                            {{enrollmentData.lessonCategory}}-->
                                <!--                                            {{enrollmentData.lessonSubCateogry}}-->


                            </v-col>
                        </v-row>
                        <v-divider class="mb-2"></v-divider>
                        <span :class="{
                                    'text-h6': $vuetify.breakpoint.mdAndDown,
                                    'text-h6': $vuetify.breakpoint.lgAndUp
                                    }">
                                        수업 횟수
                                    </span>
                        <v-row>
                            <v-col cols="12">
                                <v-radio-group
                                        v-model="studentContent.expectedNumberOfLessonsPerWeek"
                                        column
                                >
                                    <v-radio
                                            label="주 1회"
                                            value="주 1회"
                                    ></v-radio>
                                    <v-radio
                                            label="주 2회"
                                            value="주 2회"
                                    ></v-radio>
                                    <v-radio
                                            label="주 3회"
                                            value="주 3회"
                                    ></v-radio>
                                    <v-radio
                                            label="주 5회"
                                            value="주 5회"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-divider class="mb-2"></v-divider>

                        <span :class="{
                                    'text-h6': $vuetify.breakpoint.mdAndDown,
                                    'text-h6': $vuetify.breakpoint.lgAndUp
                                    }">
                                        수업 기간 선택
                                    </span>
                        <v-row>
                            <v-col cols="12">
                                <v-radio-group
                                        v-model="studentContent.expectedLessonDuration"
                                        column
                                >
                                    <v-radio
                                            label="1개월"
                                            value="1개월"
                                    >
                                        <template v-slot:label>
                                            <div>1개월
                                                &nbsp;
                                                <span class="highlight-container"><span class="highlight">20% 할인</span></span>
                                            </div>
                                        </template>

                                    </v-radio>
                                    <v-radio
                                            label="3개월"
                                            value="3개월"
                                    >
                                        <template v-slot:label>
                                            <div>3개월
                                                &nbsp;
                                                <span class="highlight-container"><span class="highlight">28% 할인</span></span>
                                            </div>
                                        </template>

                                    </v-radio>
                                    <v-radio
                                            label="12개월"
                                            value="12개월"
                                    >
                                        <template v-slot:label>
                                            <div>12개월
                                                &nbsp;
                                                <span class="highlight-container"><span class="highlight">40% 할인</span></span>
                                            </div>
                                        </template>

                                    </v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>

                        <!--                        <span :class="{-->
                        <!--                                    'text-h6': $vuetify.breakpoint.mdAndDown,-->
                        <!--                                    'text-h6': $vuetify.breakpoint.lgAndUp-->
                        <!--                                    }">-->
                        <!--                                        태블릿-->
                        <!--                                    </span>-->
                        <!--                        <v-row>-->
                        <!--                            <v-col cols="12">-->
                        <!--                                <v-radio-group-->
                        <!--                                        v-model="studentContent.tablet"-->
                        <!--                                        column-->
                        <!--                                >-->
                        <!--                                    <v-radio-->
                        <!--                                            label="대여 할래요"-->
                        <!--                                            value="Yes"-->
                        <!--                                    ></v-radio>-->
                        <!--                                    <v-radio-->
                        <!--                                            label="괜찮아요. 이미 있어요"-->
                        <!--                                            value="No"-->
                        <!--                                    ></v-radio>-->
                        <!--                                </v-radio-group>-->
                        <!--                            </v-col>-->
                        <!--                        </v-row>-->

                        <!--                        {{}}-->
                        <!--                        <v-btn-->
                        <!--                                :disabled="!valid"-->
                        <!--                                color="primary"-->
                        <!--                                class="mr-4"-->
                        <!--                                @click="updateEnrollmentDataAndMoveToPayment(currentUser.id, studentContent)"-->
                        <!--                        >-->
                        <!--                            수업 결제하기-->
                        <!--                        </v-btn>-->
<!--                        <div class="text-center">-->
<!--                            <v-row class="pb-5">-->
<!--                                <v-col>-->
<!--                                    <v-btn-->
<!--                                            class="ma-1"-->
<!--                                            :disabled="!valid"-->
<!--                                            color="secondary"-->
<!--                                            @click="updateEnrollmentDataAndMoveToPayment(currentUser.id, studentContent)"-->
<!--                                            height="100%"-->
<!--                                            x-large-->

<!--                                    >-->
<!--                                        <button class="">-->
<!--                                            <h3 class="">수업 결제하기</h3>-->
<!--                                            <hr>-->

<!--                                            &lt;!&ndash;                                            <span class="smaller-desc" v-if="virtualPriceToPay == ''">지금 가입하고 할인받기</span><br/>&ndash;&gt;-->
<!--                                            <span class="smaller-desc">{{virtualPriceToPayForEditing}}</span><br/>-->
<!--                                            <span class="smaller-desc" v-if="virtualPriceToPayForEditing != ''">{{studentContent.expectedNumberOfLessonsPerWeek}}·{{studentContent.expectedLessonDuration}}</span>-->
<!--                                            &lt;!&ndash;                                            <span class="smaller-desc">{{virtualPrice}}</span>&ndash;&gt;-->
<!--                                            &lt;!&ndash;                                            <span class="smaller-desc">{{enrollmentData.expectedLessonDuration}}</span>&ndash;&gt;-->


<!--                                        </button>-->

<!--                                        &lt;!&ndash;                                        <h4>수업 결제하기</h4>&ndash;&gt;-->
<!--                                        &lt;!&ndash;                                        <hr/>&ndash;&gt;-->


<!--                                        &lt;!&ndash;                                        <span class="smaller-desc">more text</span>&ndash;&gt;-->
<!--                                    </v-btn>-->
<!--                                </v-col>-->

<!--                                &lt;!&ndash;                                <v-col class="">&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    <v-card class="py-2"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                            height="100%"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                            width="100%"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                            elevation="0"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    >&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        <v-card-subtitle class="smaller-desc">{{virtualPriceToPay}}</v-card-subtitle>&ndash;&gt;-->

<!--                                &lt;!&ndash;                                    </v-card>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                </v-col>&ndash;&gt;-->
<!--                            </v-row>-->
<!--                        </div>-->

                    </v-form>
                </template>
            </v-card>
            <hr/>
            <v-card class="center-horizontal mx-auto enroll-page"
                    elevation="0"
                    width="800" >
                <v-row class="pb-5">

                    <v-col class="center-vertical  mt-4" md="">
                        <div class="smaller-desc center-horizontal" v-if="virtualPriceToPayForEditing != ''">
                            <!--                                        <span class="text-next-to-button-01">최대 40% 할인</span>-->
                            <span class="highlight-container"><span class="highlight"><strong>{{selectedDiscountRateForEditing}}</strong> 할인</span></span>
                            <span class="font-weight-bold">
                                            {{virtualPriceToPayForEditing}}
                                        </span>
                            <span class="text-next-to-button-03">{{studentContent.expectedNumberOfLessonsPerWeek}}(60분)•{{studentContent.expectedLessonDuration}}</span>
                        </div>
                        <div class="smaller-desc center-horizontal" v-if="virtualPriceToPayForEditing == ''">
                            <span class=" text-next-to-button-01">최대 40% 할인</span>

                            <span class="text-next-to-button-02">
                                            수업 정보를 <br/>선택하세요.
                                        </span>
                        </div>
                    </v-col>
                    <v-col  class="center-vertical mt-4">
                        <v-btn
                                class=" rounded-lg"
                                :disabled="!valid"
                                color="secondary"
                                @click="updateEnrollmentDataAndMoveToPayment(currentUser.id, studentContent)"
                                height="70px"
                                x-large
                        >
                            <button class="">
                                <h3 class="">수업 결제하기</h3>
                            </button>

                        </v-btn>

                    </v-col>
                </v-row>
            </v-card>

        </v-container>
        </div>
    </v-container>

</template>

<script>
    import StudentService from "../../../services/student.service"
    import info from "../../../../info";

    export default {
        name: "Enrollment",
        components: {},
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
            virtualPriceToPayForEditing() {
                if (this.studentContent.subSubjects.length > 0 &&
                    this.studentContent.expectedNumberOfLessonsPerWeek != ''
                    && this.studentContent.expectedLessonDuration != '') {

                    let numberOfMonths = this.studentContent.expectedLessonDuration.match(/\d/g).join("");
                    let numberPerWeek = this.studentContent.expectedNumberOfLessonsPerWeek.match(/\d/g).join("");
                    let totalNumberOfLessons = numberOfMonths * numberPerWeek * 4;

                    if (numberOfMonths == 1) {
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.virtualPrice = 200000
                    } else if (numberOfMonths == 3) {
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.virtualPrice = 180000
                    } else if (numberOfMonths == 12) {
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.virtualPrice = 150000
                    }
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.totalPrice = totalNumberOfLessons * this.virtualPrice;
                    let perMonthPrice = (this.totalPrice / numberOfMonths).toLocaleString();
                    return perMonthPrice + "원/월";
                }
                return "";

            },

            selectedDiscountRate() {
              if (this.enrollmentData.expectedLessonDuration.includes('1개월')) {
                  return '20%';
              }  else if (this.enrollmentData.expectedLessonDuration.includes('3')) {
                  return '28%'
              } else if (this.enrollmentData.expectedLessonDuration.includes('12')) {
                  return '40%'
              }
              return '';
            },

            selectedDiscountRateForEditing() {
                if (this.studentContent.expectedLessonDuration.includes('1개월')) {
                    return '20%';
                }  else if (this.studentContent.expectedLessonDuration.includes('3')) {
                    return '28%'
                } else if (this.studentContent.expectedLessonDuration.includes('12')) {
                    return '40%'
                }
                return '';
            },

            virtualPriceToPay() {
                if (this.enrollmentData.subSubjects.length > 0 &&
                    this.enrollmentData.expectedNumberOfLessonsPerWeek != ''
                    && this.enrollmentData.expectedLessonDuration != '') {

                    let numberOfMonths = this.enrollmentData.expectedLessonDuration.match(/\d/g).join("");
                    let numberPerWeek = this.enrollmentData.expectedNumberOfLessonsPerWeek.match(/\d/g).join("");
                    let totalNumberOfLessons = numberOfMonths * numberPerWeek * 4;

                    if (numberOfMonths == 1) {
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.virtualPrice = 200000
                    } else if (numberOfMonths == 3) {
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.virtualPrice = 180000
                    } else if (numberOfMonths == 12) {
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.virtualPrice = 150000
                    }
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.totalPrice = totalNumberOfLessons * this.virtualPrice;
                    let perMonthPrice = (this.totalPrice / numberOfMonths).toLocaleString();
                    return perMonthPrice + "원/월";
                }
                return "";
            }
        },
        mounted() {


            StudentService.getStudentInfo(this.currentUser.id).then(
                (response) => {
                    this.studentContent = response.data;
                    console.log("this.studentContent")
                    console.log(response)
                    // console.log(Object.keys(this.$store.state.enrollmentData).length)
                    console.log("this.studentContent")
                    this.studentContent.subjectsOfInterest = this.removeEmptyStringFromList(this.studentContent.subjectsOfInterest)[0];
                    // console.log("herehereherehereherehereherehere")
                    // console.log(this.studentContent.subjectsOfInterest)[0];
                    // console.log("herehereherehereherehereherehere")
                    this.studentContent.subSubjects = this.removeEmptyStringFromList(this.studentContent.subSubjects)[0];
                    this.studentContent.availableDays = this.removeEmptyStringFromList(this.studentContent.availableDays);
                    this.studentContent.availableTimes = this.removeEmptyStringFromList(this.studentContent.availableTimes);
                    this.studentContent.goalOfLesson = this.removeEmptyStringFromList(this.studentContent.goalOfLesson);
                    this.studentContent.preferredLessonMaterial = this.removeEmptyStringFromList(this.studentContent.preferredLessonMaterial);
                    this.studentContent.preferredLessonStyle = this.removeEmptyStringFromList(this.studentContent.preferredLessonStyle);
                    this.studentContent.expectedNumberOfLessonsPerWeek = "주 " + this.studentContent.expectedNumberOfLessonsPerWeek + "회";
                    this.studentContent.expectedLessonDuration = this.studentContent.expectedLessonDuration + "개월";
                    this.studentContent.tablet = this.removeEmptyStringFromList(this.studentContent.tablet);
                    console.log("-------------------")
                    console.log(this.studentContent)
                    console.log("-------------------")
                    // console.log("IN GET STUDENT INFO")
                    // console.log(this.studentContent)
                    // console.log("IN GET STUDENT INFO")
                },
                (error) => {
                    this.studentContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        methods: {
            updateEnrollmentDataToStore() {
                this.$store.state.enrollmentData = this.enrollmentData;
                this.$router.push("/payment");
            },

            updateEnrollmentDataAndMoveToPayment(userId, requestBody) {

                // console.log(userId)
                // console.log(requestBody)
                this.updateStudent(userId, requestBody);
                this.$store.state.enrollmentData = this.enrollmentData;
                this.$router.push("/payment");
            },

            isEnrollmentDataEmpty() {
                if (Object.keys(this.$store.state.enrollmentData).length > 0) {
                    return false;
                }
                return true;
            },

            removeEmptyStringFromList(input) {
                return input.filter(element => {
                    return element !== '';
                });
            },

            removeStringFromString(input) {
                return input.replace(/\D/g, '');
            },

            updateStudent(userId, request) {
                let requestBody = {
                    subjectsOfInterest: [],
                    subSubjects: [],
                    expectedNumberOfLessonsPerWeek: "",
                    expectedLessonDuration: "",
                    tablet: "",
                };
                console.log("+++++++++++++++++++++++++++++++++++++")
                console.log(requestBody)
                console.log("+++++++++++++++++++++++++++++++++++++")
                requestBody.expectedNumberOfLessonsPerWeek = this.removeStringFromString(request.expectedNumberOfLessonsPerWeek);
                requestBody.expectedLessonDuration = this.removeStringFromString(request.expectedLessonDuration);
                requestBody.subjectsOfInterest.push(request.subjectsOfInterest)
                requestBody.subSubjects.push(request.subSubjects)
                requestBody.tablet = request.tablet


                StudentService.updateStudent(userId, requestBody).then(
                    (response) => {
                        console.log("update");
                        console.log(response.data);
                        console.log("update");
                    },
                    (error) => {
                        this.studentContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        console.log(error.response)
                    }
                )
            },


            moveToProfile() {
                // this.$store.dispatch('auth/logout');
                this.$router.push('/');
            }
        },
        data: () => (
            {
                selectedDiscount: '',
                rules: {
                    required: (value) => !!value || '필수 입력',
                    listNotEmpty: (value) => value.length > 0 || '필수 선택'
                },
                virtualPrice: 0,
                totalPrice: 0,
                formContents: {
                    subjects: [],
                    country: "",
                    frequency: "",
                    duration: "",
                    payableItem: [''],
                    agree: false,
                    tablet: "",

                },

                enrollmentData: {
                    subjectsOfInterest: [],
                    subSubjects: "",
                    tablet: "",
                    expectedNumberOfLessonsPerWeek: "",
                    expectedLessonDuration: "",
                },

                temp: false,
                currentLocation: "",
                currentLocationDifferent: false,
                items: info.teacherRegister.subjectsOfExpertise,
                mainSubjects: info.mainSubjects,
                mathSubSubjects: info.mathSubSubjects,
                scienceSubSubjects: info.scienceSubSubjects,
                englishSubSubjects: info.englishSubSubjects,
                studentContent: {
                    subjectsOfInterest: [],
                    subSubjects: [],
                    expectedNumberOfLessonsPerWeek: "",
                    expectedNumberOfLessonDuration: "",
                    tablet: "",
                },
                valid: false,
                name: '',
                nameRules: [
                    v => !!v || '필수 입력',
                    v => (v && v.length <= 100) || 'must be less than 100 characters',
                ],
                email: '',
                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ],
                select: null,
                frequencyItems: [
                    '1회',
                    '2회',
                    '3회',
                    '5회',
                ],
                checkbox: false,
                checkbox1: true,
                checkbox2: false,
            }
        )
    }
</script>

<style scoped>
    .button {
        background: none repeat scroll 0 0 #FF0000;
        border: medium none;
        color: #FFFFFF;
        height: 100px;
        /*left: 74%;*/
        position: fixed;
        /*top: 100px;*/
        width: 100px;
    }

    .smaller-desc {
        font-size: 1.0em;
    }

    .small-desc-background {
        background-color: #00BCD4;
    }

    button hr {
        margin: 2px;
    }

    .main-title {
        padding-top: 30px;
        font-weight: bold;
        font-size: 25px;
    }

    .enroll-page {
        /*background-color: #F0F0F0;*/
        max-width: 900px;
        height: 100%;
    }

    .subtitle-text {
        color: black;
        font-weight: bold;
        font-size: 20px;
    }

    .button {
        position: fixed;
        bottom: 10px;
        /*left: 50%;*/
        /*margin-left: -104.5px; !*104.5px is half of the button's width*!*/
        /*background-color: #DE2828;*/
        background-image: linear-gradient(92.88deg, #DE2828 9.16%, #cd6060 64.72%);
        box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
        /*background-image: linear-gradient(92.88deg, #DE2828 9.16%, #c23e3e 43.89%, #cd6060 64.72%);*/
        border-radius: 15px;
        /*border-radius: 8px;*/
        /*border-style: none;*/
        /*box-sizing: border-box;*/
        color: #FFFFFF;
        cursor: pointer;
        flex-shrink: 0;
        /*font-family: "Inter UI", "SF Pro Display", -apple-system, BlinkMacSystemFont, "Segoe UI ",Roboto,Oxygen,Ubuntu,Cantarell," Open Sans "," Helvetica Neue ",sans-serif;*/
        /*font-family: 'SuncheonB';*/
        /*src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2202-2@1.0/SuncheonB.woff') format('woff');*/
        font-size: 15px;
        height: 75px;
        width: 400px;
        font-weight: 500;
    }

    .justify-left {
        text-align: left;
    }

    .centered-element {
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .discount-text {
        font-size: 16px;
        color: red;
        font-weight: bold;
    }

    /*.container {*/
    /*    line-height: 1.4;*/
    /*    text-align: center;*/
    /*    padding: 44px;*/
    /*    color: #333;*/
    /*}*/

    /*h1 {*/
    /*    font-size: 50px;*/
    /*}*/

    /*p {*/
    /*    font-size: 18px;*/
    /*}*/
    /*p small {*/
    /*    font-size: 80%;*/
    /*    color: #666;*/
    /*}*/

    .highlight-container, .highlight {
        position: relative;
        /*font-weight: bold;*/
        color: red;
    }

    .highlight-container {
        display: inline-block;
    }

    .highlight-container:before {
        content: " ";
        display: block;
        height: 90%;
        width: 100%;
        margin-left: -3px;
        margin-right: -3px;
        position: absolute;

        background: #FEDFDD;
        transform: rotate(2deg);
        top: -1px;
        left: -1px;
        border-radius: 20% 25% 20% 24%;
        padding: 10px 3px 3px 10px;
    }
    .center-vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .text-next-to-button-01 {
        font-weight: bold;
        color: gray;
        font-size: 18px;
    }
    .text-next-to-button-02 {
        color: gray;
        font-size: 16px;
    }
    .text-next-to-button-03 {
        color: black;
        font-size: 14px;
    }
    .anchor {
        position: fixed;
        bottom: 0;
        /*padding: 0 !important;*/
        /*margin: 0 !important;*/
        /*display: flex;*/
        /*flex-direction: column;*/
        /*justify-content: center;*/
        /*align-items: center;*/
        /*width: 800px !important;*/
    }
    .semi-background {
        background-color: #F0F0F0;
        /*max-width: 1000px;*/
        /*justify-content: center;*/
    }
</style>
