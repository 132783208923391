import axios from "axios";
import authHeader from "./auth-header";
import info from "../../info";

// const API_URL = 'http://localhost:8080/api/';
// const API_URL = 'https://backend-ie.herokuapp.com/api/';
// https://backend-ie.herokuapp.com/api/test/all

//local
// const API_URL = info.urls.local;

//dev
const API_URL = info.urls.staging;

//prod
// const API_URL = info.urls.production;


class StudentService {

    getStudentInfo(userId) {
        return axios.get(API_URL + 'student/' + userId, {headers: authHeader()});
    }

    getAllStudents() {
        return axios.get(API_URL + 'student/', {headers: authHeader()});
    }

    getLessonInfoByStudentId(studentId) {
        return axios.get(API_URL + 'student/' + studentId + "/lessons", {headers: authHeader()});
    }

    getUserInfoOfStudent(userId) {
        return axios.get(API_URL + 'student/user/' + userId, {headers: authHeader()});
    }

    getUserInfoByLessonId(lessonId) {
        return axios.get(API_URL + 'student/lessons/' + lessonId, {headers: authHeader()});
    }

    getStudentLessonInfo(userId) {
        return axios.get(API_URL + 'test/student/lesson/' + userId, {headers: authHeader()});
    }

    createStudentPayment(requestBody, userId) {
        return axios.post(API_URL + "student/" + userId + "/payment", requestBody, {headers: authHeader()});
    }

    updateStudent(userId, requestBody) {
        return axios.put(API_URL + "student/" + userId, requestBody, {headers: authHeader()});
    }
}

export default new StudentService();
