<template>
    <v-card
            class="mx-auto mt-5 ml-5 mb-5 pl-5 pt-5 pb-5 pr-5 primary--text"
            max-width="2000"
            outlined
    >
<!--        <v-card-->
<!--                class="mx-auto mt-5 ml-5 mb-5 pl-5 pt-5 pb-5 pr-5 primary&#45;&#45;text"-->
<!--                max-width="1500"-->
<!--                outlined-->
<!--        >-->


        <v-card-subtitle><h1>Matching 추천</h1></v-card-subtitle>
        <v-divider></v-divider>
        <StudentsDataTable></StudentsDataTable>
        <v-divider></v-divider>
        <TeachersDataTable></TeachersDataTable>
    </v-card>
</template>

<script>

    // import StudentService from "../../services/student.service"
    // import UserService from "../../services/user.service"
    import StudentsDataTable from "./StudentsDataTable";
    import TeachersDataTable from "./TeachersDataTable";

    export default {
        name: "MatchingInitiation",
        components: {StudentsDataTable, TeachersDataTable},
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
            formTitle() {
                return this.editedindex === -1 ? 'new item' : 'edit item'
            },
        },
        // mounted() {
        //     if (!this.currentUser) {
        //         this.$router.push('/login');
        //     }
        //
        //     UserService.getAllUsers().then(
        //         (response) => {
        //             this.users = response.data;
        //         },
        //         (error) => {
        //             this.users =
        //                 (error.response &&
        //                     error.response.data &&
        //                     error.response.data.message) ||
        //                 error.message ||
        //                 error.toString();
        //         }
        //     );
        //
        //     UserService.getAllUserStudents().then(
        //         (response) => {
        //             this.userStudents = response.data;
        //         },
        //         (error) => {
        //             this.userStudents =
        //                 (error.response &&
        //                     error.response.data &&
        //                     error.response.data.message) ||
        //                 error.message ||
        //                 error.toString();
        //         }
        //     );
        //
        //     // add student info fetcher
        //     StudentService.getStudentInfo(this.currentUser.id).then(
        //         (response) => {
        //             this.studentContent = response.data;
        //         },
        //         (error) => {
        //             this.studentContent =
        //                 (error.response &&
        //                     error.response.data &&
        //                     error.response.data.message) ||
        //                 error.message ||
        //                 error.toString();
        //         }
        //     );
        //
        //     StudentService.getAllStudents().then(
        //         (response) => {
        //             this.students = response.data;
        //         },
        //         (error) => {
        //             this.students =
        //                 (error.response &&
        //                     error.response.data &&
        //                     error.response.data.message) ||
        //                 error.message ||
        //                 error.toString();
        //         }
        //     );
        //     // if()
        // },
        data: () => ({
            userStudents:[],
            studentContent: "",
            requestBody: "",
            students: [],
            dialog: false,
            dialogDelete: false,
            users: [],
            headers: [
                {
                    text: 'user_id',
                    align: 'start',
                    value: 'id',
                },
                {text: 'username', value: 'username'},
                {text: 'firstname', value: 'firstName'},
                {text: 'email', value: 'email'},
                {text: 'phone number', value: 'phoneNumber'},
                {text: 'matching status', value: 'student.matchingStatus'},
                {text: 'register status', value: 'student.studentRegisterStatus'},
                {text: '수업', value: 'student.subjectsOfInterest'},
                {text: 'location', value: 'student.currentLocation'},
                {text: 'last login', value: 'lastLogin'},
                {text: 'Actions', value: 'actions', sortable: false},

            ],
            // headers: [
            //     {
            //         text: 'Dessert (100g serving)',
            //         align: 'start',
            //         sortable: false,
            //         value: 'name',
            //     },
            //     { text: 'Calories', value: 'calories' },
            //     { text: 'Fat (g)', value: 'fat' },
            //     { text: 'Carbs (g)', value: 'carbs' },
            //     { text: 'Protein (g)', value: 'protein' },
            //     { text: 'Actions', value: 'actions', sortable: false },
            // ],
            desserts: [],
            editedIndex: -1,
            editedItem: {
                username: '',
                firstName: '',
                email: '',
                phoneNumber: '',
                roleType: '',
            },
            defaultItem: {
                username: '',
                firstName: '',
                email: '',
                phoneNumber: '',
                roleType: '',
            },
        }),
        // watch: {
        //     dialog(val) {
        //         val || this.close()
        //     },
        //     dialogDelete(val) {
        //         val || this.closeDelete()
        //     },
        // },

        // created() {
        //     this.initialize()
        // },
        // methods: {
        //
        //     updateUserForAdmin(userId, requestBody) {
        //         UserService.updateUserForAdmin(userId, requestBody).then(
        //             (response) => {
        //                 console.log(response.data);
        //                 return response.data;
        //             },
        //             (error) => {
        //                 // this.users =
        //                     return (error.response &&
        //                         error.response.data &&
        //                         error.response.data.message) ||
        //                     error.message ||
        //                     error.toString();
        //             }
        //         )
        //     },
        //
        //
        //     initialize() {
        //         this.desserts = [
        //             {
        //                 name: 'Frozen Yogurt',
        //                 calories: 159,
        //                 fat: 6.0,
        //                 carbs: 24,
        //                 protein: 4.0,
        //             },
        //             {
        //                 name: 'Ice cream sandwich',
        //                 calories: 237,
        //                 fat: 9.0,
        //                 carbs: 37,
        //                 protein: 4.3,
        //             },
        //             {
        //                 name: 'Eclair',
        //                 calories: 262,
        //                 fat: 16.0,
        //                 carbs: 23,
        //                 protein: 6.0,
        //             },
        //             {
        //                 name: 'Cupcake',
        //                 calories: 305,
        //                 fat: 3.7,
        //                 carbs: 67,
        //                 protein: 4.3,
        //             },
        //             {
        //                 name: 'Gingerbread',
        //                 calories: 356,
        //                 fat: 16.0,
        //                 carbs: 49,
        //                 protein: 3.9,
        //             },
        //             {
        //                 name: 'Jelly bean',
        //                 calories: 375,
        //                 fat: 0.0,
        //                 carbs: 94,
        //                 protein: 0.0,
        //             },
        //             {
        //                 name: 'Lollipop',
        //                 calories: 392,
        //                 fat: 0.2,
        //                 carbs: 98,
        //                 protein: 0,
        //             },
        //             {
        //                 name: 'Honeycomb',
        //                 calories: 408,
        //                 fat: 3.2,
        //                 carbs: 87,
        //                 protein: 6.5,
        //             },
        //             {
        //                 name: 'Donut',
        //                 calories: 452,
        //                 fat: 25.0,
        //                 carbs: 51,
        //                 protein: 4.9,
        //             },
        //             {
        //                 name: 'KitKat',
        //                 calories: 518,
        //                 fat: 26.0,
        //                 carbs: 65,
        //                 protein: 7,
        //             },
        //         ]
        //     },
        //
        //     editItem(user) {
        //         console.log("================================");
        //         console.log(user.id);
        //         console.log('================================');
        //         this.editedIndex = this.users.indexOf(user)
        //         this.editedItem = Object.assign({}, user)
        //         // updateUserForAdmin()
        //         this.dialog = true
        //     },
        //
        //     deleteItem(item) {
        //         this.editedIndex = this.desserts.indexOf(item)
        //         this.editedItem = Object.assign({}, item)
        //         this.dialogDelete = true
        //     },
        //
        //     deleteItemConfirm() {
        //         this.desserts.splice(this.editedIndex, 1)
        //         this.closeDelete()
        //     },
        //
        //     close() {
        //         this.dialog = false
        //         this.$nextTick(() => {
        //             this.editedItem = Object.assign({}, this.defaultItem)
        //             this.editedIndex = -1
        //         })
        //     },
        //
        //     closeDelete() {
        //         this.dialogDelete = false
        //         this.$nextTick(() => {
        //             this.editedItem = Object.assign({}, this.defaultItem)
        //             this.editedIndex = -1
        //         })
        //     },
        //
        //     save() {
        //
        //         if (this.editedIndex > -1) {
        //             Object.assign(this.users[this.editedIndex], this.editedItem)
        //             this.updateUserForAdmin(this.editedItem.id, this.editedItem)
        //         } else {
        //             this.users.push(this.editedItem)
        //
        //
        //
        //
        //         }
        //         this.close()
        //     },
        //
        //     getUserInfoOfStudent(userId) {
        //         StudentService.getUserInfoOfStudent(userId).then(
        //             (response) => {
        //                 console.log(response.data)
        //                 this.studentContent = response.data;
        //             },
        //             (error) => {
        //                 this.studentContent =
        //                     (error.response &&
        //                         error.response.data &&
        //                         error.response.data.message) ||
        //                     error.message ||
        //                     error.toString();
        //             }
        //         )
        //     }
        // },

    }
</script>

<style scoped>

</style>
