<template>
    <v-container>
        <v-card>
            <v-card-title>
                Students
                <v-spacer></v-spacer>
                <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                ></v-text-field>
            </v-card-title>

            <v-data-table
                    :headers="headers"
                    :items="userStudents"
                    class="elevation-1"
                    :search="search"
            >
                <template v-slot:top>
                    <v-toolbar
                            flat
                    >
                        <v-divider
                                class="mx-4"
                                inset
                                vertical
                        ></v-divider>
                        <v-spacer></v-spacer>
                        <v-dialog
                                v-model="dialog"
                                max-width="500px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                        color="primary"
                                        dark
                                        class="mb-2"
                                        v-bind="attrs"
                                        v-on="on"
                                >
                                    New Item
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedItem.username"
                                                        label="username"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <!--                                            username: '',-->
                                                <!--                                            firstName: '',-->
                                                <!--                                            email: '',-->
                                                <!--                                            phoneNumber: '',-->
                                                <!--                                            roleType: '',-->
                                                <v-text-field
                                                        v-model="editedItem.firstName"
                                                        label="first name"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedItem.email"
                                                        label="Email"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedItem.phoneNumber"
                                                        label="phone number"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedItem.roleType"
                                                        label="role type"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="close"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="save"
                                    >
                                        Save
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-icon
                            small
                            class="mr-2"

                    >
                        mdi-account-plus
                    </v-icon>
                    <v-icon
                            small
                            class="mr-2"
                            @click="editItem(item)"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                            small
                            @click="deleteItem(item)"
                    >
                        mdi-delete
                    </v-icon>
                </template>
                <template v-slot:no-data>
                    <v-btn
                            color="primary"
                            @click="initialize"
                    >
                        Reset
                    </v-btn>
                </template>
            </v-data-table>
        </v-card>

    </v-container>
</template>

<script>
    import UserService from "../../services/user.service"
    import StudentService from "../../services/student.service"

    export default {
        name: "StudentsDataTable",
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
            formTitle() {
                return this.editedindex === -1 ? 'new item' : 'edit item'
            },
        },
        mounted() {
            if (!this.currentUser) {
                this.$router.push('/login');
            }

            UserService.getAllUsers().then(
                (response) => {
                    this.users = response.data;
                },
                (error) => {
                    this.users =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

            UserService.getAllUserStudents().then(
                (response) => {
                    this.userStudents = response.data;
                },
                (error) => {
                    this.userStudents =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

            // add student info fetcher
            StudentService.getStudentInfo(this.currentUser.id).then(
                (response) => {
                    this.studentContent = response.data;
                },
                (error) => {
                    this.studentContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

            StudentService.getAllStudents().then(
                (response) => {
                    this.students = response.data;
                },
                (error) => {
                    this.students =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
            // if()
        },
        data: () => ({
            editedIndex: -1,
            search: '',
            userStudents: [],
            studentContent: "",
            requestBody: "",
            students: [],
            dialog: false,
            dialogDelete: false,
            users: [],
            headers: [
                {
                    text: 'user_id',
                    align: 'start',
                    value: 'id',
                },
                {text: 'username', value: 'username'},
                {text: 'firstname', value: 'firstName'},
                {text: 'email', value: 'email'},
                {text: 'phone number', value: 'phoneNumber'},
                {text: 'matching status', value: 'student.matchingStatus'},
                {text: 'register status', value: 'student.studentRegisterStatus'},
                {text: '수업', value: 'student.subjectsOfInterest'},
                {text: 'location', value: 'student.currentLocation'},
                {text: '시간', value: 'student.availableTimes'},
                {text: 'last login', value: 'lastLogin'},
                {text: 'Actions', value: 'actions', sortable: false},

            ],
            // headers: [
            //     {
            //         text: 'Dessert (100g serving)',
            //         align: 'start',
            //         sortable: false,
            //         value: 'name',
            //     },
            //     { text: 'Calories', value: 'calories' },
            //     { text: 'Fat (g)', value: 'fat' },
            //     { text: 'Carbs (g)', value: 'carbs' },
            //     { text: 'Protein (g)', value: 'protein' },
            //     { text: 'Actions', value: 'actions', sortable: false },
            // ],
            desserts: [],
            // editedIndex: -1,
            editedItem: {
                username: '',
                firstName: '',
                email: '',
                phoneNumber: '',
                roleType: '',
            },
            defaultItem: {
                username: '',
                firstName: '',
                email: '',
                phoneNumber: '',
                roleType: '',
            },
        }),
        watch: {
            dialog(val) {
                val || this.close()
            },
            dialogDelete(val) {
                val || this.closeDelete()
            },
        },

        created() {
            this.initialize()
        },
        methods: {

            updateUserForAdmin(userId, requestBody) {
                UserService.updateUserForAdmin(userId, requestBody).then(
                    (response) => {
                        console.log(response.data);
                        return response.data;
                    },
                    (error) => {
                        // this.users =
                        return (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },


            initialize() {
                this.desserts = [
                    {
                        name: 'Frozen Yogurt',
                        calories: 159,
                        fat: 6.0,
                        carbs: 24,
                        protein: 4.0,
                    },
                    {
                        name: 'Ice cream sandwich',
                        calories: 237,
                        fat: 9.0,
                        carbs: 37,
                        protein: 4.3,
                    },
                    {
                        name: 'Eclair',
                        calories: 262,
                        fat: 16.0,
                        carbs: 23,
                        protein: 6.0,
                    },
                    {
                        name: 'Cupcake',
                        calories: 305,
                        fat: 3.7,
                        carbs: 67,
                        protein: 4.3,
                    },
                    {
                        name: 'Gingerbread',
                        calories: 356,
                        fat: 16.0,
                        carbs: 49,
                        protein: 3.9,
                    },
                    {
                        name: 'Jelly bean',
                        calories: 375,
                        fat: 0.0,
                        carbs: 94,
                        protein: 0.0,
                    },
                    {
                        name: 'Lollipop',
                        calories: 392,
                        fat: 0.2,
                        carbs: 98,
                        protein: 0,
                    },
                    {
                        name: 'Honeycomb',
                        calories: 408,
                        fat: 3.2,
                        carbs: 87,
                        protein: 6.5,
                    },
                    {
                        name: 'Donut',
                        calories: 452,
                        fat: 25.0,
                        carbs: 51,
                        protein: 4.9,
                    },
                    {
                        name: 'KitKat',
                        calories: 518,
                        fat: 26.0,
                        carbs: 65,
                        protein: 7,
                    },
                ]
            },

            editItem(user) {
                console.log("================================");
                console.log(user.id);
                console.log('================================');
                this.editedIndex = this.users.indexOf(user)
                this.editedItem = Object.assign({}, user)
                // updateUserForAdmin()
                this.dialog = true
            },

            deleteItem(item) {
                this.editedIndex = this.desserts.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialogDelete = true
            },

            deleteItemConfirm() {
                this.desserts.splice(this.editedIndex, 1)
                this.closeDelete()
            },

            close() {
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            closeDelete() {
                this.dialogDelete = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            save() {

                if (this.editedIndex > -1) {
                    Object.assign(this.users[this.editedIndex], this.editedItem)
                    this.updateUserForAdmin(this.editedItem.id, this.editedItem)
                } else {
                    this.users.push(this.editedItem)


                }
                this.close()
            },

            getUserInfoOfStudent(userId) {
                StudentService.getUserInfoOfStudent(userId).then(
                    (response) => {
                        console.log(response.data)
                        this.studentContent = response.data;
                    },
                    (error) => {
                        this.studentContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            }
        },

    }
</script>

<style scoped>

</style>
