<template>
<v-container>
    <v-card
            class="mx-auto mt-5 ml-5 mb-5 pl-5 pt-5 pb-5 pr-5 primary--text"
            max-width="750"
            outlined
    >
        <v-card-subtitle>
            <h1>ADMIN</h1>
        </v-card-subtitle>
        <v-container
                fluid
                class="pa-0"
        >
            <v-row align="center">
                <v-col
                        cols="12"
                        sm="6"
                >
                    <div class="text-center">
<!--                        <div class="my-2">-->
<!--                            <v-btn-->
<!--                                    x-large-->
<!--                                    color="primary"-->
<!--                                    dark-->
<!--                            >-->
<!--                                학생에게 선생 추천하기-->
<!--                            </v-btn>-->
<!--                        </div>-->
<!--                        <div class="my-2">-->
<!--                            <v-btn-->
<!--                                    x-large-->
<!--                                    color="primary"-->
<!--                                    dark-->
<!--                            >-->
<!--                                선생에게 학생 추천하기-->
<!--                            </v-btn>-->
<!--                        </div>-->
                        <div class="my-2">
                            <v-btn
                                    color="primary"
                                    dark
                                    x-large
                                    :to="'/admin/matching/initiate'"
                            >
                                매칭 주선
                            </v-btn>
                            <v-divider class="my-5"></v-divider>
                            <v-btn
                                    color="primary"
                                    dark
                                    x-large
                                    :to="'/admin/lessons/scheduled'"
                            >
                                선생님별 수업 리스트
                            </v-btn>
                        </div>
<!--                        <div class="my-2">-->
<!--                            <v-btn-->
<!--                                    color="error"-->
<!--                                    dark-->
<!--                                    x-large-->
<!--                            >-->
<!--                                Large Button-->
<!--                            </v-btn>-->
<!--                        </div>-->
<!--                        <div class="my-2">-->
<!--                            <v-btn-->
<!--                                    x-large-->
<!--                                    color="success"-->
<!--                                    dark-->
<!--                            >-->
<!--                                Extra large Button-->
<!--                            </v-btn>-->
<!--                        </div>-->
                    </div>
                </v-col>
                <v-col
                        cols="12"
                        sm="6"
                >
                </v-col>
            </v-row>
        </v-container>
    </v-card>

</v-container>
</template>

<script>
    export default {
        name: "Admin"
    }
</script>

<style scoped>

</style>
