<template>
    <div>
        <v-footer class="hidden-sm-and-down" height="300px" color="#393130"
                  padless>
            <v-card
                    flat
                    tile
                    class="white--text text-center"
                    color="#393130"
                    width="100%"
            >
                <v-card-text>
                    <v-btn
                            v-for="item in iconsAndLinks"
                            :key="item.link"
                            class="mx-4 white--text"
                            icon
                            :href="item.link"
                            target="_blank"
                    >
                        <v-icon size="24px">
                            {{ item.icon }}
                        </v-icon>
                    </v-btn>
                </v-card-text>

                <v-card-text class="white--text pt-0">
<!--                    <strong>(주) _______</strong><br/>-->
<!--                    대표: 김주남, 정희준 | 사업자번호 : 000-00-00000 | 주소: 서울특별시 여기저기-->
                    <br/>
                    <br/>
<!--                    통신판매업신고: 2022-00 00-1111-->
                    <br/>
                    <br/>
                    <a class="link" target="_blank" :href="policies.termsOfUse">이용약관</a>
                    | <a class="link" target="_blank" :href="policies.personalInfoHandling">개인정보 처리방침</a>
<!--                    | <a class="link" target="_blank" :href="policies.refundTerms">환불규정</a>-->

                    <br/>
                    <br/>
<!--                    COPYRIGHT 2022 IvyEd, All Rights Reserved-->

                </v-card-text>

                <v-divider></v-divider>

                <!--            <v-card-text class="white&#45;&#45;text">-->
                <!--                {{ new Date().getFullYear() }} — <strong>IVYeD</strong>-->
                <!--            </v-card-text>-->
            </v-card>
        </v-footer>

        <v-footer class="hidden-md-and-up secondary lighten-1 white--text"
                  padless
                  height="350px"
        >
            <v-card
                    flat
                    tile
                    color="#393130"
                    class="white--text"
                    width="100%"
                    height="100%"
            >
                <v-card-text class="text-center">
                    <v-btn
                            v-for="item in iconsAndLinks"
                            :key="item.link"
                            class="mx-4 white--text"
                            icon
                            :href="item.link"
                            target="_blank"
                    >
                        <v-icon size="24px" color="white">
                            {{ item.icon }}
                        </v-icon>
                    </v-btn>
                </v-card-text>

                <v-card-text class="white--text pt-0">
<!--                    <strong>(주) _______</strong><br/>-->
<!--                    대표: 김주남, 정희준 | 사업자번호 : 000-00-00000 <br/> 주소: 서울특별시 여기저기-->
                    <br/>
                    <br/>
<!--                    통신판매업신고: 2022-00 00-1111-->
                    <br/>
                    <br/>
                    <a class="link" target="_blank"
                       :href="policies.termsOfUse">이용약관</a>
                    | <a class="link" target="_blank" :href="policies.personalInfoHandling">개인정보 처리방침</a>
<!--                    | <a class="link" href="https://www.notion.so/ivyed/IVYED-3a7e3a0951e0442c8d7f248a805472b0">환불규정</a>-->
                    <br/>
                    <br/>
<!--                    COPYRIGHT 2022 IvyEd, All Rights Reserved-->

                </v-card-text>
            </v-card>
        </v-footer>
        <!--    <div class="footer">-->
        <!--        <p>Footer</p>-->
        <!--    </div>-->
    </div>
</template>


<script>
    import info from "../../../info";

    export default {
        name: "Footer",
        data: () => ({
            iconsAndLinks: [
                {icon: '$vuetify.icons.kakaoChannel', link: 'https://pf.kakao.com/_NxlSSb/chat'},
                {icon: '$vuetify.icons.notion', link: 'https://ivyed.notion.site/IVYED-Info-4e12dd5af08641269ea09f8b43a3a461'},
                // https://ivyed.notion.site/IVYED-Info-4e12dd5af08641269ea09f8b43a3a461
                {icon: 'mdi-instagram', link: ''},
            ],
            icons: [
                'mdi-facebook',
                'mdi-twitter',
                'mdi-linkedin',
                'mdi-instagram',
                '$vuetify.icons.kakaoChannel',
                '$vuetify.icons.notion',
            ],
            items: [
                'default',
                'absolute',
                'fixed',
            ],
            padless: false,
            variant: 'default',
            policies: info.policies,
        }),
        computed: {
            localAttrs() {
                const attrs = {}

                if (this.variant === 'default') {
                    attrs.absolute = false
                    attrs.fixed = false
                } else {
                    attrs[this.variant] = true
                }
                return attrs
            },
        },
    }
</script>


<style scoped>
    .footer {
        /*position: fixed;*/
        /*left: 0;*/
        /*bottom: 0;*/
        width: 100%;
        /*background-color: #DE2828;*/
        /*color: white;*/
        text-align: center;
    }

    .link {
        color: #fffffb;
        /*color: blue;*/
        text-decoration: none;

    }
</style>
