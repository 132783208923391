<template>
    <v-container :class="{
                    // 'text-h4': $vuetify.breakpoint.lgAndUp,
                    'payment-background': $vuetify.breakpoint.smAndDown,
                    // 'text-strong': $vuetify.breakpoint.mdAndDown
                    }">
        <!-- NEED TO ADD ONLY WHEN PAYMENT INFO IS AVAILABLE        -->

        <!--        {{enrollmentInfo}}-->
        <!--        <div v-if="!paymentInfo">-->
        <!--            <h2 class="text&#45;&#45;primary">Please fill out the payment form again</h2>-->
        <!--        </div>-->
        <!--        {{paymentInfo}}-->
        <div>
            <!--            <v-toolbar-title>결제</v-toolbar-title>-->
            <v-card
                    class="mx-auto primary--text pa-5 ma-2"
                    max-width="750"
                    flat
            >

                <v-card-title class="mt-5">
                    <!--                    <h1>상품 정보</h1>-->
                    <span
                            :class="{
                    'text-h4': $vuetify.breakpoint.lgAndUp,
                    'text-h5': $vuetify.breakpoint.mdAndDown,
                    // 'text-strong': $vuetify.breakpoint.mdAndDown
                    }"
                    ><strong>수강 신청 정보</strong></span>
                </v-card-title>
                <v-divider></v-divider>
                <v-list-item three-line>
                    <v-list-item-content class="primary--text">
                        <!--                        <div class=" mb-4">-->
                        <!--                        <h1> 저희가  딱 맞는 선생님을 찾아드릴게요! </h1>-->
                        <!--                            <v-divider class="mb-3 mt-3"></v-divider>-->
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="mb-3"><strong>희망수업 과목</strong></v-list-item-title>
                                <span>{{this.studentContent.subjectsOfInterest.join(', ')}} - {{this.studentContent.subSubjects[0]}}</span>
                            </v-list-item-content>
                        </v-list-item>

                        <!--                        <v-list-item>-->
                        <!--                            <v-list-item-content>-->
                        <!--                                <v-list-item-title><strong>희망 수업 요일</strong></v-list-item-title>-->
                        <!--                                <span>{{this.studentContent.availableDays.join(', ')}}</span>-->
                        <!--                            </v-list-item-content>-->
                        <!--                        </v-list-item>-->

                        <!--                        <v-list-item two-line>-->
                        <!--                            <v-list-item-content>-->
                        <!--                                <v-list-item-title><strong>희망 수업 시간대</strong></v-list-item-title>-->
                        <!--                                <span>{{paymentInfo.availableTimes.join(', ')}}</span>-->
                        <!--                            </v-list-item-content>-->
                        <!--                        </v-list-item>-->

                        <v-list-item three-line>
                            <v-list-item-content>
                                <v-list-item-title><strong>수업 기간</strong></v-list-item-title>
                                <span>
                                        {{this.studentContent.expectedLessonDuration}} 개월
                                    </span>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item three-line>
                            <v-list-item-content>
                                <v-list-item-title><strong>횟수</strong></v-list-item-title>
                                <span>
                                    주 {{this.studentContent.expectedNumberOfLessonsPerWeek}}회
                                </span>
                            </v-list-item-content>
                        </v-list-item>

                        <!--                        <v-list-item two-line>-->
                        <!--                            <v-list-item-content>-->
                        <!--                                <v-list-item-title><strong>태블릿 선택</strong></v-list-item-title>-->
                        <!--                                <span>-->
                        <!--                                        {{this.studentContent.tablet}}-->
                        <!--                                    </span>-->

                        <!--                            </v-list-item-content>-->
                        <!--                        </v-list-item>-->
                        <!--                        </div>-->
                        <!--                    <v-list-item-title class="text-h5 mb-1">-->
                        <!--                        {{currentUser.lastName}} {{currentUser.firstName}} 님 환영합니다!-->
                        <!--                    </v-list-item-title>-->
                        <v-list-item-subtitle></v-list-item-subtitle>
                    </v-list-item-content>

                    <!--                    <v-list-item-avatar-->
                    <!--                            tile-->
                    <!--                            size="100"-->
                    <!--                    >-->
                    <!--                        &lt;!&ndash;                    <v-img src="../assets/IvyEd-cornell-color.png"></v-img>&ndash;&gt;-->
                    <!--                    </v-list-item-avatar>-->
                </v-list-item>
                <div v-if="currentUser">
                    <v-divider></v-divider>
                    <div class="text-center">
                        <v-btn
                                color="primary"
                                elevation="0"
                                class="ma-4"
                                to="/enroll"
                                text
                        >
                            정보 변경 >
                        </v-btn>

                    </div>
                </div>

            </v-card>
            <v-container fluid class="hidden-md-and-up payment-background"></v-container>

            <v-card
                    class="mx-auto primary--text pa-5"
                    max-width="750"
                    flat
            >
                <v-card-title class="mt-5">
                    <span
                            :class="{
                    'text-h4': $vuetify.breakpoint.lgAndUp,
                    'text-h5': $vuetify.breakpoint.mdAndDown,
                    // 'text-strong': $vuetify.breakpoint.mdAndDown
                    }"
                    ><strong>결제 금액</strong></span>
                </v-card-title>
                <v-list-item three-line>
                    <div class="primary--text">

                        <div class="text-overline mb-4">
                            <!--                        <h1> 저희가  딱 맞는 선생님을 찾아드릴게요! </h1>-->
                            <v-divider class="mb-3 mt-3"></v-divider>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>정가</v-list-item-title>
                                    <v-list-item-subtitle>{{this.originalPrice.toLocaleString()}} 원
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title>{{studentContent.expectedLessonDuration}}개월 할인 &nbsp; <span class="highlight-container"><span class="highlight">{{selectedDiscountRate}}할인</span></span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        -{{(this.discountedAmount).toLocaleString()}} 원
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item three-line>
                                <v-list-item-content>
                                    <v-list-item-title> 최종 결제 금액</v-list-item-title>
                                    <span class="text-h6 font-weight-bold">
                                        {{(this.finalAmount).toLocaleString()}} 원
                                    </span>
                                    <v-list-item-subtitle class="font-weight-medium">
                                        {{studentContent.expectedLessonDuration}}개월 할부 시, 월
                                        {{(this.finalAmount/studentContent.expectedLessonDuration).toLocaleString()}}
                                        원
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <!--                            <v-list-item four-line>-->
                            <!--                                <v-list-item-content>-->
                            <!--                                    <v-list-item-title>{{studentContent.expectedLessonDuration}}개월 무이자 할부시</v-list-item-title>-->
                            <!--                                    <v-list-item-subtitle>-->

                            <!--                                        {{(this.requestBody.finalPrice/studentContent.expectedLessonDuration).toLocaleString()}} / 월-->
                            <!--                                    </v-list-item-subtitle>-->

                            <!--                                </v-list-item-content>-->
                            <!--                            </v-list-item>-->
                        </div>
                    </div>

                </v-list-item>
            </v-card>

            <v-container fluid class="hidden-md-and-up  payment-background"></v-container>
            <v-form class=""
                    ref="form"
                    v-model="valid"
            >
                <v-card
                        class="mx-auto primary--text pa-5 ma-2"
                        max-width="750"
                        flat
                >
                    <v-card-title class="mt-5">
                    <span
                            :class="{
                    'text-h4': $vuetify.breakpoint.lgAndUp,
                    'text-h5': $vuetify.breakpoint.mdAndDown,
                    // 'text-strong': $vuetify.breakpoint.mdAndDown
                    }"
                    ><strong>결제 수단</strong></span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <template>
                        <v-row>
                            <v-col>
                                <v-list-item-subtitle class="black--text mb-n4 mt-4">할부 개월</v-list-item-subtitle>
                            </v-col>
                            <v-col>
                                <v-list-item-subtitle class="justify-right mb-n4  mt-4 margin-test">

                                    <v-dialog
                                            v-model="dialog"
                                            width="500"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <a
                                                    class="text-decoration-underline"
                                                    v-bind="attrs"
                                                    v-on="on">무이자 할부 가능
                                            </a>
                                            <v-icon class="" size="17px">mdi-information-outline</v-icon>


                                        </template>

                                        <v-card class="py-4 ">
                                            <v-card-subtitle class=" dialog-title">
                                                무이자 할부 적용 가능 금액
                                                <p class="dialog-subtitle">
                                                    5만원 이상
                                                </p>
                                            </v-card-subtitle>

                                            <v-card-text>
                                                BC 2 ~ 7개월 무이자 <br/>
                                                KB국민 2 ~ 7개월 무이자 <br/>
                                                하나(외환) 2 ~ 8개월 무이자 <br/>
                                                삼성 2 ~ 6개월 무이자 <br/>
                                                신한 2 ~ 7개월 무이자 <br/>
                                                현대 2 ~ 7개월 무이자 <br/>
                                                롯데 2 ~ 4개월 무이자 <br/>
                                                NH농협 2 ~ 8개월 무이자 <br/>
                                                전북 2 ~ 5개월 무이자 <br/>
                                                우리 2 ~ 7개월 무이자 <br/>
                                            </v-card-text>

                                            <v-divider></v-divider>
                                            <v-card-actions class="justify-end">
                                                <v-btn
                                                        text
                                                        @click="dialog = false"
                                                >닫기
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>


                                </v-list-item-subtitle>
                            </v-col>
                        </v-row>
                        <v-select
                                v-model="requestBody.financing"
                                :items="financingOptions"
                                class="form-input-text"
                                :rules="[v => !!v || 'Item is required']"
                                required
                        ></v-select>
                        <v-list-item-subtitle class="black--text mt-4 mb-n4">카드 번호</v-list-item-subtitle>
                        <v-text-field
                                v-model="requestBody.cardNumber"
                                :counter="16"
                                :rules="cardNumberRules"
                                placeholder="'-' 생략하고 입력"
                                class="form-input-text"
                                required
                        ></v-text-field>

                        <v-list-item-subtitle class="black--text mb-n4 mt-4">카드 유효기간</v-list-item-subtitle>
                        <v-text-field
                                v-model="requestBody.expiration"
                                :rules="cardExpirationRules"
                                placeholder="월 (MM) / 년 (YY)"

                                class="form-input-text"
                                required
                        ></v-text-field>
                        <v-list-item-subtitle class="black--text mb-n4 mt-4">비밀번호 앞 2자리</v-list-item-subtitle>
                        <v-text-field
                                v-model="requestBody.cardPassword"
                                :counter="2"
                                :rules="cardPasswordRules"
                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"

                                class="form-input-text"
                                @click:append="showPassword = !showPassword"
                                :type="showPassword ? 'text' : 'password'"


                                placeholder="**"
                                required
                        ></v-text-field>
                        <v-list-item-subtitle class="black--text mb-n4 mt-4">생년월일 6자리 (개인) / 사업자 번호 10자리 (법인)
                        </v-list-item-subtitle>

                        <v-text-field
                                v-model="requestBody.cardBirthday"
                                :counter="10"
                                :rules="birthdayRules"
                                class="form-input-text"
                                placeholder="'-' 생략하고 입력"
                                required
                        ></v-text-field>

                        <p class="mt-4 msg-text black--text">* 결제한 수업이 모두 진행되면, <strong>위 카드로 다음 과외가 자동 결제</strong>됩니다.
                        </p>
                        <p class="msg-text black--text">* 카드정보는 <strong>NICEPAY</strong> 전자결제 회사에서 암호화해 안전하게 관리합니다. </p>
                        <v-container class="text-center" fluid>

                            <v-btn
                                    color="secondary"
                                    class="mr-4"
                                    x-large
                                    :disabled="!valid"
                                    @click="createStudentPayment(requestBody, currentUser.id)"
                            >
                                카드 정보 등록 및 결제하기
                            </v-btn>

                        </v-container>
                    </template>
                </v-card>
            </v-form>

            <!--            <v-card-->
            <!--                    class="mx-auto primary&#45;&#45;text pa-5 ma-2"-->
            <!--                    max-width="750"-->
            <!--                    outlined-->
            <!--            >-->
            <!--                <v-card-title class="mt-5">-->
            <!--                    <h1>결제 수단</h1>-->
            <!--                </v-card-title>-->
            <!--            </v-card>-->

        </div>
    </v-container>
</template>

<script>
    import info from "../../../info";
    import StudentService from "../../services/student.service";
    import EmailService from "../../services/email.service";

    export default {
        name: "Payment",
        components: {},
        computed: {
            enrollmentInfo() {
                return this.$store.state.enrollmentData;
            },

            paymentInfo() {
                return this.$store.state.studentRegisterData;
            },
            currentUser() {
                return this.$store.state.auth.user;
            },
            totalLessons() {
                return this.studentContent.expectedLessonDuration * this.studentContent.expectedNumberOfLessonsPerWeek * 4;
            },
            discountedRate() {
                if (this.studentContent.expectedLessonDuration == 1) {
                    return 20;
                } else if (this.studentContent.expectedLessonDuration == 3) {
                    return 28;
                } else if (this.studentContent.expectedLessonDuration == 12) {
                    return 40;
                }
                return "";
            },
            originalPrice() {
                return this.studentContent.expectedLessonDuration * this.studentContent.expectedNumberOfLessonsPerWeek * 250000 * 4
            }
            ,
            discountedAmount() {
                return this.originalPrice*.01*this.discountedRate;
            },
            finalAmount() {
                return this.originalPrice - this.discountedAmount
            },
            selectedDiscountRate() {
                if (this.studentContent.expectedLessonDuration == 1) {
                    return '20%';
                }  else if (this.studentContent.expectedLessonDuration == 3) {
                    return '28%'
                } else if (this.studentContent.expectedLessonDuration == 12) {
                    return '40%'
                }
                return '';
            },

        },
        mounted() {
            if (!this.currentUser) {
                this.$router.push('/login');
            }

            this.requestBody.finalPrice = 120000 * parseInt(this.studentContent.expectedNumberOfLessonsPerWeek)

            StudentService.getStudentInfo(this.currentUser.id).then(
                (response) => {
                    this.studentContent = response.data;
                    // this.studentContent.subjectsOfInterest = this.removeEmptyStringFromList(this.studentContent.subjectsOfInterest);
                    console.log("IN GET STUDENT INFO")
                    this.calculatePrices(this.studentContent)
                    console.log("IN GET STUDENT INFO")

                },
                (error) => {
                    this.studentContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

        },
        methods: {
            calculatePrices(studentContent) {
                this.requestBody.originalPrice = studentContent.expectedNumberOfLessonsPerWeek * 120000 * studentContent.expectedLessonDuration * 4
                // test- 20% discount
                this.requestBody.discountedPrice = studentContent.expectedNumberOfLessonsPerWeek * 120000 * studentContent.expectedLessonDuration * 4 * 0.8
                this.requestBody.finalPrice = this.requestBody.discountedPrice
            },
            calculateDiscountPrice(price) {
                let discounted = parseFloat(price) * 0.6;
                return discounted;
            },
            finalPrice(price) {
                return this.$store.state.studentRegisterData.duration.replace(/[^\d.]/g, '') * price;
            },
            applyFinancing(price) {
                return price / 12;
            },
            validate() {
                this.$refs.form.validate()
            },

            sendNotificationEmail(requestBody) {
                EmailService.sendEmailNotification(requestBody).then(
                    (response) => {
                        console.log(response.data)
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },


            createStudentPayment(requestBody, userId) {

                requestBody.finalPrice = this.finalAmount + "";
                requestBody.originalPrice = this.originalPrice + ""
                requestBody.discountedPrice = this.discountedAmount + ""

                StudentService.createStudentPayment(requestBody, userId).then(
                    (response) => {
                        console.log(response.data)
                        this.sendNotificationEmail({
                            'email': this.currentUser.email,
                            'emailBody': 'money thank you [여기서 과외신청서 관련 알려줘야댐]'
                        })
                        this.$router.push("/payment/confirmation")
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            }
        },
        data() {
            return {
                dialog: false,
                showPassword: false,
                studentContent: "",
                agreements: {
                    student: {
                        checkbox: false,
                        title: "(학생 동의 항목) 아래의 약관 및 개인정보 처리에 모두 동의합니다.",
                        content: "",
                    },
                    parents: {
                        checkbox: false,
                        title: "(학부모 동의 항목) 아래의 약관 및 개인정보 처리에 모두 동의합니다. ",
                        content: "",
                    }
                },
                requestBody: {
                    // subjectsOfInterest: this.$store.state.studentRegisterData.subjectsOfInterest,
                    // currentLocation: this.$store.state.studentRegisterData.currentLocation,
                    // frequency: this.$store.state.studentRegisterData.expecte,
                    // duration: this.$store.state.studentRegisterData.duration,
                    // tablet: this.$store.state.studentRegisterData.tablet,
                    originalPrice: "",
                    discountRate: "",
                    discountedPrice: "",
                    paidAmount: "",
                    paymentType: "",
                    finalPrice: "",
                    cardNumber: "",
                    expiration: "",
                    cardPassword: "",
                    birthday: "",
                    financing: "",
                },
                // temp: this.requestBody.financing.replace(/[^0-9.]+/g, ""),
                radios: null,
                nameRules: [
                    v => !!v || '필수 입력',
                    v => (v && v.length <= 10) || 'Name must be less than 10 characters',
                ],
                cardNumberRules: [
                    v => !!v || '필수 입력',
                    v => (v.length == 16 || v.length == 15) || ' \'-\' 을 제외한 16자리 카드번호를 입력해주세요.',
                ],
                cardExpirationRules: [
                    v => !!v || '필수 입력',
                    v => (v.match('[0-90-9]\\/[0-90-9]')) || ' MM/YY 형식으로 입력해주세요. (예. 02/24)',
                ],
                cardPasswordRules: [
                    v => !!v || '필수 입력',
                    v => (v.match('[0-9][0-9]')) || ' 비밀번호 첫 두자리 숫자를 입력해주세요.',
                    v => (v.length == 2) || '비밀번호 두자리만 입력해주세요'
                ],
                birthdayRules: [
                    v => !!v || '필수 입력',
                    v => (v.length == 6) || ' 생년월일은 6자리, 사업자 번호는 10자리 입력하여 주세요.',
                ],
                financingOptions: info.financingOptions,
                valid: false,
            }
        },
    }
</script>

<style scoped>
    .payment-background {
        background-color: #F7F8FA;
    }

    .form-input-text {
        font-size: 14px;
    }

    .justify-right {
        text-align: end;
    }

    .margin-test {
        font-size: 12px;

    }

    a:link {
        color: dimgray;
        background-color: transparent;
        text-decoration: underline;

    }

    .dialog-title {
        font-size: 16px;
        font-weight: bold;
    }

    .dialog-subtitle {
        font-size: 18px;
        font-weight: bold;
    }

    .msg-text {
        font-size: 10px;
    }
    .highlight-container, .highlight {
        position: relative;
        /*font-weight: bold;*/
        color: red;
    }

    .highlight-container {
        display: inline-block;
    }

    .highlight-container:before {
        content: " ";
        display: block;
        height: 90%;
        width: 100%;
        margin-left: -3px;
        margin-right: -3px;
        position: absolute;

        background: #FEDFDD;
        transform: rotate(2deg);
        top: -1px;
        left: -1px;
        border-radius: 20% 25% 20% 24%;
        padding: 10px 3px 3px 10px;
    }

</style>
