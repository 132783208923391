import axios from "axios";
import info from "../../info";

// const API_URL = 'http://localhost:8080/api/auth/';
// const API_URL = 'https://backend-ie.herokuapp.com/api/auth/';

//local
// const API_URL = info.urls.local + 'auth/';

//dev
const API_URL = info.urls.staging + 'auth/';

//prod
// const API_URL = info.urls.production + 'auth/';

class AuthService {
    login(user) {
        return axios
            .post(API_URL + 'signin', {
                username: user.username,
                password: user.password
            })
            .then(response => {
                if (response.data.accessToken) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    logout() {
        localStorage.removeItem('user');
    }

    register(user) {
        return axios.post(API_URL + 'signup', {
            username: user.username,
            email: user.email,
            password: user.password,
            firstName: user.firstName,
            lastName: user.lastName,
            birthDate: user.birthDate,
            roleType: user.roleType,
            phoneNumber: user.phoneNumber,
            countryCode: user.countryCode,
            role: [user.role],
            // teacherId: user.teacherId,
            // studentId: user.studentId
        });
    }

    resetPassword(user) {
        return axios.put(API_URL + 'password/reset', {
            email: user.email
        });
    }
}

export default new AuthService();
