<template>

    <v-container>
        <!--        <h1>{{currentUser}}</h1>-->
        <v-container v-if="currentUser.roles[0] == 'ROLE_TEACHER'">


            <v-card
                    class="mx-auto pa-5 primary--text"
                    max-width="800"
                    elevation="0"
            >
                <span
                        class="font-weight-bold"
                        :class="{
            'text-h6': $vuetify.breakpoint.mdAndDown,
            'text-h5': $vuetify.breakpoint.lgAndUp
            }">
                    희망 과외 과목 <v-icon color="primary"> mdi-book-open-blank-variant</v-icon>
                    <v-divider class="mt-3"></v-divider>

                </span>
                <p class="black--text text-subtitle-2">
                    가르치고 싶은 과목을 모두 선택해 주세요. 세부 과목을 많이 선택해 주실수록 빠르고 원할한 매칭이 가능합니다!
                </p>
            </v-card>
            <!--        <h1>{{teacherContent}}</h1>-->
            <!--            {{items}}-->
            <!--            {{removeEmptyStringFromList(teacherContent.subjectsOfExpertise)}}-->
            <v-card
                    class="mx-auto pa-5 mt-2 black--text justify-center"
                    max-width="800"
                    elevation="0"
            >

                <!-- 대학원 재학 이상 -->
                <v-form v-model="formValid">
<!--                    <v-card-title-->
<!--                            class="font-weight-bold mb-5"-->
<!--                            :class="{}">-->
<!--                        희망 과외 과목-->
<!--                    </v-card-title>-->
                    <v-row>
                        <v-col cols="12">
                            <v-select
                                    class="select"
                                    v-model="teacherContent.subjectsOfExpertise"
                                    :items="mainSubjects"
                                    dense
                                    chips
                                    label="과목"
                                    multiple
                                    :rules="[rules.required, rules.subjectRequired]"
                                    auto-select-first
                            >
                                <v-switch
                                        slot="prepend-item"
                                        @click="selectAllMainSubjects()"
                                        label="전체 선택"
                                        class="ml-1 pl-1"
                                >

                                </v-switch>
                            </v-select>
                            <v-select v-if="teacherContent.subjectsOfExpertise.includes('Mathematics')"
                                        :items="mathSubSubjects"
                                        class="select"
                                        v-model="teacherContent.selectedMathSubSubjects"
                                        dense
                                        chips
                                        label="수학 세부과목"
                                        multiple
                                        :rules="[rules.mathSubSubjectRequired]"
                                        auto-select-first
                            >
                                <v-switch
                                        slot="prepend-item"
                                        @click="selectAllMathSubjects()"
                                        label="전체 선택"
                                        class="ml-1 pl-1"
                                ></v-switch>
                            </v-select>
                            <v-select v-if="teacherContent.subjectsOfExpertise.includes('Science')"
                                        :items="scienceSubSubjects"
                                        v-model="teacherContent.selectedScienceSubSubjects"
                                        class="select"
                                        dense
                                        chips
                                        label="과학 세부과목"
                                        multiple
                                        :rules="[rules.scienceSubSubjectRequired]"
                                        auto-select-first
                            >
                                <v-switch
                                        slot="prepend-item"
                                        @click="selectAllScienceSubjects()"
                                        label="전체 선택"
                                        class="ml-1 pl-1"
                                ></v-switch>
                            </v-select>
                        </v-col>

                    </v-row>

                    <v-btn
                            v-show="teacherContent.registerStatus == 'NOT_STARTED'"
                            :disabled="!formValid"
                            color="secondary"
                            class="mr-3"
                            @click="updateTeacherInfo(currentUser.id, teacherContent)">
                        NEXT
                    </v-btn>
                    <v-btn
                            v-show="teacherContent.registerStatus != 'NOT_STARTED'"
                            :disabled="!formValid"
                            color="secondary"
                            class="mr-3"
                            @click="updateTeacherInfo(currentUser.id, teacherContent)">

                        수정하기
                    </v-btn>
                    <v-btn v-show="teacherContent.registerStatus == 'NOT_STARTED'"
                           :to="'/teacher/register/highschool'" text>
                        Back
                    </v-btn>
                    <v-btn v-show="teacherContent.registerStatus != 'NOT_STARTED'"
                           :to="'/teacher/register/teacher/summary'" text>
                        Back
                    </v-btn>

                </v-form>
            </v-card>
        </v-container>
    </v-container>
</template>

<script>
    import TeacherService from '../../../services/teacher.service'
    import info from "../../../../info";

    export default {
        name: "SubjectsOfExpertise",
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },

        },
        mounted() {
            TeacherService.getTeacherInfo(this.currentUser.id).then(
                (response) => {
                    this.teacherContent = response.data;
                    this.teacherContent.subjectsOfExpertise = this.removeEmptyStringFromList(this.teacherContent.subjectsOfExpertise);
                    this.teacherContent.availableTimes = this.removeEmptyStringFromList(this.teacherContent.availableTimes);
                    this.teacherContent.availableDays = this.removeEmptyStringFromList(this.teacherContent.availableDays);
                    this.teacherContent.preferredLessonStyle = this.removeEmptyStringFromList(this.teacherContent.preferredLessonStyle);
                    this.teacherContent.preferredLessonMaterial = this.removeEmptyStringFromList(this.teacherContent.preferredLessonMaterial);
                    this.teacherContent.preferredCoverMaterial = this.removeEmptyStringFromList(this.teacherContent.preferredCoverMaterial);
                    this.teacherContent.selectedMathSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedMathSubSubjects);
                    this.teacherContent.selectedScienceSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedScienceSubSubjects);
                    this.teacherContent.selectedEnglishSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedEnglishSubSubjects)
                    if (this.teacherContent.highestLevelOfEducation == '대학원 재학중' ||
                        this.teacherContent.highestLevelOfEducation == '석사 졸업' ||
                        this.teacherContent.highestLevelOfEducation == '박사 졸업') {
                        this.isBeyondUndergrad = true;
                    }
                },
                (error) => {
                    this.teacherContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

        },
        data() {
            return {
                teacherContent: "",
                formValid: false,
                undergrad: "",
                gradSchoolType: info.gradSchoolType,
                mainSubjects: info.mainSubjects,
                mathSubSubjects: info.mathSubSubjects,
                scienceSubSubjects: info.scienceSubSubjects,
                hasSelectedAllMainSubjects: false,
                hasSelectedAllMathSubjects: false,
                hasSelectedAllScienceSubjects: false,


                rules: {
                    required: (value) => !!value || '필수 입력 값입니다.',
                    subjectRequired: (value) => (value.includes('Mathematics') || value.includes('Science')) || '필수 입력 값입니다.',
                    mathSubSubjectRequired: (value) => (value.includes("Middle school") ||
                        // value.includes("Elementary school") ||
                        value.includes("Algebra") ||
                        value.includes("Geometry") ||
                        value.includes("Pre-calculus") ||
                        value.includes("Calculus") ||
                        value.includes("AP Calc AB") ||
                        value.includes("AP Calc BC") ||
                        value.includes("Statistics") ||
                        value.includes("AP Statistics") ||
                        value.includes("Multi-variate Calculus") ||
                        value.includes("AP CS Principles") ||
                        value.includes("AP CS A") ||
                        value.includes("SAT")) || '필수 입력 값입니다.',
                    scienceSubSubjectRequired: (value) => (
                        // value.includes("Elementary school") ||
                        value.includes("Middle school") ||
                        value.includes("Biology") ||
                        value.includes("AP Biology") ||
                        value.includes("Chemistry") ||
                        value.includes("AP Chemistry") ||
                        value.includes("Environmental Science") ||
                        value.includes("AP Environmental Science") ||
                        value.includes("Astronomy") ||
                        value.includes("Physics") ||
                        value.includes("AP Physics 1") ||
                        value.includes("AP Physics 2") ||
                        value.includes("AP Physics C Mech") ||
                        value.includes("AP Physics C E&M")) || '필수 입력 값입니다.',

                },
                possibleHighSchoolTypes: info.possibleHighSchoolTypes
            }
        },
        methods: {
            selectAllMainSubjects(){
                this.hasSelectedAllMainSubjects = !this.hasSelectedAllMainSubjects;
                if (this.hasSelectedAllMainSubjects) {
                    this.teacherContent.subjectsOfExpertise = [...this.mainSubjects]
                } else if (!this.hasSelectedAllMainSubjects) {
                    this.teacherContent.subjectsOfExpertise = []
                }
            },
            selectAllMathSubjects() {
              this.hasSelectedAllMathSubjects = !this.hasSelectedAllMathSubjects;
              if (this.hasSelectedAllMathSubjects) {
                  this.teacherContent.selectedMathSubSubjects = [...this.mathSubSubjects]
              } else {
                  this.teacherContent.selectedMathSubSubjects = []
              }
            },
            selectAllScienceSubjects() {
                this.hasSelectedAllScienceSubjects = !this.hasSelectedAllScienceSubjects;
                if (this.hasSelectedAllScienceSubjects) {
                    this.teacherContent.selectedScienceSubSubjects = [...this.scienceSubSubjects]
                } else {
                    this.teacherContent.selectedScienceSubSubjects = []
                }
            },

            updateTeacherInfo(userId, requestBody) {

                TeacherService.updateTeacherSubjects(userId, requestBody).then(
                    (response) => {
                        this.teacherContent = response.data;
                        // this.$router.push("/teacher/register/lesson/times")
                        if (this.teacherContent.registerStatus == 'NOT_STARTED') {
                            this.$router.push("/teacher/register/lesson/times")
                        } else {
                            this.$router.push("/teacher/register/teacher/summary")
                        }
                        this.next = true
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },
            removeEmptyStringFromList(input) {
                return input.filter(element => {
                    return element !== '';
                });
            },

        }
    }
</script>

<style scoped>

    .select {
        max-width: 500px;
    }
</style>
