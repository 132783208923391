<template>
    <div id="create-channel-chat-button"></div>
</template>

<script>

    export default {
        name: "KakaoChannelButton",
    }
    // eslint-disable-next-line no-undef

</script>

<style scoped>

</style>
